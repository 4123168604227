import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { LogoIcon, Illustration2, PrimaryButton } from 'components'
import styles from './ConfirmEmail.module.scss'
import { useStateValue } from 'state'
import { useNavigate } from 'hooks'
import { PATH } from 'utils/constants'

/*
 * Visited when an email confirmation link is clicked.
 *
 * When successfully confirmed it should indicate success and show link to dboard.
 *
 * When invite is already confirmed, it should indicate prior success and
 * show link to dashboard.
 *
 * When token is not valid an error message should be shown.
 *
 * When campaign is closed an error message should be shown.
 */

const ConfirmEmail = () => {
  const { token } = useParams()

  const navigate = useNavigate()
  const [invitation, setInvitation] = useState({ email: 'unknown' })
  const [ambassador, setAmbassador] = useState({ uniqueLink: '' })

  const {
    state: { deviceSize },
    action: { confirmEmail },
  } = useStateValue()

  useEffect(
    () => {
      confirmEmail({ token }, handleConfirm)
    },
    // eslint-disable-next-line
    [token]
  )
  // const handleClickSignIn = () => navigate({ pathname: PATH.AUTH__SIGN_IN })

  const handleConfirm = ({ invitation, ambassador }) => {
    // console.log('handleConfirm')
    // console.log(ambassador)
    setInvitation(invitation)
    setAmbassador(ambassador)
  }

  return (
    <div className={styles.page__container}>
      <div className={styles.page__wrapper}>
        <div className={styles.page}>
          <Link className={styles.logo} to="/">
            {' '}
            <LogoIcon />{' '}
          </Link>

          <p className={styles.description}>
            Thank you! Your email, '{invitation.email},' is confirmed.
          </p>
          <PrimaryButton
            onClick={() =>
              navigate({
                pathname: `${PATH.PUBLIC__AMBASSADOR_DASHBOARD}/${ambassador.uniqueLink}`,
              })
            }
          >
            Go To Your Dashboard!
          </PrimaryButton>
        </div>

        {deviceSize === 'xxl' ? (
          <Illustration2 className={styles.page__illustration} />
        ) : null}
      </div>
    </div>
  )
}

export default ConfirmEmail
