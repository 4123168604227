import { getAxiosInstance, handleError } from 'utils'
import { ACTION } from 'utils/constants'

export const getCampaignReferrals =
  (dispatch) =>
  async (campaignId, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().get(
        `/api/campaigns/${campaignId}/referrals`
      )

      const { success, code, message, referrals } = response.data

      if (success) callback(referrals)
      else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const updateCampaignReferral =
  (dispatch) =>
  async (campaignId, data, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().put(
        `/api/campaigns/${campaignId}/referrals`,
        data
      )

      const { success, code, referral, message } = response.data

      if (success) callback(referral)
      else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const deleteCampaignReferral =
  (dispatch) =>
  async (campaignId, referralId, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().delete(
        `/api/campaigns/${campaignId}/referral/${referralId}`
      )

      const { success, code, message } = response.data

      if (success) callback()
      else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const getReferral =
  (dispatch) =>
  async (referralId, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().get(
        `/api/campaign/referral/${referralId}`
      )

      const { success, code, message, referral } = response.data

      if (success) callback(referral)
      else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }
