import React, { useRef, useState } from 'react'
import { TertiaryButton, ChevronArrowIcon } from 'components'
import { useClickOutside } from 'hooks'
import styles from './SelectFilter.module.scss'
import clsx from 'clsx'
import _ from 'lodash'

const SelectFilter = ({
  filters,
  showKey,
  uniqueKey,
  className,
  handleFilterClick,
  selectedStatus,
}) => {
  const [isNewMenuOpened, setNewMenuOpened] = useState(false)

  const refItems = {
    filterMenu: useRef(null),
  }

  useClickOutside(refItems.filterMenu, () => {
    setNewMenuOpened(false)
  })

  // Handler for item click
  const handleClick = (filter) => {
    setNewMenuOpened(false)
    handleFilterClick(filter)
  }

  return (
    <div className={[styles.amb_filter__wrapper]} ref={refItems.filterMenu}>
      <TertiaryButton
        onClick={() => setNewMenuOpened(!isNewMenuOpened)}
        className={
          JSON.stringify(filters[0]) != JSON.stringify(selectedStatus)
            ? styles.amb_filter__filtered_button
            : ''
        }
      >
        <span style={{ color: '#976821' }}>{selectedStatus[showKey]}</span>
        {JSON.stringify(filters[0]) === JSON.stringify(selectedStatus) ? (
          <ChevronArrowIcon
            className={clsx(
              styles.amb_filter__icon,
              isNewMenuOpened && styles['amb_filter__icon--up']
            )}
          />
        ) : (
          <span
            className={clsx(styles.amb_filter__icon)}
            onClick={() => {
              handleClick(filters[0])
            }}
          >
            x
          </span>
        )}
      </TertiaryButton>

      <div
        className={clsx(
          styles.amb_filter__menu,
          isNewMenuOpened && styles['amb_filter__menu--opened']
        )}
      >
        {filters.map((filter) => (
          <button
            key={filter[uniqueKey]}
            className={styles.amb_filter__menu__item}
            onClick={() => handleClick(filter)}
          >
            {filter[showKey]}
          </button>
        ))}
      </div>
    </div>
  )
}

export default SelectFilter
