import React, { useEffect } from 'react'
import { Route, Navigate, useLocation } from 'react-router-dom'
// import { Layout } from 'components'
import { useStateValue } from 'state'
import { AUTH_STATE, PATH } from 'utils/constants'
import { removeStorageItem } from 'utils'
import { Layout } from 'components'

const AuthRoute = (props) => {
  const {
    state: { auth },
  } = useStateValue()
  const { pathname } = useLocation()

  useEffect(() => {
    if (
      auth.state !== AUTH_STATE.SIGNED_IN ||
      !pathname.includes(PATH.CAMPAIGNS__EDIT)
    )
      removeStorageItem('step')
  }, [useLocation()])
  return (
    <>
      {auth.state !== AUTH_STATE.SIGNED_IN ? (
        <Navigate to={PATH.AUTH__SIGN_IN} />
      ) : !pathname.includes(PATH.AUTH__YOUR_PROFILE) &&
        !auth.user.isProfileCompleted ? (
        <Navigate to={PATH.AUTH__YOUR_PROFILE} />
      ) : props.noLayout ? (
        <>{props.children}</>
      ) : (
        <Layout bgColor={props.bgColor}>{props.children} </Layout>
      )}
    </>
  )
}

export default AuthRoute
