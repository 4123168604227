import React from 'react'
import clsx from 'clsx'
import styles from './TextSpan.module.scss'

const TextSpan = ({
  classNames = { container: '', label: '', input: '' },
  label = null,
  span = '',
}) => {
  return (
    <div className={clsx(classNames.container, styles.text_span__wrapper)}>
      {label ? (
        <label className={clsx(classNames.label, styles.text_span__label)}>
          {label === ' ' ? <>&nbsp;</> : label}
        </label>
      ) : null}
      <span className={clsx(classNames.label, styles.text_span_label)}>
        {span}
      </span>
    </div>
  )
}

export default TextSpan
