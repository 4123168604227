import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import {
  Breadcrumb,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  PlusCircleIcon,
  RemoveIcon,
  TextInput,
  AddressInput,
  TextArea,
  ColorPickerModal,
  AddRoleModal,
} from 'components'
import { useStateValue } from 'state'
import styles from './Add.module.scss'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useNavigate as useHistory, useLocation } from 'react-router-dom'
import _ from 'lodash'
import { useNavigate } from 'hooks'
import { PATH } from 'utils/constants'

const Add = () => {
  const {
    REACT_APP_STRIPE_ADDITIONAL_YEARLY,
    REACT_APP_STRIPE_ADDITIONAL_MONTHLY,
  } = process.env
  const history = useHistory()
  const { state } = useLocation()
  const {
    state: {
      auth: { user },
    },
    action: {
      addCamp,
      getSubscription,
      createSubscription,
      deleteSubscription,
    },
  } = useStateValue()
  const [openColorPicker, setOpenColorPicker] = useState(false)
  const [colorPickerOptions, setColorPickerOptions] = useState({})
  const [openAddRole, setOpenAddRole] = useState(false)
  const fileRef = useRef(null)
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      id: '',
      name: '',
      website: '',
      email: '',
      phone: '',
      mission: '',
      logo: null,
      address_1: '',
      address_2: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
      primaryColor: '#5992FF',
      secondaryColor: '#CCDDFF',
      buttonColor: '#EBF2FF',
      seasons: [],
      roles: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please enter camp name.'),
      website: Yup.string()
        .required('Please enter camp website.')
        .url('Please enter a valid website.'),
      email: Yup.string()
        .required('Please enter camp email.')
        .email('Please enter a valid email.'),
      phone: Yup.string().nullable(),
      address_1: Yup.string().required('Please enter your address.'),
      city: Yup.string().required('Please enter your city.'),
      state: Yup.string().required('Please select your state.'),
      zipCode: Yup.string().required('Please enter your zipcode.'),
      country: Yup.string().required('Please select your country/region.'),
    }),
    onSubmit: (camp) => {
      if (state.firstCamp) {
        addCamp(camp, () => navigate({ pathname: PATH.CAMPS }))
      } else {
        createSubscription(
          {
            price:
              state?.subs === 'monthly'
                ? REACT_APP_STRIPE_ADDITIONAL_MONTHLY
                : REACT_APP_STRIPE_ADDITIONAL_YEARLY,
            userId: user.id,
          },
          (subscription) => {
            addCamp(
              subscription.metadata?.campId
                ? { ...camp, id: subscription.metadata.campId }
                : camp,
              () => navigate({ pathname: PATH.CAMPS }),
              () => {
                if (subscription) deleteSubscription(subscription.id)
              }
            )
          }
        )
      }
    },
  })

  useEffect(() => {
    if (state?.firstCamp) {
      getSubscription({ userId: user.id }, (subscription) => {
        formik.setFieldValue('id', subscription[0].metadata.campId)
      })
    }
  }, [state])

  const handleClickFileUploader = (e) => {
    e?.preventDefault()
    fileRef.current.click()
  }

  const handleEditPrimaryColor = (e) => {
    e.preventDefault()

    setColorPickerOptions({
      title: 'Primary Color',
      color: formik.values.primaryColor,
      onUpdateColor: (color) => formik.setFieldValue('primaryColor', color),
    })

    setOpenColorPicker(true)
  }

  const handleEditSecondaryColor = (e) => {
    e.preventDefault()

    setColorPickerOptions({
      title: 'Secondary Color',
      color: formik.values.secondaryColor,
      onUpdateColor: (color) => formik.setFieldValue('secondaryColor', color),
    })

    setOpenColorPicker(true)
  }

  const handleAddLogo = ({ target: { files } }) =>
    formik.setFieldValue('logo', files[0])

  return (
    <>
      <Breadcrumb
        items={[
          { to: PATH.CAMPS, text: 'My Camps' },
          { to: PATH.CAMPS__ADD, text: 'Creating New Camp' },
        ]}
      />

      <div className={styles.page}>
        <form className={styles.form}>
          <fieldset>
            <legend className={styles.form__legend}>Camp Details</legend>

            <div className={clsx(styles.form__row, styles.form__row__reverse)}>
              <div className={styles.form__col}>
                <TextInput
                  label="Camp Name"
                  value={formik.values.name}
                  onChange={(name) => formik.setFieldValue('name', name)}
                  {...(formik.touched.name && formik.errors.name
                    ? { isError: true, captionText: formik.errors.name }
                    : {})}
                  requiredShown
                />

                <TextInput
                  label="Camp Website"
                  placeholder="https://"
                  value={formik.values.website}
                  onChange={(website) =>
                    formik.setFieldValue('website', website)
                  }
                  {...(formik.touched.website && formik.errors.website
                    ? { isError: true, captionText: formik.errors.website }
                    : {})}
                />

                <TextInput
                  label="Camp Email"
                  value={formik.values.email}
                  onChange={(email) => formik.setFieldValue('email', email)}
                  {...(formik.touched.email && formik.errors.email
                    ? { isError: true, captionText: formik.errors.email }
                    : {})}
                  requiredShown
                />

                <TextInput
                  label="Phone"
                  value={formik.values.phone}
                  onChange={(phone) => formik.setFieldValue('phone', phone)}
                  {...(formik.touched.phone && formik.errors.phone
                    ? { isError: true, captionText: formik.errors.phone }
                    : {})}
                />
              </div>

              <div className={styles.form__col}>
                <div
                  className={clsx(
                    styles.form__add_logo,
                    formik.touched.logo &&
                      formik.errors.logo &&
                      styles['form__add_logo--error']
                  )}
                >
                  <input
                    ref={fileRef}
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={handleAddLogo}
                  />

                  {formik.values.logo ? (
                    <>
                      <span className={styles.form__add_logo__name}>
                        {formik.values.logo.name}
                      </span>
                      <img
                        className={styles.form__add_logo__preview}
                        src={
                          typeof formik.values.logo === 'string'
                            ? formik.values.logo
                            : URL.createObjectURL(formik.values.logo)
                        }
                        alt={formik.values.logo.name}
                      />
                    </>
                  ) : null}

                  {formik.values.logo ? (
                    <TertiaryButton
                      className={styles.form__add_logo__change}
                      label="CHANGE LOGO"
                      onClick={handleClickFileUploader}
                    />
                  ) : (
                    <button
                      type="button"
                      className={styles.form__add_logo__button}
                      onClick={handleClickFileUploader}
                    >
                      <PlusCircleIcon className={styles.form__add_logo__icon} />
                      <p>Add logo</p>
                      <p>svg, png</p>
                    </button>
                  )}

                  {formik.touched.logo && formik.errors.logo ? (
                    <span className={styles.form__add_logo__caption}>
                      {formik.errors.logo}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>

            <div className={styles.form__row}>
              <TextArea
                label="Our Mission"
                placeholder="Text here"
                value={formik.values.mission}
                onChange={(mission) => formik.setFieldValue('mission', mission)}
              />
            </div>

            <div className={styles.form__row}>
              <AddressInput
                label="Camp Address"
                onChange={({ address_1, city, state, zipCode, country }) => {
                  formik.setFieldValue('address_1', address_1)
                  formik.setFieldValue('city', city)
                  formik.setFieldValue('state', state)
                  formik.setFieldValue('zipCode', zipCode)
                  formik.setFieldValue('country', country)
                }}
                {...(formik.touched.country && formik.errors.country
                  ? { isError: true, captionText: formik.errors.country }
                  : {})}
                {...(formik.touched.zipCode && formik.errors.zipCode
                  ? { isError: true, captionText: formik.errors.zipCode }
                  : {})}
                {...(formik.touched.state && formik.errors.state
                  ? { isError: true, captionText: formik.errors.state }
                  : {})}
                {...(formik.touched.city && formik.errors.city
                  ? { isError: true, captionText: formik.errors.city }
                  : {})}
                {...(formik.touched.address_1 && formik.errors.address_1
                  ? { isError: true, captionText: formik.errors.address_1 }
                  : {})}
              />
            </div>
          </fieldset>

          <fieldset>
            <legend className={styles.form__legend}>Camp Color Palette</legend>

            <div
              className={clsx(
                styles.form__row,
                styles.form__row__color_palette
              )}
            >
              <div className={styles.form__color_picker__wrapper}>
                <p className={styles.form__color_picker__label}>Primary</p>
                <div className={styles.form__color_picker}>
                  <div
                    className={styles.form__color_picker__preview}
                    style={{ backgroundColor: formik.values.primaryColor }}
                    onClick={handleEditPrimaryColor}
                  />

                  <div className={styles.form__color_picker__text}>
                    <p className={styles.form__color_picker__color}>
                      {formik.values.primaryColor}
                    </p>
                    <button
                      className={styles.form__color_picker__btn}
                      type="button"
                      onClick={handleEditPrimaryColor}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>

              <div className={styles.form__color_picker__wrapper}>
                <p className={styles.form__color_picker__label}>Secondary</p>
                <div className={styles.form__color_picker}>
                  <div
                    className={styles.form__color_picker__preview}
                    style={{ backgroundColor: formik.values.secondaryColor }}
                    onClick={handleEditSecondaryColor}
                  />

                  <div className={styles.form__color_picker__text}>
                    <p className={styles.form__color_picker__color}>
                      {formik.values.secondaryColor}
                    </p>
                    <button
                      type="button"
                      className={styles.form__color_picker__btn}
                      onClick={handleEditSecondaryColor}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <legend className={styles.form__legend}>All Users</legend>
            <div className={clsx(styles.form__row, styles.roles__wrapper)}>
              {[
                { name: 'You', role: 'OWNER', email: '' },
                ...formik.values.roles,
              ].map(({ name, role, email }, rIndex) => (
                <div
                  className={clsx(styles.form__col, styles.role)}
                  key={rIndex}
                >
                  <span className={styles.role__no}>{rIndex + 1}</span>
                  <span className={styles.role__name}>{name}</span>
                  <span className={styles.role__text}>{role}</span>
                  {email ? <p className={styles.role__email}>{email}</p> : null}
                  {rIndex ? (
                    <button
                      className={styles.role__remove}
                      onClick={(e) => {
                        e.preventDefault()

                        formik.setFieldValue(
                          'roles',
                          formik.values.roles.filter(
                            (role, _rIndex) => _rIndex + 1 !== rIndex
                          )
                        )
                      }}
                    >
                      <RemoveIcon />
                    </button>
                  ) : null}
                </div>
              ))}
            </div>

            <div
              className={clsx(styles.form__row, styles.role__add_role__wrapper)}
            >
              <TertiaryButton
                className={styles.role__add_role}
                onClick={() => setOpenAddRole(true)}
              >
                <PlusCircleIcon />
                Add Another User
              </TertiaryButton>
            </div>
          </fieldset>

          <div className={styles.form__buttons}>
            <SecondaryButton
              className={styles.form__cancel}
              label="Cancel"
              onClick={history.goBack}
            />

            <PrimaryButton
              className={styles.form__save}
              label="Save"
              onClick={formik.handleSubmit}
            />
          </div>
        </form>
      </div>

      <ColorPickerModal
        show={openColorPicker}
        onClose={() => setOpenColorPicker(false)}
        options={colorPickerOptions}
      />

      <AddRoleModal
        show={openAddRole}
        onClose={() => setOpenAddRole(false)}
        onAdd={(role) =>
          formik.setFieldValue('roles', _.concat(formik.values.roles, role))
        }
      />
    </>
  )
}

export default Add
