import { getAxiosInstance, handleError } from 'utils'
import { ACTION } from 'utils/constants'

export const createSetupIntentStripe =
  (dispatch) =>
  async (params, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance(true).post(
        '/api/setup-intents/create',
        params
      )

      const { data, status } = response.data
      if (status) {
        callback(data.client_secret, data.userId)
      }
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }
export const getSubscription =
  (dispatch) =>
  async (params, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance(true).get(
        `/api/subscriptions?metadata=${JSON.stringify(params)}`
      )

      if (response.status) callback(response.data.data)
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const createSubscription =
  (dispatch) =>
  async (params, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance(true).post(
        '/api/subscriptions/add-with-userid',
        params
      )
      const { status } = response.data
      if (status) callback(response.data.data)
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const deleteSubscription = (dispatch) => async (id) => {
  try {
    dispatch({ type: ACTION.START })

    await getAxiosInstance(true).delete(`/api/subscriptions/${id}`)
  } catch (error) {
    handleError(dispatch, error)
  } finally {
    dispatch({ type: ACTION.END })
  }
}

export const getBilling =
  (dispatch) =>
  async (id, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance(true).get(
        `/api/billing-portal/${id}`
      )

      // TODO: Is this what we really want to do when we don't get a billing portal?
      // This just bypasses everything in the callback.
      if (response.data.status && response.data.data)
        callback(response.data.data)
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const getPromoCodes =
  (dispatch) =>
  async (purpose, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance(true).get(
        `/api/promo-codes/${purpose}`
      )

      if (response.data.status && response.data.data)
        callback(response.data.data)
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }
