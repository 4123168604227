import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Landing from '../AmbLdg';
import { useStateValue } from 'state';

const Preview = () => {
  const { action: { getAmbassadorSetupData } } = useStateValue();
  const { uniqueLink } = useParams();
  const [ambassadorSetup, setAmbassadorSetup] = useState(null);
  const [campaignId, setCampaignId] = useState();
  const [camp, setCamp] = useState(null);

  // preview query param causes publicLinkViews to not increment
  useEffect(() => getAmbassadorSetupData({ type: 'c', uniqueLink, preview: true}, ({
    Campaign: { id, AmbassadorSetup, Camp },
  }) => {
    setCampaignId(id);
    setAmbassadorSetup(AmbassadorSetup);
    setCamp(Camp);
    // eslint-disable-next-line
  }), [uniqueLink]);

  if (ambassadorSetup && camp)
    return <Landing {...ambassadorSetup}
      camp={camp} campaignId={campaignId}
      uniqueLink={uniqueLink}
      onSubmit={(args, callback) => callback(args)}
    />;

  return <React.Fragment />;
};

export default Preview;
