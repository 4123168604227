import * as authActions from './auth'
import * as campActions from './camp'
import * as campaignActions from './campaign'
import * as campaignTemplateActions from './campaignTemplate'
import * as publicActions from './public'
import * as referralActions from './referral'
import * as personActions from './person'
import * as ambassadorActions from './ambassador'
import * as ambassadorSetupActions from './ambassador-setup'
import * as prospectSetupActions from './prospect-setup'
import * as rewardActions from './reward'
import * as StripeReducers from './stripe'
const mainActions = {
  ...authActions,
  ...campActions,
  ...campaignActions,
  ...campaignTemplateActions,
  ...publicActions,
  ...referralActions,
  ...personActions,
  ...ambassadorActions,
  ...ambassadorSetupActions,
  ...prospectSetupActions,
  ...rewardActions,
  ...StripeReducers,
}

export default mainActions
