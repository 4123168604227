import React, { useEffect, useState } from 'react'
import styles from './HtmlEditor.module.scss'
import { Editor } from '@tinymce/tinymce-react'
import clsx from 'clsx'

const HtmlEditor = ({
  value: initValue = '',
  editorRef = null,
  isError = false,
  captionText = null,
  disabled = false,
  isPreviewHidden = false,
  onChange = () => null,
  onPreview = () => null,
}) => {
  const [isLoaded, setLoaded] = useState(false)
  const [value, setValue] = useState('')

  useEffect(() => {
    if (value !== initValue && !isLoaded) {
      setValue(initValue)
      setLoaded(true)
    }
    // eslint-disable-next-line
  }, [initValue])

  const onInit = (evt, editor) => {
    editor.shortcuts.remove('alt+0') // remove help

    if (editorRef) editorRef.current = editor
  }

  return (
    <div
      className={clsx(
        styles.html_editor,
        isPreviewHidden && 'preview-hidden',
        'html-editor',
        isError && 'html-editor--error'
      )}
    >
      <Editor
        apiKey="efatm7j3exz994xpsyujzboiyfylqiv7fqbhvwv2md2o0qur"
        onInit={onInit}
        initialValue={value}
        disabled={disabled}
        onKeyUp={onChange}
        onBlur={onChange} // This is the only way to capture changes to a link
        init={{
          height: 300,
          menubar: false,
          plugins: 'link anchor image lists',
          toolbar:
            'ct-bold ct-italic ct-strike ct-underline ct-image ct-preview',
          help_tabs: false,
          statusbar: false,
          content_css: `${process.env.PUBLIC_URL}/stylesheets/html-editor.css`,
          setup: (editor) => {
            editor.on('Change', onChange)

            editor.ui.registry.addIcon(
              'ct-bold-icon',
              '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.2025 11.1283C15.4684 11.357 16.4177 11.7637 17.0506 12.3482C17.6835 12.9264 18 13.6826 18 14.6166C18 15.665 17.5949 16.491 16.7848 17.0946C15.9747 17.6982 14.6297 18 12.75 18H6V16.9421C6.67722 16.9421 7.12658 16.8372 7.3481 16.6276C7.56962 16.4115 7.68038 15.989 7.68038 15.36V7.78299C7.68038 7.17302 7.57595 6.75684 7.36709 6.53446C7.15823 6.31207 6.70253 6.20088 6 6.20088V5.14296L10.9462 5H11.6487C13.8766 5 15.3956 5.27957 16.2057 5.83871C17.0158 6.3915 17.4209 7.14761 17.4209 8.10704C17.4209 8.83138 17.1519 9.44453 16.6139 9.94648C16.0823 10.4484 15.2785 10.8424 14.2025 11.1283ZM11.0506 10.5755H11.7057C12.4019 10.5755 12.9209 10.4135 13.2627 10.0894C13.6044 9.75904 13.7753 9.19037 13.7753 8.38343C13.7753 7.77981 13.6677 7.30327 13.4525 6.95381C13.2373 6.60435 12.9525 6.37879 12.5981 6.27713C12.25 6.17546 11.7342 6.12463 11.0506 6.12463V10.5755ZM11.0506 11.7383V15.4076C11.0506 15.7634 11.0854 16.0335 11.1551 16.2177C11.231 16.402 11.3734 16.5577 11.5823 16.6848C11.7975 16.8055 12.0981 16.8658 12.4842 16.8658C13.0854 16.8658 13.5348 16.653 13.8323 16.2273C14.1361 15.8016 14.288 15.163 14.288 14.3116C14.288 13.6317 14.1899 13.1012 13.9937 12.7199C13.7975 12.3324 13.5285 12.0718 13.1867 11.9384C12.8449 11.805 12.3386 11.7383 11.6677 11.7383H11.0506Z" fill="#999999" /></svg>'
            )
            editor.ui.registry.addIcon(
              'ct-italic-icon',
              '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.4259 16.9263L13.1399 18H8L8.28601 16.9263C8.61129 16.9071 8.87206 16.8176 9.06835 16.6578C9.26463 16.498 9.45531 16.0634 9.64038 15.354L11.7687 7.4351C11.8247 7.2178 11.8528 7.05482 11.8528 6.94617C11.8528 6.65855 11.7518 6.44125 11.5499 6.29425C11.3537 6.14086 11.1349 6.06416 10.8938 6.06416L11.1714 5H16L15.7056 6.06416C15.2008 6.1089 14.8419 6.53392 14.6288 7.33923L12.5258 15.1718C12.408 15.6064 12.3491 15.8941 12.3491 16.0347C12.3491 16.3095 12.4501 16.5268 12.6519 16.6866C12.8538 16.8464 13.1118 16.9263 13.4259 16.9263Z" fill="#999999" /></svg>'
            )
            editor.ui.registry.addIcon(
              'ct-strike-icon',
              '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.00927 17.5291V13.245H8.12152C8.43048 14.5253 8.89392 15.4394 9.51184 15.9872C10.1298 16.535 10.7971 16.8089 11.5139 16.8089C12.0453 16.8089 12.4686 16.652 12.7837 16.3381C13.0989 16.0241 13.2564 15.6548 13.2564 15.2301C13.2564 14.9285 13.1576 14.6269 12.9598 14.3253C12.7621 14.0237 12.2925 13.6882 11.551 13.3189C10.1236 12.611 9.15963 12.094 8.65911 11.7678C8.1586 11.4354 7.75695 11.0107 7.45417 10.4936C7.15139 9.97656 7 9.38258 7 8.71165C7 7.63447 7.3862 6.74811 8.1586 6.05256C8.93718 5.35085 9.83625 5 10.8558 5C11.9248 5 12.8332 5.35701 13.5808 6.07102C13.7044 5.88636 13.8064 5.60322 13.8867 5.22159H14.9526V8.91477H13.9145C13.7291 8.09612 13.3769 7.42211 12.8579 6.89276C12.3388 6.3634 11.7549 6.09872 11.1061 6.09872C10.6179 6.09872 10.2286 6.23414 9.93821 6.50497C9.65396 6.7758 9.51184 7.11435 9.51184 7.5206C9.51184 7.83452 9.60453 8.12689 9.78991 8.39773C9.97528 8.66241 10.2255 8.884 10.5407 9.0625C10.8558 9.23485 11.5232 9.558 12.5427 10.032C13.4573 10.4628 14.1339 10.8475 14.5726 11.1861C15.0113 11.5246 15.3574 11.9524 15.6107 12.4695C15.8702 12.9804 16 13.5343 16 14.1314C16 15.2393 15.5891 16.1626 14.7673 16.9013C13.9454 17.6338 12.966 18 11.829 18C11.1802 18 10.5438 17.8646 9.91967 17.5938C9.30175 17.3229 8.80742 16.9628 8.43666 16.5135C8.29454 16.7905 8.1895 17.129 8.12152 17.5291H7.00927Z" fill="#999999" /><path d="M4 11H20" stroke="#999999" stroke-linejoin="round" /></svg>'
            )
            editor.ui.registry.addIcon(
              'ct-underline-icon',
              '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 19.918H20" stroke="#999999" stroke-linejoin="round" /><path d="M4 6.04263V5H11.1315V6.04263H10.9024C10.2317 6.04263 9.79681 6.15848 9.59761 6.39017C9.39841 6.62187 9.2988 7.02577 9.2988 7.60188V12.6272C9.2988 13.5414 9.37849 14.2616 9.53785 14.7876C9.69721 15.3136 10.0458 15.7613 10.5837 16.1308C11.1215 16.5002 11.7656 16.685 12.5159 16.685C13.3327 16.685 14.0299 16.4877 14.6076 16.0932C15.1919 15.6987 15.5704 15.2197 15.743 14.6561C15.9157 14.0862 16.002 13.2941 16.002 12.2796V7.99639C16.002 6.69388 15.3347 6.04263 14 6.04263V5H19V6.04263C18.7145 6.04263 18.4157 6.12091 18.1036 6.27746C17.7915 6.42775 17.5757 6.64066 17.4562 6.91618C17.3367 7.18545 17.2769 7.56431 17.2769 8.05275V12.3078C17.2769 13.2221 17.2138 13.9704 17.0876 14.5527C16.9681 15.1289 16.6793 15.6862 16.2211 16.2247C15.7629 16.757 15.1255 17.1859 14.3088 17.5116C13.492 17.8372 12.5857 18 11.5896 18C10.5471 18 9.61089 17.8403 8.78088 17.521C7.9575 17.1953 7.32669 16.7883 6.88845 16.2999C6.4502 15.8052 6.15139 15.2697 5.99203 14.6936C5.83931 14.1113 5.76295 13.4224 5.76295 12.6272V7.60188C5.76295 7.00072 5.65339 6.59056 5.43426 6.37139C5.21514 6.15222 4.73705 6.04263 4 6.04263Z" fill="#999999" /></svg>'
            )
            editor.ui.registry.addIcon(
              'ct-image-icon',
              '<svg class="ct-image-svg" width="24" height="24" focusable="false"><path d="M5 15.7l3.3-3.2c.3-.3.7-.3 1 0L12 15l4.1-4c.3-.4.8-.4 1 0l2 1.9V5H5v10.7zM5 18V19h3l2.8-2.9-2-2L5 17.9zm14-3l-2.5-2.4-6.4 6.5H19v-4zM4 3h16c.6 0 1 .4 1 1v16c0 .6-.4 1-1 1H4a1 1 0 01-1-1V4c0-.6.4-1 1-1zm6 8a2 2 0 100-4 2 2 0 000 4z" fill-rule="nonzero"></path></svg>'
            )

            editor.ui.registry.addToggleButton('ct-bold', {
              icon: 'ct-bold-icon',
              onAction: () =>
                editor.execCommand('mceToggleFormat', false, 'bold'),
              onSetup: (api) =>
                editor.formatter.formatChanged('bold', (state) =>
                  api.setActive(state)
                ),
            })

            editor.ui.registry.addToggleButton('ct-italic', {
              icon: 'ct-italic-icon',
              onAction: () =>
                editor.execCommand('mceToggleFormat', false, 'italic'),
              onSetup: (api) =>
                editor.formatter.formatChanged('italic', (state) =>
                  api.setActive(state)
                ),
            })

            editor.ui.registry.addToggleButton('ct-strike', {
              icon: 'ct-strike-icon',
              onAction: () =>
                editor.execCommand('mceToggleFormat', false, 'strikethrough'),
              onSetup: (api) =>
                editor.formatter.formatChanged('strikethrough', (state) =>
                  api.setActive(state)
                ),
            })

            editor.ui.registry.addToggleButton('ct-underline', {
              icon: 'ct-underline-icon',
              onAction: () =>
                editor.execCommand('mceToggleFormat', false, 'underline'),
              onSetup: (api) =>
                editor.formatter.formatChanged('underline', (state) =>
                  api.setActive(state)
                ),
            })

            editor.ui.registry.addToggleButton('ct-image', {
              icon: 'ct-image-icon',
              onAction: () => editor.execCommand('mceImage'),
            })

            editor.ui.registry.addButton('ct-preview', {
              text: 'Preview',
              onAction: onPreview,
            })
          },
        }}
      />

      <p className={clsx(styles.caption, isError && styles.caption__error)}>
        {captionText ? <span>{captionText}</span> : null}
      </p>
    </div>
  )
}

export default HtmlEditor
