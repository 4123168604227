import { getAxiosInstance, handleError } from 'utils';
import { ACTION } from 'utils/constants';

export const updateAmbassadorSetup = dispatch => async (ambassadorSetupDetails, callback = () => null) => {
  try {
    dispatch({ type: ACTION.START });

    const formData = new FormData();

    Object.keys(ambassadorSetupDetails).forEach(key => {
      const data = ambassadorSetupDetails[key];

      if (data || data === "") formData.append(key, data);
    });

    const args = [`/api/campaigns/${ambassadorSetupDetails.campaignId}/ambassador-setup/`, formData]

    const response = await (
      ambassadorSetupDetails.id ? getAxiosInstance().put(...args) : getAxiosInstance().post(...args)
    );

    const { success, code, message, ambassadorSetup } = response.data;

    if (success) callback(ambassadorSetup);
    else throw new Error(JSON.stringify({ code, message }));
  } catch (error) {
    handleError(dispatch, error);
  } finally {
    dispatch({ type: ACTION.END });
  }
};

export const popupError = dispatch => async ( error, callback = () => null) => {
  handleError(dispatch, error)
}
