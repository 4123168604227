import { ExcludedIcon, Modal, Subscriptions } from 'components'
import React from 'react'
import styles from './SubscriptionMethod.module.scss'

const SubscriptionMethod = ({
  show,
  title = 'Select Your Subscription Payment Option',
  monthly_price = 10,
  description,
  yearly_price = 10,
  note,
  setupFee,
  includes = [],
  terms = [],
  submitButtonText,
  onOK = () => null,
  onCancel = () => null,
}) => {
  return (
    <Modal
      classNames={{ modal: styles.modal }}
      show={show}
      onClose={() => onCancel()}
    >
      <h6 className={styles.title}>{title}</h6>

      <button type="button" className={styles.close} onClick={onCancel}>
        <ExcludedIcon />
      </button>

      <Subscriptions
        monthly_price={monthly_price}
        yearly_price={yearly_price}
        description={description}
        note={note}
        setupFee={setupFee}
        submitButtonText={submitButtonText}
        onOK={onOK}
        includes={includes}
        terms={terms}
        purpose="additional_camp"
      />
    </Modal>
  )
}

export default SubscriptionMethod
