import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { EyeVisibleIcon, EyeInvisibleIcon } from 'components';
import styles from './PasswordInput.module.scss';

const PasswordInput = ({
  classNames = { container: '', label: '', input: '' },
  label = null, placeholder = '', value: initValue = '', captionText = null,
  onChange: handleChangeValue = () => null, isError = false,
}) => {
  const [isVisible, setVisible] = useState(false);
  const [value, setValue] = useState('');

  useEffect(() => setValue(initValue || ''), [initValue]);

  const handleChange = e => {
    setValue(e.target.value);
    handleChangeValue(e.target.value);
  };

  return (
    <div className={clsx(classNames.container, styles.text_input__wrapper)}>
      {label ? <label className={clsx(styles.text_input__label, classNames.label)}>{label}</label> : null}

      <input
        className={clsx(
          classNames.input,
          styles.text_input,
          isError && styles[`text_input--error`]
        )}
        type={isVisible ? 'text' : 'password'}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
      />

      <span
        className={styles.text_input__icon}
        onClick={() => setVisible(!isVisible)}
      >{isVisible ? <EyeVisibleIcon /> : <EyeInvisibleIcon />}</span>

      <p className={clsx(styles.caption, isError && styles.caption__error)}>{captionText ? <span>{captionText}</span> : null}</p>
    </div>
  );
};

export default PasswordInput;
