import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import styles from './TextArea.module.scss'

const TextArea = ({
  classNames = { container: '', label: '', textarea: '' },
  tabIndex = '',
  label = null,
  placeholder = '',
  value: initValue = '',
  captionText = null,
  requiredShown = false,
  optionalShown = false,
  isError = false,
  readOnly = false,
  disabled = false,
  onChange: handleChangeValue = () => null,
  rows = 3,
}) => {
  const [value, setValue] = useState('')

  useEffect(() => setValue(initValue || ''), [initValue])

  const handleChange = (e) => {
    setValue(e.target.value)
    handleChangeValue(e.target.value)
  }

  return (
    <div className={clsx(classNames.container, styles.text_textarea__wrapper)}>
      {label ? (
        <label className={clsx(classNames.label, styles.text_textarea__label)}>
          {label}
          {requiredShown ? (
            <span className={styles.text_textarea__required}>&nbsp;*</span>
          ) : null}
          {optionalShown ? (
            <span className={styles.text_textarea__optional}>
              &nbsp;(optional)
            </span>
          ) : null}
        </label>
      ) : null}

      <textarea
        className={clsx(
          classNames.textarea,
          styles.text_textarea,
          isError && styles[`text_textarea--error`]
        )}
        tabIndex={tabIndex}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        rows={rows}
        readOnly={readOnly}
        disabled={disabled}
      />

      <p className={clsx(styles.caption, isError && styles.caption__error)}>
        {captionText ? <span>{captionText}</span> : null}
      </p>
    </div>
  )
}

export default TextArea
