export const getFormikCaptionTextData = (formik, formikId, defaultOutput = {}) => {
  try {
    if (formik.touched[formikId] && formik.errors[formikId]) return {
      isError: true,
      captionText: formik.errors[formikId]
    };

    return defaultOutput;
  } catch (error) {
    return defaultOutput;
  }
};