import _ from 'lodash'
import { ACTION, CAMPAIGN_STATUS } from 'utils/constants'

const campaignReducer = (state, { type, payload }) => {
  switch (type) {
    case ACTION.CAMPAIGN__GET_ALL:
      return {
        ...state,
        incompleteCampaigns: payload.campaigns.filter(
          ({ status }) => status === CAMPAIGN_STATUS.INCOMPLETE
        ),
        activeCampaigns: payload.campaigns.filter(
          ({ status }) => status === CAMPAIGN_STATUS.ACTIVE
        ),
        closedCampaigns: payload.campaigns.filter(
          ({ status, deleted_at }) =>
            status === CAMPAIGN_STATUS.CLOSED && deleted_at === null
        ),
        archivedCampaigns: payload.campaigns.filter(
          ({ status, deleted_at }) =>
            status === CAMPAIGN_STATUS.CLOSED && deleted_at !== null
        ),
      }

    case ACTION.CAMPAIGN__ADD:
      return {
        ...state,
        incompleteCampaigns: _.concat(
          state.incompleteCampaigns,
          payload.campaign
        ),
      }

    case ACTION.CAMPAIGN__UPDATE:
      return {
        ...state,
        incompleteCampaigns: state.incompleteCampaigns.map((campaign) => ({
          ...campaign,
          ...(campaign.id !== payload.campaign.id ? {} : payload.campaign),
        })),
      }

    case ACTION.CAMPAIGN__DELETE: {
      return {
        ...state,
        incompleteCampaigns: state.incompleteCampaigns.filter(
          ({ id }) => id !== payload.campaignId
        ),
        closedCampaigns: state.closedCampaigns.filter(
          ({ id }) => id !== payload.campaignId
        ),
        archivedCampaigns: _.concat(state.archivedCampaigns, payload.deleted),
      }
    }

    case ACTION.CAMPAIGN__CLOSE: {
      return {
        ...state,
        activeCampaigns: state.activeCampaigns.filter(
          ({ id }) => id !== payload.campaign.id
        ),
        closedCampaigns: _.concat(state.closedCampaigns, payload.campaign),
      }
    }

    default:
      return state
  }
}

export default campaignReducer
