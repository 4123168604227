import { getAxiosInstance, handleError } from 'utils'
import { ACTION, CAMPAIGN_STATUS, MESSAGE } from 'utils/constants'

export const getAllCampaigns =
  (dispatch) =>
  async (campId, callback = () => null, errCallback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().get('/api/campaigns/all', {
        params: { campId },
      })

      const { success, campaigns, code, message } = response.data

      if (success) {
        dispatch({ type: ACTION.CAMPAIGN__GET_ALL, payload: { campaigns } })

        callback()
      } else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      errCallback(error)
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const getCampaign =
  (dispatch) =>
  async (id, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().get(`/api/campaigns/${id}`)

      const { success, campaign, code, message } = response.data

      if (success) callback(campaign)
      else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const addCampaign =
  (dispatch) =>
  async (campId, data, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().post(
        `/api/camps/${campId}/campaigns`,
        data
      )

      const { success, code, message, campaign } = response.data

      if (success) {
        dispatch({ type: ACTION.CAMPAIGN__ADD, payload: { campaign } })
        callback(campaign)
      } else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const updateCampaign =
  (dispatch) =>
  async (id, data, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().put(
        `/api/campaigns/${id}`,
        data
      )

      const { success, code, message, campaign } = response.data

      if (success) {
        dispatch({ type: ACTION.CAMPAIGN__UPDATE, payload: { campaign } })
        callback(campaign)
      } else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const deleteCampaign =
  (dispatch) =>
  async (campaignId, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().delete(
        `/api/campaigns/${campaignId}`
      )

      const { success, code, message, deleted } = response.data

      if (success) {
        dispatch({
          type: ACTION.CAMPAIGN__DELETE,
          payload: { campaignId, deleted },
        })
        callback()
      } else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const getDefaultCampaign =
  (dispatch) =>
  async ({ campId, campaignType }, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().post(
        `/api/camps/${campId}/campaigns/new/${campaignType}`
      )

      const { success, code, message, campaign } = response.data

      if (success) callback(campaign)
      else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const launchCampaign =
  (dispatch) =>
  async (campaignId, data, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().post(
        `/api/campaigns/${campaignId}/email/${MESSAGE.A__INVITATION.TYPE}`,
        data
      )
      const { success, code, message, emailed } = response.data

      if (success) callback(emailed)
      else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const getCampaignPerformance =
  (dispatch) =>
  async (id, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().get(
        `/api/campaigns/${id}/performance`
      )

      const { success, code, message, performance } = response.data

      if (success) callback(performance)
      else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const getAmbassadorRewardSummaries =
  (dispatch) =>
  async (id, callback = () => console.log('no callback')) => {
    const url = `/api/campaigns/${id}/ambassador-rewards/summary`
    try {
      dispatch({ type: ACTION.START })
      const response = await getAxiosInstance().get(url)
      const {
        success,
        code,
        message,
        referrals,
        ambassadorRewards,
        prospectRewards,
      } = response.data
      if (success) callback({ referrals, ambassadorRewards, prospectRewards })
      else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const getVerificationReport =
  (dispatch) =>
  async (id, callback = () => console.log('no callback')) => {
    const url = `/api/campaigns/${id}/performance/verification`
    try {
      dispatch({ type: ACTION.START })
      const response = await getAxiosInstance().get(url)
      const { success, code, message, report, headers } = response.data
      if (success) callback({ report, headers })
      else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const getPerformanceReport =
  (dispatch) =>
  async (id, callback = () => console.log('no callback')) => {
    const url = `/api/campaigns/${id}/performance/ambassador`
    try {
      dispatch({ type: ACTION.START })
      const response = await getAxiosInstance().get(url)
      const { success, code, message, report, headers } = response.data
      if (success) callback({ report, headers })
      else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const getCampaignSummaries =
  (dispatch) =>
  async (campId, status = 'ALL', callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      if (!campId) return []

      const response = await getAxiosInstance().get(
        `/api/campaigns/?campId=${campId}&status=${status}`
      )

      const { success, code, message, campaigns } = response.data
      if (success) callback(campaigns)
      else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      console.error(error)
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const getCampaignRewards =
  (dispatch) =>
  async (id, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().get(
        `/api/campaigns/${id}/rewards`
      )

      const { success, code, message, rewards } = response.data

      if (success) callback(rewards)
      else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const copyCampaign =
  (dispatch) =>
  async (campId, campaignId, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().post(
        `/api/camps/${campId}/campaigns/${campaignId}/copy`
      )

      const { success, code, message, campaign } = response.data

      if (success) {
        callback(campaign)
        dispatch({ type: ACTION.CAMPAIGN__ADD, payload: { campaign } })
      } else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const closeCampaign =
  (dispatch) =>
  async (id, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().put(`/api/campaigns/${id}`, {
        status: CAMPAIGN_STATUS.CLOSED,
      })

      const { success, code, message, campaign } = response.data

      if (success) {
        dispatch({ type: ACTION.CAMPAIGN__CLOSE, payload: { campaign } })
        callback(campaign)
      } else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const uploadCampaignSourceFiles =
  (dispatch) =>
  async (id, sourceFiles = [], callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const results = await Promise.all(
        sourceFiles.map(
          (sourceFile) =>
            new Promise((resolve, reject) =>
              getAxiosInstance()
                .post(`/api/campaigns/${id}/import-source-file/${sourceFile}`)
                .then(({ data }) => resolve(data))
                .catch(reject)
            )
        )
      )

      callback()

      results.forEach(({ success, code, message }) => {
        if (!success) throw new Error(JSON.stringify({ code, message }))
      })
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }
