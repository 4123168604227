import React from 'react';
import clsx from 'clsx';
import styles from './LoadingOverlay.module.scss';
import { LoadingIcon } from 'components';
import { useScrollLock } from 'hooks';

const LoadingOverlay = ({ className, isLoading = true }) => {
  useScrollLock(isLoading);

  if (!isLoading) return <React.Fragment />;

  return (
    <div className={clsx(className, styles.page)}>
      <LoadingIcon />
    </div>
  );
};

export default LoadingOverlay;