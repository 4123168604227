import React from 'react'
import { Navigate } from 'react-router-dom'
import { useStateValue } from 'state'
import { AUTH_STATE } from 'utils/constants'

const NonAuthRoute = (props) => {
  const {
    state: { auth },
  } = useStateValue()

  return auth.state === AUTH_STATE.SIGNED_IN ? (
    <Navigate to="/" />
  ) : (
    <>{props.children}</>
  )
}

export default NonAuthRoute
