import { ACTION, AUTH_STATE } from 'utils/constants';
import { clearAuthToken } from 'utils';

const authReducer = (state, { type, payload }) => {
  switch (type) {
    case ACTION.AUTH__SIGN_IN:
    case ACTION.AUTH__UPDATE_PROFILE:
      return {
        ...state,
        ...payload,
        state: AUTH_STATE.SIGNED_IN,
      };

    case ACTION.AUTH__SIGN_OUT:
      clearAuthToken();

      return {
        ...state,
        state: AUTH_STATE.SIGNED_OUT,
        user: null
      };

    default:
      return state;
  }
};

export default authReducer;
