import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useStateValue } from 'state'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Modal,
  PasswordInput,
  PrimaryButton,
  SecondaryButton,
  ExcludedIcon,
} from 'components'
import styles from './ChangePassword.module.scss'
import { ERR_CODE } from 'utils/constants'

const ChangePassword = ({ show, onClose = () => null }) => {
  const {
    state: { auth, deviceSize },
    action: { updatePassword },
  } = useStateValue()

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .required('Please enter a password.'),
      newPassword: Yup.string()
        .required('Please enter a password.')
        .min(
          8,
          ({ min }) => `Password is too short - should be ${min} chars minimum.`
        )
        .max(
          20,
          ({ max }) => `Password is too long - should be ${max} chars maximum.`
        )
        .matches(/[A-Z]/, 'Must contain at least one uppercase character.')
        .matches(/[a-z]/, 'Must contain at least one lowercase character.')
        .matches(/[0-9]/, 'Must contain at least one number.'),
      confirmPassword: Yup.string()
        .required('Please enter a confirm password.')
        .oneOf([Yup.ref('newPassword')], 'Passwords must match.'),
    }),
    onSubmit: ({ oldPassword, newPassword }) => {
      updatePassword({ oldPassword, newPassword }, updateResult)
    },
  })

  const updateResult = (result) => {
    if (result.success){
      onClose()
    } else {
      formik.errors.oldPassword = result.message
    }
  }

  useEffect(() => {
    switch (auth.code) {
      case ERR_CODE.AUTH_PASSWORD:
        console.error('bad password')
        formik.setFieldError('oldPassword', auth.message)
        break

      case ERR_CODE.AUTH_NEW_PASSWORD:
        formik.setFieldError('newPassword', auth.message)
        break

      case ERR_CODE.AUTH_TOKEN:
      case ERR_CODE.AUTH_UNKNOWN:
        // alert(auth.message);
        break

      default:
        formik.setErrors({})
        break
    }
    // eslint-disable-next-line
  }, [auth.code, auth.message])

  return (
    <Modal classNames={{ modal: styles.modal }} show={show} onClose={onClose}>
      <h6 className={styles.title}>Change Password</h6>

      <button type="button" className={styles.close} onClick={onClose}>
        <ExcludedIcon />
      </button>

      <form onSubmit={formik.handleSubmit}>
        <PasswordInput
          label="Old Password"
          value={formik.values.oldPassword}
          onChange={(oldPassword) =>
            formik.setFieldValue('oldPassword', oldPassword)
          }
          {...(formik.touched.oldPassword && formik.errors.oldPassword
            ? { isError: true, captionText: formik.errors.oldPassword }
            : {})}
        />

        <PasswordInput
          label="Password"
          value={formik.values.newPassword}
          onChange={(newPassword) =>
            formik.setFieldValue('newPassword', newPassword)
          }
          {...(formik.touched.newPassword && formik.errors.newPassword
            ? { isError: true, captionText: formik.errors.newPassword }
            : {})}
        />

        <PasswordInput
          label="Confirm Password"
          value={formik.values.confirmPassword}
          onChange={(confirmPassword) =>
            formik.setFieldValue('confirmPassword', confirmPassword)
          }
          {...(formik.touched.confirmPassword && formik.errors.confirmPassword
            ? { isError: true, captionText: formik.errors.confirmPassword }
            : {})}
        />

        <div className={styles.forgot_password}>
          <Link
            to="/forgot-password"
            dangerouslySetInnerHTML={{ __html: 'Forgot password?' }}
          />
        </div>

        <div className={styles.form__buttons}>
          <SecondaryButton
            className={styles.form__cancel}
            label={['xs', 'sm', 'md'].includes(deviceSize) ? 'Back' : 'Cancel'}
            onClick={onClose}
          />

          <PrimaryButton
            className={styles.form__save}
            label="Save"
            onClick={formik.handleSubmit}
          />
        </div>
      </form>
    </Modal>
  )
}

export default ChangePassword
