import React from 'react'
import { Link } from 'react-router-dom'
import {
  LogoIcon,
  TextInput,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  Illustration2,
} from 'components'
import styles from './ForgotPassword.module.scss'
import { useStateValue } from 'state'
import { useNavigate } from 'hooks'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { PATH } from 'utils/constants'
import { toast } from 'react-toastify'

const ForgotPassword = () => {
  const navigate = useNavigate()
  const {
    state: { deviceSize },
    action: { forgotPassword },
  } = useStateValue()

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: Yup.object({
      email: Yup.string()
        .required('Please enter your email.')
        .email('Please enter a valid email.'),
    }),
    onSubmit: ({ email }) =>
      forgotPassword(email, () => toast.success('Please check your email.')),
  })

  const handleClickSignIn = () => navigate({ pathname: PATH.AUTH__SIGN_IN })

  return (
    <div className={styles.page__container}>
      <div className={styles.page__wrapper}>
        <div className={styles.page}>
          <Link className={styles.logo} to="/">
            <LogoIcon />
          </Link>

          <p className={styles.description}>Password Reset</p>

          <form>
            <TextInput
              classNames={{ container: styles.input }}
              label={
                ['lg', 'xl', 'xxl'].includes(deviceSize)
                  ? 'Your Email'
                  : 'Email'
              }
              value={formik.values.email}
              onChange={(email) => formik.setFieldValue('email', email)}
              {...(formik.touched.email && formik.errors.email
                ? { isError: true, captionText: formik.errors.email }
                : {})}
            />

            <div className={styles.buttons__container}>
              {['md', 'lg', 'xl', 'xxl'].includes(deviceSize) ? (
                <SecondaryButton
                  className={styles.button__login}
                  label="LOGIN"
                  onClick={handleClickSignIn}
                />
              ) : null}
              <PrimaryButton
                className={styles.button__send}
                label="SEND"
                onClick={formik.handleSubmit}
              />
              {['md', 'lg', 'xl', 'xxl'].includes(deviceSize) ? null : (
                <TertiaryButton
                  className={styles.button__login}
                  label="LOGIN"
                  onClick={handleClickSignIn}
                />
              )}
            </div>
          </form>
        </div>

        {deviceSize === 'xxl' ? (
          <Illustration2 className={styles.page__illustration} />
        ) : null}
      </div>
    </div>
  )
}

export default ForgotPassword
