import React from 'react';
import styles from './AddRole.module.scss';
import {
  Modal, PrimaryButton, SecondaryButton, TextInput, ExcludedIcon
} from 'components';
import { useStateValue } from 'state';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CAMP_ROLE } from 'utils/constants';

const AddRole = ({ show, onClose = () => null, onAdd = () => null }) => {
  const { state: { deviceSize } } = useStateValue();
  // const initRoles = [CAMP_ROLE.ADMIN];

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      role: CAMP_ROLE.ADMIN
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please enter your name.'),
      email: Yup.string()
        .required('Please enter your email.')
        .email('Please enter a valid email.'),
      // role: Yup.string().required('Please select a role.')
    }),
    onSubmit: role => {
      onAdd(role);
      handleClose();
    }
  })

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <Modal classNames={{ modal: styles.modal, content: styles.modal__content }} show={show} onClose={onClose}>
      <h6 className={styles.title}>Add Camp Admin User</h6>

      <button type='button' className={styles.close} onClick={handleClose}>
        <ExcludedIcon />
      </button>

      <form className={styles.form}>
        <div className={styles.form__row}>
          <TextInput
            label='Name'
            placeholder='Name'
            value={formik.values.name}
            onChange={name => formik.setFieldValue('name', name)}
            {...(formik.touched.name && formik.errors.name ? { isError: true, captionText: formik.errors.name } : {})}
            requiredShown
          />
        </div>

        <div className={styles.form__row}>
          <TextInput
            label='Email'
            placeholder='Email'
            value={formik.values.email}
            onChange={email => formik.setFieldValue('email', email)}
            {...(formik.touched.email && formik.errors.email ? { isError: true, captionText: formik.errors.email } : {})}
            requiredShown
          />
        </div>


        <div className={styles.form__buttons}>
          <SecondaryButton
            className={styles.form__cancel}
            label={['xs', 'sm', 'md'].includes(deviceSize) ? 'Back' : 'Cancel'}
            onClick={handleClose}
          />

          <PrimaryButton
            className={styles.form__save}
            label='Save'
            onClick={formik.handleSubmit}
          />
        </div>
      </form>
    </Modal>
  );
};

export default AddRole;
