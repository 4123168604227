import { useEffect, Fragment } from 'react';
import { useStateValue } from 'state';
import { AUTH_STATE } from 'utils/constants';
import { toast } from 'react-toastify';
import { readAuthToken, isAuthError } from 'utils';

const AuthOverlay = ({ children }) => {
  const {
    state: { isLoading, auth, error },
    action: { userSignIn }
  } = useStateValue();

  useEffect(() => {
    if (isLoading || auth.state !== AUTH_STATE.SIGNED_IN || auth.user) return;

    if (readAuthToken()) userSignIn();
    // eslint-disable-next-line
  }, [isLoading, auth.state, auth.user]);

  useEffect(() => {
    if (!error || isAuthError(error)) return;
    toast.error(error.message, {autoClose: 10000});
  }, [error]);

  if (auth.state === AUTH_STATE.SIGNED_IN && !auth.user) return <Fragment />;

  return <Fragment>{children}</Fragment>;
};

export default AuthOverlay;
