import React, { useState } from 'react'
import styles from './HelpModal.module.scss'
import { ExcludedIcon, HelpIconSmall, Modal, SecondaryButton } from 'components'

const HelpModal = () => {
  const [showHelp, setShowHelp] = useState(false)

  return (
    <>
      <div className={styles.form__header__desc}>
        <HelpIconSmall
          className={styles.help_icon}
          onClick={() => {
            setShowHelp(true)
          }}
        />
      </div>
      <Modal
        classNames={{ modal: styles.modal }}
        show={showHelp}
        onClose={() => {
          setShowHelp(false)
        }}
      >
        <h6 className={styles.title}>How to use Sample CSV</h6>

        <p className={styles.body}>
          <ol>
            <li>Download Sample CSV file.</li>
            <li>
              Copy data into the existing columns (keep existing headings, do
              not add spaces or punctuation).
            </li>
            <li>
              Do not make edits to this file or it will not save properly.
            </li>
            <li>
              You can change the file name when saving. but it must be saved in
              the format downloaded (i.e. CSV UTF-8 (Comma delimited) (*.csv)
            </li>
            <li>
              Upload the same file you downloaded but now populated with your
              data.
            </li>
          </ol>
        </p>

        <button
          type="button"
          className={styles.close}
          onClick={() => {
            setShowHelp(false)
          }}
        >
          <ExcludedIcon />
        </button>

        <div className={styles.buttons}>
          <SecondaryButton
            className={styles.button__cancel}
            label="Close"
            onClick={() => {
              setShowHelp(false)
            }}
          />
        </div>
      </Modal>
    </>
  )
}

export default HelpModal
