import { CAMPAIGN_TYPE } from './constants'

export const getCampaignTypeAsText = (type) => {
  if (type === CAMPAIGN_TYPE.REGISTRATION) return 'Camper'
  if (type === 'REGISTRATION') return 'Camper'
  if (type === CAMPAIGN_TYPE.COUNSELOR) return 'Counselor'
  if (type === CAMPAIGN_TYPE.CUSTOM) return 'Custom'

  return ''
}

