import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { CheckMarkIcon } from 'components';
import styles from './Checkbox.module.scss';

const Checkbox = ({
  className,
  label = null,
  disabled = false,
  readOnly = false,
  isChecked: initIsChecked = false,
  onChange: handleChangeValue = () => null
}) => {
  const [states, setStates] = useState([]);
  const [isChecked, setChecked] = useState(false);

  useEffect(() => setChecked(initIsChecked), [initIsChecked]);

  const handleChange = () => {
    if (disabled || readOnly) return;

    setChecked(!isChecked);
    handleChangeValue(!isChecked);
  };

  return (
    <div
      className={clsx(className, styles.checkbox, disabled && styles.checkbox__disabled)}
      onClick={handleChange}
      onMouseEnter={() => !disabled && setStates(states => [...states, 'hovered'])}
      onMouseLeave={() => !disabled && setStates([])}
      onMouseDown={() => !disabled && setStates(states => [...states, 'pressed'])}
      onMouseUp={() => !disabled && setStates(states => states.filter(state => state !== 'pressed'))}
    >
      <span className={clsx(
        styles.checkbox__icon,
        isChecked && styles.checkbox__icon__checked,
        !disabled && states.includes('hovered') && styles.checkbox__hovered__icon,
        !disabled && states.includes('hovered') && isChecked && styles.checkbox__hovered__icon__checked,
        !disabled && states.includes('pressed') && styles.checkbox__pressed__icon,
        !disabled && states.includes('pressed') && isChecked && styles.checkbox__pressed__icon__checked,
        disabled && styles.checkbox__disabled__icon,
        disabled && isChecked && styles.checkbox__disabled__icon__checked,
      )}>{isChecked ? <CheckMarkIcon /> : null}</span>

      {label ? (
        <label className={clsx(
          styles.checkbox__label,
          disabled && styles.checkbox__disabled__label
        )}
          dangerouslySetInnerHTML={{ __html: label }}
        />
      ) : null}
    </div>
  );
};

export default Checkbox;