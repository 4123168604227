import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import {
  SignIn,
  SignUp,
  SignUpInvitation,
  AuthSuccess,
  ForgotPassword,
  Page404,
  Dashboard,
  YourProfile,
  Camps,
  CampAdd,
  CampEdit,
  CampStats,
  Campaigns,
  CampaignEdit,
  AmbassadorSetupLandingPreview,
  AmbassadorSetupDashboardPreview,
  AmbassadorSetupEmailPreview,
  AmbassadorSetupPublic,
  ConfirmEmail,
  ProspectSetupPreview,
  ProspectSetupPublic,
  ProspectSetupEmailPreview,
  Ambassadors,
  Prospects,
  AmbassadorEdit,
  ResetPassword,
  Help,
} from 'pages'
import { AuthRoute, NonAuthRoute } from 'components'
import { PATH } from 'utils/constants'
import ProspectEdit from 'pages/Prospects/Edit/ProspectEdit'

const router = createBrowserRouter([
  {
    path: PATH.HOME,
    element: (
      <AuthRoute>
        <Dashboard />
      </AuthRoute>
    ),
  },
  {
    path: PATH.AUTH__YOUR_PROFILE,
    element: (
      <AuthRoute>
        <YourProfile />
      </AuthRoute>
    ),
  },
  {
    path: PATH.DASHBOARD,
    element: (
      <AuthRoute>
        <Dashboard />
      </AuthRoute>
    ),
  },
  {
    path: PATH.CAMPS,
    element: (
      <AuthRoute>
        <Camps />
      </AuthRoute>
    ),
  },
  {
    path: PATH.CAMPS__ADD,
    element: (
      <AuthRoute>
        <CampAdd />
      </AuthRoute>
    ),
  },
  {
    path: `${PATH.CAMPS__EDIT}/:id`,
    element: (
      <AuthRoute>
        <CampEdit />
      </AuthRoute>
    ),
  },
  {
    path: PATH.CAMPS__STATS,
    element: (
      <AuthRoute>
        <CampStats />
      </AuthRoute>
    ),
  },
  {
    path: PATH.CAMPAIGNS,
    element: (
      <AuthRoute>
        <Campaigns />
      </AuthRoute>
    ),
  },
  {
    path: `${PATH.CAMPAIGNS__EDIT}/:id`,
    element: (
      <AuthRoute bgColor="#F8F8F8">
        <CampaignEdit />
      </AuthRoute>
    ),
  },
  {
    path: `${PATH.CAMPAIGNS__EDIT}/performance/:id`,
    element: (
      <AuthRoute bgColor="#F8F8F8">
        <CampaignEdit />
      </AuthRoute>
    ),
  },
  {
    path: `${PATH.CAMPAIGNS__EDIT}/verification/:id`,
    element: (
      <AuthRoute bgColor="#F8F8F8">
        <CampaignEdit />
      </AuthRoute>
    ),
  },
  {
    path: `${PATH.CAMPAIGNS__EDIT}/define/:id`,
    element: (
      <AuthRoute bgColor="#F8F8F8">
        <CampaignEdit />
      </AuthRoute>
    ),
  },
  {
    path: `${PATH.CAMPAIGNS__EDIT}/ambassador/:id`,
    element: (
      <AuthRoute bgColor="#F8F8F8">
        <CampaignEdit />
      </AuthRoute>
    ),
  },
  {
    path: `${PATH.CAMPAIGNS__EDIT}/prospect/:id`,
    element: (
      <AuthRoute bgColor="#F8F8F8">
        <CampaignEdit />
      </AuthRoute>
    ),
  },
  {
    path: `${PATH.CAMPAIGNS__EDIT}/launch/:id`,
    element: (
      <AuthRoute bgColor="#F8F8F8">
        <CampaignEdit />
      </AuthRoute>
    ),
  },
  {
    path: PATH.PREVIEW__AS__EMAIL,
    element: (
      <AuthRoute noLayout>
        <AmbassadorSetupEmailPreview />
      </AuthRoute>
    ),
  },
  {
    path: `${PATH.PREVIEW__AS__LANDING}/:uniqueLink`,
    element: (
      <AuthRoute noLayout>
        <AmbassadorSetupLandingPreview />
      </AuthRoute>
    ),
  },
  {
    path: `${PATH.PREVIEW__AS__DASHBOARD}/:uniqueLink`,
    element: (
      <AuthRoute noLayout>
        <AmbassadorSetupDashboardPreview />
      </AuthRoute>
    ),
  },
  {
    path: PATH.PREVIEW__PS__EMAIL,
    element: (
      <AuthRoute noLayout>
        <ProspectSetupEmailPreview />
      </AuthRoute>
    ),
  },
  {
    path: `${PATH.PREVIEW__PS}/:uniqueLink`,
    element: (
      <AuthRoute noLayout>
        <ProspectSetupPreview />
      </AuthRoute>
    ),
  },
  {
    path: PATH.AMBASSADORS,
    element: (
      <AuthRoute>
        <Ambassadors />
      </AuthRoute>
    ),
  },
  {
    path: `${PATH.AMBASSADORS__EDIT}/:id`,
    element: (
      <AuthRoute>
        <AmbassadorEdit />
      </AuthRoute>
    ),
  },
  {
    path: PATH.PROSPECTS,
    element: (
      <AuthRoute>
        <Prospects />
      </AuthRoute>
    ),
  },
  {
    path: `${PATH.PROSPECTS__EDIT}/:id`,
    element: (
      <AuthRoute>
        <ProspectEdit />
      </AuthRoute>
    ),
  },
  {
    path: PATH.HELP,
    element: (
      <AuthRoute>
        <Help />{' '}
      </AuthRoute>
    ),
  },
  {
    path: PATH.AUTH__SIGN_IN,
    element: (
      <NonAuthRoute>
        <SignIn />
      </NonAuthRoute>
    ),
  },
  {
    path: `${PATH.AUTH__SIGN_UP}/:id`,
    element: (
      <NonAuthRoute>
        <SignUpInvitation />
      </NonAuthRoute>
    ),
  },
  {
    path: `${PATH.AUTH__VERIFY}/:id`,
    element: (
      <NonAuthRoute>
        <SignUpInvitation />
      </NonAuthRoute>
    ),
  },
  {
    path: PATH.AUTH__SIGN_UP,
    element: (
      <NonAuthRoute>
        <SignUp />
      </NonAuthRoute>
    ),
  },
  {
    path: PATH.AUTH__SIGN_UP__SUCCESS,
    element: (
      <NonAuthRoute>
        <AuthSuccess />
      </NonAuthRoute>
    ),
  },
  {
    path: PATH.AUTH__FORGOT__PASSWORD,
    element: (
      <NonAuthRoute>
        <ForgotPassword />
      </NonAuthRoute>
    ),
  },
  {
    path: PATH.AUTH__FORGOT__PASSWORD__SUCCESS,
    element: (
      <NonAuthRoute>
        <AuthSuccess />
      </NonAuthRoute>
    ),
  },
  {
    path: PATH.AUTH__RESET__PASSWORD,
    element: (
      <NonAuthRoute>
        <ResetPassword />
      </NonAuthRoute>
    ),
  },
  {
    path: PATH.AUTH__RESET__PASSWORD_SUCCESS,
    element: (
      <NonAuthRoute>
        <AuthSuccess />
      </NonAuthRoute>
    ),
  },
  {
    path: `${PATH.PUBLIC__AS}/:type/:uniqueLink`,
    element: <AmbassadorSetupPublic />,
  },
  {
    path: `${PATH.PUBLIC__CE}/:token`,
    element: <ConfirmEmail />,
  },
  {
    path: `${PATH.PUBLIC__PS}/:uniqueLink`,
    element: <ProspectSetupPublic />,
  },
  {
    path: PATH.NOT_FOUND,
    element: <Page404 />,
  },
])
export default router
