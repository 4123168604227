import {
  getAxiosInstance,
  saveAuthToken,
  handleError,
  setStorageItem,
} from 'utils'
import _ from 'lodash'
import { ACTION, ERR_CODE, STORAGE_NAME } from 'utils/constants'

export const userSignOut = (dispatch) => () =>
  dispatch({ type: ACTION.AUTH__SIGN_OUT })

export const userSignIn =
  (dispatch) =>
  async (args, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      let response = {}

      if (!args) response = await getAxiosInstance().get('/api/user/profile')
      else response = await getAxiosInstance().post('/api/user/sign-in', args)

      const { success, code, message, user } = response.data

      if (success) {
        if (user.authToken && args?.isRemember) saveAuthToken(user.authToken)

        if (user['Camps'].length === 0) {
          // Keeps Dashboard (which flashes up for some reason) from trying to load a camp this user can't access
          // TODO - Why does Dashboard flash up as soon as we dispatch the sign in?
          console.log('No camps found for this user')
          setStorageItem(STORAGE_NAME.CAMP_IN_USE, null)
        }
        dispatch({
          type: ACTION.AUTH__SIGN_IN,
          payload: { user: _.omit(user, 'Camps') },
        })
        dispatch({
          type: ACTION.CAMP__GET_ALL,
          payload: { camps: user['Camps'] },
        })

        callback(user['Camps'])
      } else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      const { code, message } = JSON.stringify(error.message)

      handleError(
        dispatch,
        new Error(
          JSON.stringify({
            code: code || ERR_CODE.AUTH_TOKEN,
            message: message || error.message,
          })
        )
      )
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const userSignUp =
  (dispatch) =>
  async (params, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().post(
        '/api/user/sign-up',
        params
      )

      const { success, code, message } = response.data

      if (success) {
        callback(message, code)
      } else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const userSignUpInvitation =
  (dispatch) =>
  async (params, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().post(
        '/api/user/sign-up-invitation',
        params
      )

      const { success, code, message } = response.data

      if (success) {
        // Signup is no longer automatic login
        // saveAuthToken(user.authToken);

        // dispatch({ type: ACTION.AUTH__SIGN_IN, payload: { user } });

        callback()
      } else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const updateUserProfile =
  (dispatch) =>
  async ({ name, email, phone }, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().post(
        '/api/user/update-profile',
        { name, email, phone }
      )

      const { success, code, message, user } = response.data

      if (success) {
        dispatch({ type: ACTION.AUTH__UPDATE_PROFILE, payload: { user } })

        callback()
      } else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const updatePassword =
  (dispatch) =>
  async ({ oldPassword, newPassword }, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })
      const response = await getAxiosInstance().post(
        '/api/user/update-password',
        { oldPassword, newPassword }
      )
      const { success, code, message, user } = response.data

      if (success) {
        saveAuthToken(user.authToken)
        callback({ success, code, message, user })
      }
      callback({ success, code, message, user })
    } catch (error) {
      const { code, message } = JSON.stringify(error.message)

      handleError(
        dispatch,
        new Error(
          JSON.stringify({
            code: code || ERR_CODE.AUTH_TOKEN,
            message: message || error.message,
          })
        )
      )
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const resetPassword =
  (dispatch) =>
  async ({ user, newPassword }, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })
      const response = await getAxiosInstance().post(
        '/api/user/reset-password',
        { user, newPassword }
      )

      const { success, code, message } = response.data

      if (success) callback()
      else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const forgotPassword =
  (dispatch) =>
  async (email, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().post(
        '/api/user/forgot-password',
        { email }
      )

      const { success, code, message } = response.data

      if (success) callback()
      else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }
