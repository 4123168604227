import React from 'react'
import styles from './Card.module.scss'
import clsx from 'clsx'

export const transitionTimeout = 200

const Card = ({ children, cardType, ...other }) => {
  return (
    <div
      {...other}
      className={clsx([
        styles.card, 
        cardType==='orange-circle' && styles['card--orange-circle'],
        cardType==='green-circle' && styles['card--green-circle']
      ])}
    >
      {children}
    </div>
  )
}

export default Card
