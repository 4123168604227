import { toast } from 'react-toastify'

export const oneMbSizeLimit = (file) => {
  let fileSize = file.size / (1024 * 1024)
  if (fileSize < 1) return true
  else {
    toast.error(ToastErrorMessage)
    return false
  }
}

const ToastErrorMessage = () => (
  <div>
    Your image is too large. Image sizes are limited to 1 MB. It's best for
    images to be 250 KB or less, try resizing your image at{' '}
    <a href="https://tinypng.com" target="_blank">
      https://tinypng.com/
    </a>
  </div>
)
