import React, { useState, useEffect } from 'react'
import styles from './EmailTemplateEditor.module.scss'
import { Modal, ExcludedIcon, PrimaryButton, SecondaryButton } from 'components'
import { EmailTemplateSection } from 'sections/Campaigns/AmbassadorSetup'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { getFormikCaptionTextData } from 'utils'
import { useStateValue } from 'state'
import { PATH } from 'utils/constants'

const makeFullTag = (tag) => {
  try {
    // tags that end in '-image' or '-link' need 3 curly brackets
    const type = tag.split('-')?.pop()
    if (type === 'image' || type === 'link') {
      return `{{{${tag}}}}`
    } else {
      return `{{${tag}}}`
    }
  } catch (error) {
    return false
  }
}

const Handlebars = require('handlebars')

const EmailTemplateEditor = ({
  campaignId,
  type,
  setupType = 'AS',
  title = 'Email Template Editor',
  data: initData = {},
  isLocked = false,
  optionalTags = [],
  requiredTags = [],
  templateVars = [],
  show,
  onClose = () => null,
}) => {
  const [isPreview, setPreview] = useState(false)
  const {
    action: { updateEmailTemplate },
  } = useStateValue()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      subject: '',
      heading: '',
      subheading: '',
      content: '',
      ...initData,
    },
    validationSchema: Yup.object({
      subject: Yup.string().required('Email subject is required.'),
      heading: Yup.string().required('Email heading is required.'),
      subheading: Yup.string().required('Email subheading is required.'),
      content: Yup.string()
        .required('Email content is required.')
        .test(
          'required-merge-tags',
          'missing a required merge tag',
          (value, _) => {
            if (value) {
              let result = true
              requiredTags &&
                requiredTags.forEach((tag) => {
                  if (value.indexOf(makeFullTag(tag)) === -1) result = false
                })
              return result
            }
          }
        )
        .test(
          'invalid-tags',
          'One of the merge tags is invalid',
          (value, _) => {
            if (value) {
              let template = Handlebars.compile(value)
              try {
                template({})
                return true
              } catch (e) {
                return false
              }
            }
          }
        ),
    }),
    onSubmit: ({ subject, heading, subheading, content: text }) =>
      updateEmailTemplate(
        {
          campaignId,
          messageType: type,
          templateType: 'content',
          fileType: 'html',
          data: { subject, heading, subheading, text },
        },
        handleClose
      ),
  })

  useEffect(() => {
    formik.validateForm()
    // eslint-disable-next-line
  }, [title])

  useEffect(() => {
    if (!isPreview) return

    if (
      (formik.touched.heading && formik.errors.heading) ||
      !formik.values.heading
    ) {
      setPreview(false)
      return
    }
    if (
      (formik.touched.subheading && formik.errors.subheading) ||
      !formik.values.subheading
    ) {
      setPreview(false)
      return
    }
    if (
      (formik.touched.content && formik.errors.content) ||
      !formik.values.content
    ) {
      setPreview(false)
      return
    }

    updateEmailTemplate(
      {
        campaignId,
        messageType: type,
        templateType: 'content',
        fileType: 'html',
        data: {
          subject: formik.values.subject,
          heading: formik.values.heading,
          subheading: formik.values.subheading,
          text: formik.values.content,
        },
      },
      () => {
        setPreview(false)

        if (['AS', 'PS'].includes(setupType))
          window
            .open(
              `${
                setupType === 'AS'
                  ? PATH.PREVIEW__AS__EMAIL
                  : PATH.PREVIEW__PS__EMAIL
              }?campaignId=${campaignId}&messageType=${type}`,
              '_blank'
            )
            .focus()
      }
    )

    // eslint-disable-next-line
  }, [isPreview])

  const handleClose = (e) => {
    if (typeof e?.preventDefault === 'function') e?.preventDefault()
    onClose()
    formik.resetForm()
  }

  const handleClickPreview = () => {
    formik.setFieldTouched('heading', true)
    formik.setFieldTouched('subheading', true)
    formik.setFieldTouched('content', true)

    setPreview(true)
  }

  return (
    <Modal
      classNames={{ modal: styles.modal, content: styles.modal__content }}
      show={show}
      onClose={onClose}
    >
      <h6
        className={styles.title}
        dangerouslySetInnerHTML={{ __html: title }}
      />

      <button type="button" className={styles.close} onClick={handleClose}>
        <ExcludedIcon />
      </button>

      <EmailTemplateSection
        subject={{
          id: 'subject',
          value: formik.values.subject,
          ...getFormikCaptionTextData(formik, 'subject'),
        }}
        heading={{
          id: 'heading',
          value: formik.values.heading,
          ...getFormikCaptionTextData(formik, 'heading'),
        }}
        subheading={{
          id: 'subheading',
          value: formik.values.subheading,
          ...getFormikCaptionTextData(formik, 'subheading'),
        }}
        content={{
          id: 'content',
          value: formik.values.content,
          ...getFormikCaptionTextData(formik, 'content'),
        }}
        isLocked={isLocked}
        onChange={(id, value) => formik.setFieldValue(id, value)}
        onPreview={handleClickPreview}
        optionalTags={optionalTags}
        requiredTags={requiredTags}
        templateVars={templateVars}
      />

      <div className={styles.buttons}>
        <SecondaryButton
          className={styles.button__cancel}
          label="Cancel"
          onClick={handleClose}
        />

        <PrimaryButton
          className={styles.button__save}
          label="Save"
          onClick={formik.handleSubmit}
        />
      </div>
    </Modal>
  )
}

export default EmailTemplateEditor
