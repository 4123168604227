import React, { useState } from 'react'
import clsx from 'clsx'
import styles from './PrimaryButton.module.scss'

const PrimaryButton = React.forwardRef(
  (
    {
      className,
      label = null,
      children = null,
      disabled = false,
      description = null,
      onClick: handleClickButton = () => null,
      type = 'button',
    },
    ref
  ) => {
    const [states, setStates] = useState([])

    const handleClick = (e) => {
      if (disabled) return false

      handleClickButton(e)
    }

    if (description)
      return (
        <div>
          <button
            ref={ref}
            type={type}
            className={clsx(
              className,
              styles.primary_button,
              !disabled &&
                states.includes('hovered') &&
                styles.primary_button__hovered,
              !disabled &&
                states.includes('pressed') &&
                styles.primary_button__pressed,
              disabled && styles.primary_button__disabled
            )}
            onClick={handleClick}
            onMouseEnter={() =>
              !disabled && setStates((states) => [...states, 'hovered'])
            }
            onMouseLeave={() => !disabled && setStates([])}
            onMouseDown={() =>
              !disabled && setStates((states) => [...states, 'pressed'])
            }
            onMouseUp={() =>
              !disabled &&
              setStates((states) =>
                states.filter((state) => state !== 'pressed')
              )
            }
          >
            {label || children || 'Primary Button'}
          </button>
          {description ? (
            <p className={styles.primary_button__description}>{description}</p>
          ) : null}
        </div>
      )
    else
      return (
        <button
          ref={ref}
          type={type}
          className={clsx(
            className,
            styles.primary_button,
            !disabled &&
              states.includes('hovered') &&
              styles.primary_button__hovered,
            !disabled &&
              states.includes('pressed') &&
              styles.primary_button__pressed,
            disabled && styles.primary_button__disabled
          )}
          onClick={handleClick}
          onMouseEnter={() =>
            !disabled && setStates((states) => [...states, 'hovered'])
          }
          onMouseLeave={() => !disabled && setStates([])}
          onMouseDown={() =>
            !disabled && setStates((states) => [...states, 'pressed'])
          }
          onMouseUp={() =>
            !disabled &&
            setStates((states) => states.filter((state) => state !== 'pressed'))
          }
        >
          {label || children || 'Primary Button'}
        </button>
      )
  }
)

export default PrimaryButton
