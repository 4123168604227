import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import styles from './SearchInput.module.scss';
import { MagnifierIcon } from 'components';

const SearchInput = ({
  classNames = { container: '', label: '', input: '' },
  label = null, placeholder = '', value: initValue = '', captionText = null,
  requiredShown = false, optionalShown = false,
  isError = false, readOnly = false,
  onChange: handleChangeValue = () => null
}) => {
  const [value, setValue] = useState('');

  useEffect(() => setValue(initValue || ''), [initValue]);

  const handleChange = e => {
    setValue(e.target.value);
    handleChangeValue(e.target.value);
  };

  return (
    <div className={clsx(classNames.container, styles.text_input__wrapper)}>
      {label ? (
        <label className={clsx(classNames.label, styles.text_input__label)}>
          {label === ' ' ? <>&nbsp;</> : label}
          {requiredShown ? <span className={styles.text_input__required}>&nbsp;*</span> : null}
          {optionalShown ? <span className={styles.text_input__optional}>&nbsp;(optional)</span> : null}
        </label>
      ) : null}

      <MagnifierIcon className={styles.text_input__icon} />

      <input
        className={clsx(
          classNames.input,
          styles.text_input,
          isError && styles[`text_input--error`]
        )}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        readOnly={readOnly}
      />

      <p className={clsx(styles.caption, isError && styles.caption__error)}>{captionText ? <span>{captionText}</span> : null}</p>
    </div>
  );
};

export default SearchInput;