import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  LogoIcon,
  TextInput,
  PasswordInput,
  Checkbox,
  PrimaryButton,
  Illustration1,
} from 'components'
import styles from './SignIn.module.scss'
import { useStateValue } from 'state'
import { useNavigate } from 'hooks'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ERR_CODE, STORAGE_NAME, PATH } from 'utils/constants'
import { setStorageItem } from 'utils/storage'
import { isAuthError } from 'utils'

const SignIn = () => {
  const navigate = useNavigate()
  const {
    state: { deviceSize, error },
    action: { userSignIn },
  } = useStateValue()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      isRemember: true,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required('Please enter your email.')
        .email('Please enter a valid email.'),
      password: Yup.string()
        .required('Please enter your password.')
        .min(
          8,
          ({ min }) => `Please enter a password longer than ${min} characters.`
        ),
    }),
    onSubmit: ({ email, password, isRemember }) =>
      userSignIn({ email, password, isRemember }, (camps) => {
        if (camps.length > 1) {
          navigate({ pathname: PATH.CAMPS })
        } else if (camps.length === 1) {
          setStorageItem(STORAGE_NAME.CAMP_IN_USE, camps[0].id)
          navigate({ pathname: PATH.DASHBOARD })
        } else {
          navigate({ pathname: PATH.CAMPS__ADD, state: { firstCamp: true } })
        }
      }),
  })

  useEffect(() => {
    if (!error || !isAuthError(error)) return

    switch (error.code) {
      case ERR_CODE.AUTH_NOT_FOUND:
        formik.setFieldError('email', error.message)
        break

      case ERR_CODE.AUTH_PASSWORD:
        formik.setFieldError('password', error.message)
        break

      default:
        formik.setFieldError('email', 'Login failed.')
        formik.setFieldError('password', 'Login failed.')
        break
    }

    // eslint-disable-next-line
  }, [error])

  return (
    <div className={styles.page__container}>
      <div className={styles.page__wrapper}>
        <div className={styles.page}>
          <Link className={styles.logo} to="/">
            <LogoIcon />
          </Link>
          {['xs', 'sm', 'md'].includes(deviceSize) ? (
            <h4 className={styles.description}>
              Welcome to Camp Tree! <br /> Please sign in.
            </h4>
          ) : (
            <h5 className={styles.description}>
              Welcome to Camp Tree! <br /> Please sign in.
            </h5>
          )}

          <form>
            <TextInput
              label="Login"
              value={formik.values.email}
              onChange={(email) =>
                formik.setFieldValue('email', email.toLowerCase())
              }
              {...(formik.touched.email && formik.errors.email
                ? { isError: true, captionText: formik.errors.email }
                : {})}
            />

            <PasswordInput
              classNames={{ container: styles.input__password }}
              label="Password"
              value={formik.values.password}
              onChange={(password) =>
                formik.setFieldValue('password', password)
              }
              {...(formik.touched.password && formik.errors.password
                ? { isError: true, captionText: formik.errors.password }
                : {})}
            />

            <div className={styles.options}>
              <Checkbox
                label="Remember me"
                isChecked={formik.values.isRemember}
                onChange={(isRemember) =>
                  formik.setFieldValue('isRemember', isRemember)
                }
              />

              <Link
                className={styles.forgot_password}
                to="/forgot-password"
                dangerouslySetInnerHTML={{ __html: 'Forgot password?' }}
              />
            </div>

            <div className={styles.buttons__container}>
              <PrimaryButton
                className={styles.button__login}
                label="LOGIN"
                type="submit"
                onClick={formik.handleSubmit}
              />
            </div>
            <div className={styles.options_signup}>
              <Link
                className={styles.signup_link}
                to="/sign-up"
                dangerouslySetInnerHTML={{
                  __html: `Don't have an account? Click HERE`,
                }}
              />
            </div>
          </form>
        </div>

        {deviceSize === 'xxl' ? (
          <Illustration1 className={styles.page__illustration} />
        ) : null}
      </div>
    </div>
  )
}

export default SignIn
