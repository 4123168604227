import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStateValue } from 'state';
import Landing from '../Home';

const Preview = () => {
  const [prospectSetup, setProspectSetup] = useState(null);
  const [camp, setCamp] = useState(null);
  const { action: { getProspectSetupData } } = useStateValue();
  const { uniqueLink } = useParams();
  const [person, setPerson] = useState({});
  const [promocode, setPromocode] = useState('');

  useEffect(() => getProspectSetupData(uniqueLink, ({ ambassador: {
    Campaign: { ProspectSetup, Camp, ProspectRewards },
    Person: { firstName, lastName, email }
  }}) => {
    setPromocode(ProspectRewards[0].promocode);
    setProspectSetup(ProspectSetup);
    setCamp(Camp);
    setPerson({ firstName, lastName, email });
    // eslint-disable-next-line
  }), [uniqueLink]);

  if (prospectSetup && camp) return <Landing {...prospectSetup} promocode={promocode} camp={camp} person={person} />

  return <React.Fragment />;
};

export default Preview;
