import React, { useRef, useEffect, useState } from 'react'
import styles from './ProspectSetup.module.scss'
import clsx from 'clsx'
import { useFormik } from 'formik'
import {
  AccordionSection,
  ImagePlaceholderFillIcon,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  TextArea,
  TextInput,
  Checkbox,
  PencilIcon,
  EyeVisibleColorIcon,
  EmailTemplateEditorModal,
  PSPromoCodeModal,
  LoadingOverlay,
  PSLearnMoreModal,
} from 'components'
import * as Yup from 'yup'
import { CAMPAIGN_STEP, PATH, MESSAGE, ERR_CODE } from 'utils/constants'
import { useStateValue } from 'state'
import { toast } from 'react-toastify'
import _ from 'lodash'

const ProspectSetup = ({
  isLocked = false,
  step: initStep,
  onBack = () => null,
  onNext = () => null,
  onSave = () => null,
  data: initData = {},
  campaignId = null,
  campaignSlug = '',
  promocode = '',
  setDirty = () => null,
  saveAndClose = () => null,
}) => {
  const invitationsRef = useRef(null)
  const emailsRef = useRef(null)
  const {
    state: {
      camp: { activeCamp },
    },
    action: { updateProspectSetup, getEmailTemplate, popupError },
  } = useStateValue()
  const [isSubmitted, setSubmitted] = useState(false)
  const [isNext, setNext] = useState(false)
  const [isStayHere, setIsStayHere] = useState(false)
  const [isEmailEditorOpened, setEmailEditorOpened] = useState(false)
  const [emailTemplateToEdit, setEmailTemplateToEdit] = useState({})
  const [isLandingPagePreview, setLandingPagePreview] = useState(false)
  const [showPromoCode, setShowPromoCode] = useState(false)
  const [showLearnMore, setShowLearnMore] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [, setSectionOpened] = useState('invitations')
  const [isDirty, setIsDirty] = useState(false)

  useEffect(() => {
    setDirty(isDirty)
  }, [isDirty])

  const showPopup = (url) => {
    let opened = window.open(url, '_blank')
    if (opened) {
      opened.focus()
      return
    }

    popupError({
      message: JSON.stringify({
        code: ERR_CODE.POPUP_BLOCKED,
        message:
          'Please allow popups. This preview will not work without popups.',
      }),
    })
  }

  function isWorkingUrl(url) {
    return new Promise((res, rej) => {
      fetch(url, { mode: 'no-cors' })
        .then((r) => {
          res(r)
        })
        .catch((err) => {
          rej(err)
        })
    })
  }

  const isValidUrl = (url) => {
    try {
      new URL(url)
    } catch (e) {
      return false
    }
    return true
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      landingBgImgURL: null,
      landingHeading: '',
      landingSubheading: '',
      linkToWebsite: '',
      linkToSchedule: '',
      linkToVideo: '',
      linkToPricingInfo: '',
      learnMoreBtnTxt: '',
      link1: '',
      link2: '',
      link3: '',
      link4: '',
      learnMoreSubheading: '',
      learnMorePopupHeading: '',
      learnMorePopupBtnTxt: '',
      // enrollHeading: '',
      enrollSubheading: '',
      enrollBtnTxt: '',
      linkToRegistrationPage: '',
      enrollPopupHeading: '',
      enrollPopupBtnTxt: '',
      enrollInstructions: '',
      ...initData,
    },
    validationSchema: Yup.object({
      landingBgImgURL: Yup.mixed().required(
        'Landing page background image is required.'
      ),
      landingHeading: Yup.string()
        .required('Landing page title is required.')
        .max(70, 'Max length is 70.'),
      landingSubheading: Yup.string().max(250, 'Max length is 250.'),
      linkToSchedule: Yup.string().test(
        'linkToSchedule',
        'URL is not valid',
        (value) =>
          value === undefined || value.length === 0 ? true : isValidUrl(value)
      ),
      // .test('linkToSchedule', 'URL is not valid', (value) =>
      //   isWorkingUrl(value)
      //     .then((res) => {
      //       return true
      //     })
      //     .catch((err) => {
      //       return false
      //     })
      // ),
      linkToPricingInfo: Yup.string().test(
        'linkToPricingInfo',
        'URL is not valid',
        (value) =>
          value === undefined || value.length === 0 ? true : isValidUrl(value)
      ),
      // .test('linkToPricingInfo', 'URL is not valid', (value) =>
      //   isWorkingUrl(value)
      //     .then((res) => {
      //       return true
      //     })
      //     .catch((err) => {
      //       return false
      //     })
      // ),
      linkToWebsite: Yup.string()
        .required('Web link is required.')
        .test('linkToWebsite', 'URL is not valid', (value) =>
          isValidUrl(value)
        ),
      // .test('linkToWebsite', 'URL is not valid', (value) =>
      //   isWorkingUrl(value)
      //     .then((res) => {
      //       return true
      //     })
      //     .catch((err) => {
      //       return false
      //     })
      // ),
      linkToVideo: Yup.string()
        .required('Web link is required.')
        .test('linkToVideo', 'URL is not valid', (value) => isValidUrl(value)),
      // .test('linkToVideo', 'URL is not valid', (value) =>
      //   isWorkingUrl(value)
      //     .then((res) => {
      //       return true
      //     })
      //     .catch((err) => {
      //       return false
      //     })
      // ),
      link1: Yup.string()
        .required('Link 1 is required.')
        .max(36, 'Max length is 36.'),
      link2: Yup.string()
        .required('Link 2 is required.')
        .max(36, 'Max length is 36.'),
      link3: Yup.string().max(36, 'Max length is 36.'),
      link4: Yup.string().max(36, 'Max length is 36.'),
      learnMoreBtnTxt: Yup.string()
        .required('Button text is required.')
        .max(36, 'Max length is 36.'),
      learnMoreSubheading: Yup.string()
        .required('Sub-heading is required.')
        .max(120, 'Max length is 120.'),
      learnMorePopupHeading: Yup.string()
        .required('Heading is required.')
        .max(120, 'Max length is 120.'),
      learnMorePopupBtnTxt: Yup.string()
        .required('Button text is required.')
        .max(55, 'Max length is 55.'),
      enrollPopupHeading: Yup.string()
        .required('Heading is required.')
        .max(65, 'Max length is 65.'),
      // enrollHeading: Yup.string()
      //   .required('Sub-heading is required.')
      //   .max(65, 'Max length is 65.'),
      enrollSubheading: Yup.string().max(120, 'Max length is 120.'),
      enrollBtnTxt: Yup.string()
        .required('Button text is required.')
        .max(36, 'Max length is 36.'),
      linkToRegistrationPage: Yup.string()
        .required('Enrollment URL is required.')
        .test('linkToRegistrationPage', 'URL is not valid', (value) =>
          isValidUrl(value)
        ),
      // .test('linkToRegistrationPage', 'URL is not valid', (value) =>
      //   isWorkingUrl(value)
      //     .then((res) => {
      //       return true
      //     })
      //     .catch((err) => {
      //       return false
      //     })
      // ),
      enrollPopupBtnTxt: Yup.string()
        .required('Button text is required.')
        .max(25, 'Max length is 25.'),
      enrollInstructions: Yup.string()
        .required('Enroll Text is required.')
        .max(120, 'Max length is 120.'),
    }),
    onSubmit: (args) => {
      // if (isLocked)
      //   return isNext ? onNext() : navigate({ pathname: PATH.CAMPAIGNS })

      setLoading(true)

      if (isDirty)
        updateProspectSetup({ ...args, campaignId }, (prospectSetup) => {
          setLoading(false)

          if (isNext) onNext(prospectSetup)
          else if (isStayHere) onSave(prospectSetup)
          else saveAndClose()
        })
      else {
        setLoading(false)
        if (isNext) onNext(initData)
        else if (isStayHere) onSave(initData)
        else saveAndClose()
      }
    },
  })

  useEffect(() => {
    setIsDirty(
      !_.isEqual(
        _.omit(formik.values, [
          'created_at',
          'id',
          'templateVars',
          'updated_at',
        ]),
        _.omit(initData, ['created_at', 'id', 'templateVars', 'updated_at'])
      )
    )
  }, [formik])

  useEffect(() => {
    if (initStep === CAMPAIGN_STEP.PROSPECT_SETUP__EMAILS)
      emailsRef.current.scrollIntoView({ behavior: 'smooth' })
    else window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [initStep])

  useEffect(() => {
    if (!isSubmitted) return

    if (_.isEmpty(formik.touched) || _.isEmpty(formik.errors)) return

    const toastErrors = (errors) =>
      _.values(errors).forEach((error) => {
        if (typeof error === 'string') toast.error(error)
        else toastErrors(error)
      })

    toastErrors(formik.errors)

    setSubmitted(false)
    // eslint-disable-next-line
  }, [isSubmitted])

  useEffect(() => {
    if (!isLandingPagePreview) return

    const {
      landingBgImgURL,
      landingHeading,
      landingSubheading,
      linkToWebsite,
      linkToSchedule,
      linkToVideo,
      linkToPricingInfo,
      learnMoreBtnTxt,
      link1,
      link2,
      link3,
      link4,
      learnMoreSubheading,
      learnMorePopupHeading,
      learnMorePopupBtnTxt,
      enrollPopupHeading,
      enrollPopupBtnTxt,
      enrollSubheading,
      enrollBtnTxt,
      linkToRegistrationPage,
      enrollInstructions,
    } = formik.values

    if (
      (formik.touched.landingBgImgURL && formik.errors.landingBgImgURL) ||
      !landingBgImgURL
    ) {
      setLandingPagePreview(false)
      return
    }
    if (
      (formik.touched.landingHeading && formik.errors.landingHeading) ||
      !landingHeading
    ) {
      setLandingPagePreview(false)
      return
    }
    if (
      (formik.touched.landingSubheading && formik.errors.landingSubheading) ||
      !landingSubheading
    ) {
      setLandingPagePreview(false)
      return
    }
    if (
      (formik.touched.linkToWebsite && formik.errors.linkToWebsite) ||
      !linkToWebsite
    ) {
      setLandingPagePreview(false)
      return
    }
    if (
      (formik.touched.linkToVideo && formik.errors.linkToVideo) ||
      !linkToVideo
    ) {
      setLandingPagePreview(false)
      return
    }
    if (
      (formik.touched.learnMoreBtnTxt && formik.errors.learnMoreBtnTxt) ||
      !learnMoreBtnTxt
    ) {
      setLandingPagePreview(false)
      return
    }
    // if (
    //   (formik.touched.enrollHeading && formik.errors.enrollHeading) ||
    //   !enrollHeading
    // )
    //   return
    if (
      (formik.touched.enrollSubheading && formik.errors.enrollSubheading) ||
      !enrollSubheading
    ) {
      setLandingPagePreview(false)
      return
    }
    if (
      (formik.touched.enrollBtnTxt && formik.errors.enrollBtnTxt) ||
      !enrollBtnTxt
    ) {
      setLandingPagePreview(false)
      return
    }
    if (
      (formik.touched.linkToRegistrationPage &&
        formik.errors.linkToRegistrationPage) ||
      !linkToRegistrationPage
    ) {
      setLandingPagePreview(false)
      return
    }
    if (
      (formik.touched.enrollInstructions && formik.errors.enrollInstructions) ||
      !enrollInstructions
    ) {
      setLandingPagePreview(false)
      return
    }
    if ((formik.touched.link1 && formik.errors.link1) || !link1) {
      setLandingPagePreview(false)
      return
    }
    if ((formik.touched.link2 && formik.errors.link2) || !link2) {
      setLandingPagePreview(false)
      return
    }
    if (
      (formik.touched.learnMoreSubheading &&
        formik.errors.learnMoreSubheading) ||
      !learnMoreSubheading
    ) {
      setLandingPagePreview(false)
      return
    }
    if (
      (formik.touched.learnMorePopupHeading &&
        formik.errors.learnMorePopupHeading) ||
      !learnMorePopupHeading
    ) {
      setLandingPagePreview(false)
      return
    }
    if (
      (formik.touched.learnMorePopupBtnTxt &&
        formik.errors.learnMorePopupBtnTxt) ||
      !learnMorePopupBtnTxt
    ) {
      setLandingPagePreview(false)
      return
    }
    if (
      (formik.touched.enrollPopupHeading && formik.errors.enrollPopupHeading) ||
      !enrollPopupHeading
    ) {
      setLandingPagePreview(false)
      return
    }
    if (
      (formik.touched.enrollPopupBtnTxt && formik.errors.enrollPopupBtnTxt) ||
      !enrollPopupBtnTxt
    ) {
      setLandingPagePreview(false)
      return
    }

    updateProspectSetup(
      {
        id: formik.values.id,
        campaignId,
        landingBgImgURL,
        landingHeading,
        landingSubheading,
        linkToWebsite,
        linkToSchedule,
        linkToVideo,
        linkToPricingInfo,
        learnMoreBtnTxt,
        link1,
        link2,
        link3,
        link4,
        learnMoreSubheading,
        learnMorePopupHeading,
        learnMorePopupBtnTxt,
        enrollPopupHeading,
        enrollPopupBtnTxt,
        // enrollHeading,
        enrollSubheading,
        enrollBtnTxt,
        linkToRegistrationPage,
        enrollInstructions,
      },
      () => {
        setLandingPagePreview(false)
        showPopup(`${PATH.PREVIEW__PS}/test-${campaignSlug}`)
      }
    )
    // eslint-disable-next-line
  }, [isLandingPagePreview])

  const handleClickLandingPagePreview = () => {
    formik.setFieldTouched('landingBgImgURL', true)
    formik.setFieldTouched('landingHeading', true)
    formik.setFieldTouched('landingSubheading', true)
    formik.setFieldTouched('learnMoreBtnTxt', true)
    formik.setFieldTouched('learnMoreSubheading', true)
    formik.setFieldTouched('enrollSubheading', true)
    formik.setFieldTouched('enrollBtnTxt', true)

    formik.setFieldTouched('linkToRegistrationPage', true)
    formik.setFieldTouched('enrollPopupBtnTxt', true)
    formik.setFieldTouched('enrollInstructions', true)
    formik.setFieldTouched('enrollPopupHeading', true)

    formik.setFieldTouched('learnMorePopupHeading', true)
    formik.setFieldTouched('learnMorePopupBtnTxt', true)
    formik.setFieldTouched('linkToWebsite', true)
    formik.setFieldTouched('linkToSchedule', true)
    formik.setFieldTouched('linkToVideo', true)
    formik.setFieldTouched('linkToPricingInfo', true)
    formik.setFieldTouched('link1', true)
    formik.setFieldTouched('link2', true)
    formik.setFieldTouched('link3', true)
    formik.setFieldTouched('link4', true)

    if (
      (formik.touched.landingBgImgURL && formik.errors.landingBgImgURL) ||
      !formik.values.landingBgImgURL
    )
      return
    if (
      (formik.touched.landingHeading && formik.errors.landingHeading) ||
      !formik.values.landingHeading
    )
      return
    if (
      (formik.touched.landingSubheading && formik.errors.landingSubheading) ||
      !formik.values.landingSubheading
    )
      return
    if (
      (formik.touched.learnMoreBtnTxt && formik.errors.learnMoreBtnTxt) ||
      !formik.values.learnMoreBtnTxt
    )
      return
    if (
      (formik.touched.learnMoreSubheading &&
        formik.errors.learnMoreSubheading) ||
      !formik.values.learnMoreSubheading
    )
      return
    if (
      (formik.touched.enrollSubheading && formik.errors.enrollSubheading) ||
      !formik.values.enrollSubheading
    )
      return
    if (
      (formik.touched.enrollBtnTxt && formik.errors.enrollBtnTxt) ||
      !formik.values.enrollBtnTxt
    )
      return

    // enroll popup
    if (
      (formik.touched.linkToRegistrationPage &&
        formik.errors.linkToRegistrationPage) ||
      !formik.values.linkToRegistrationPage
    )
      return
    if (
      (formik.touched.enrollPopupBtnTxt && formik.errors.enrollPopupBtnTxt) ||
      !formik.values.enrollPopupBtnTxt
    )
      return
    if (
      (formik.touched.enrollInstructions && formik.errors.enrollInstructions) ||
      !formik.values.enrollInstructions
    )
      return
    if (
      (formik.touched.enrollPopupHeading && formik.errors.enrollPopupHeading) ||
      !formik.values.enrollPopupHeading
    )
      return

    // learnmore popup
    if (
      (formik.touched.learnMorePopupHeading &&
        formik.errors.learnMorePopupHeading) ||
      !formik.values.learnMorePopupHeading
    )
      return
    if (
      (formik.touched.learnMorePopupBtnTxt &&
        formik.errors.learnMorePopupBtnTxt) ||
      !formik.values.learnMorePopupBtnTxt
    )
      return
    if (
      (formik.touched.linkToWebsite && formik.errors.linkToWebsite) ||
      !formik.values.linkToWebsite
    )
      return

    if (
      (formik.touched.linkToVideo && formik.errors.linkToVideo) ||
      !formik.values.linkToVideo
    )
      return
    if ((formik.touched.link1 && formik.errors.link1) || !formik.values.link1)
      return
    if ((formik.touched.link2 && formik.errors.link2) || !formik.values.link2)
      return

    setLandingPagePreview(true)
  }

  const handleClickPromocodePreview = () => {
    formik.setFieldTouched('linkToRegistrationPage', true)
    formik.setFieldTouched('enrollPopupBtnTxt', true)
    formik.setFieldTouched('enrollInstructions', true)
    formik.setFieldTouched('enrollPopupHeading', true)
    if (
      (formik.touched.linkToRegistrationPage &&
        formik.errors.linkToRegistrationPage) ||
      !formik.values.linkToRegistrationPage
    )
      return
    if (
      (formik.touched.enrollPopupBtnTxt && formik.errors.enrollPopupBtnTxt) ||
      !formik.values.enrollPopupBtnTxt
    )
      return
    if (
      (formik.touched.enrollInstructions && formik.errors.enrollInstructions) ||
      !formik.values.enrollInstructions
    )
      return
    if (
      (formik.touched.enrollPopupHeading && formik.errors.enrollPopupHeading) ||
      !formik.values.enrollPopupHeading
    )
      return

    setShowPromoCode(true)
  }

  const handleClickLearnMorePreview = () => {
    formik.setFieldTouched('learnMorePopupHeading', true)
    formik.setFieldTouched('learnMorePopupBtnTxt', true)
    formik.setFieldTouched('linkToWebsite', true)
    formik.setFieldTouched('linkToSchedule', true)
    formik.setFieldTouched('linkToVideo', true)
    formik.setFieldTouched('linkToPricingInfo', true)
    formik.setFieldTouched('link1', true)
    formik.setFieldTouched('link2', true)
    formik.setFieldTouched('link3', true)
    formik.setFieldTouched('link4', true)

    if (
      (formik.touched.learnMorePopupHeading &&
        formik.errors.learnMorePopupHeading) ||
      !formik.values.learnMorePopupHeading
    )
      return
    if (
      (formik.touched.learnMorePopupBtnTxt &&
        formik.errors.learnMorePopupBtnTxt) ||
      !formik.values.learnMorePopupBtnTxt
    )
      return
    if (
      (formik.touched.linkToWebsite && formik.errors.linkToWebsite) ||
      !formik.values.linkToWebsite
    )
      return

    if (
      (formik.touched.linkToVideo && formik.errors.linkToVideo) ||
      !formik.values.linkToVideo
    )
      return
    if ((formik.touched.link1 && formik.errors.link1) || !formik.values.link1)
      return
    if ((formik.touched.link2 && formik.errors.link2) || !formik.values.link2)
      return

    setShowLearnMore(true)
  }

  const handleClickSubmit = (isNext) => {
    setNext(isNext)
    setIsStayHere(false)
    setSubmitted(true)
    formik.handleSubmit()
  }

  const handleSubmitOnly = () => {
    setIsStayHere(true)
    setSubmitted(true)
    formik.handleSubmit()
  }

  const handleClickEmailEditor = (template) =>
    getEmailTemplate(
      campaignId,
      template.TYPE,
      ({ htmlContentTemplate, templateVars }) => {
        setEmailTemplateToEdit({
          title: template.LABEL,
          type: template.TYPE,
          data: {
            subject: htmlContentTemplate.subject,
            heading: htmlContentTemplate.heading,
            subheading: htmlContentTemplate.subheading,
            content: htmlContentTemplate.text,
          },
          optionalTags: htmlContentTemplate.optionalTags,
          requiredTags: htmlContentTemplate.requiredTags,
          templateVars,
        })
        setEmailEditorOpened(true)
      }
    )

  return (
    <>
      <div className={styles.section}>
        <form className={styles.form}>
          <fieldset
            ref={invitationsRef}
            className={styles.section__invitations}
          >
            <h4 className={styles.section__title}>Invitations</h4>

            <AccordionSection
              className={styles.accordion_section}
              title="Landing Page"
              description="Invite Prospect to Learn More/Enroll"
              opened={true}
              // opened={sectionOpened === 'invitations'}
              onActionToOpen={() => setSectionOpened('invitations')}
              onActionToClose={() => setSectionOpened(null)}
              contentHeightScale={2}
            >
              <div className={styles.form__row}>
                <div className={styles.field__bgImg__wrapper}>
                  <div
                    className={clsx(
                      styles.field__bgImg__preview,
                      formik.touched.landingBgImgURL &&
                        formik.errors.landingBgImgURL &&
                        styles['field__bgImg__preview--error']
                    )}
                  >
                    {formik.values.landingBgImgURL ? (
                      <img
                        src={
                          typeof formik.values.landingBgImgURL === 'string'
                            ? formik.values.landingBgImgURL
                            : URL.createObjectURL(formik.values.landingBgImgURL)
                        }
                        alt="Landing page background"
                      />
                    ) : (
                      <ImagePlaceholderFillIcon
                        className={styles.field__bgImg__placeholder}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className={styles.form__row}>
                <div className={styles.form__col}>
                  <TextArea
                    tabIndex="1"
                    rows="2"
                    label="Title"
                    disabled={isLocked}
                    placeholder="Title"
                    value={formik.values.landingHeading}
                    onChange={(value) =>
                      formik.setFieldValue('landingHeading', value)
                    }
                    {...(formik.touched.landingHeading &&
                    formik.errors.landingHeading
                      ? {
                          isError: true,
                          captionText: formik.errors.landingHeading,
                        }
                      : {
                          captionText: `${formik.values.landingHeading.length}/70`,
                        })}
                  />
                </div>

                <div className={styles.form__col}>
                  <TextArea
                    tabIndex="2"
                    rows="2"
                    label="Subtitle"
                    disabled={isLocked}
                    placeholder="Subtitle"
                    value={formik.values.landingSubheading}
                    onChange={(value) =>
                      formik.setFieldValue('landingSubheading', value)
                    }
                    {...(formik.touched.landingSubheading &&
                    formik.errors.landingSubheading
                      ? {
                          isError: true,
                          captionText: formik.errors.landingSubheading,
                        }
                      : {
                          captionText: `${formik.values.landingSubheading.length}/250`,
                        })}
                  />
                </div>
              </div>

              <div className={styles['form__row--heading']}>
                Want to Learn More?
              </div>
              <div className={styles.form__row}>
                <div className={styles.form__col}>
                  <TextInput
                    tabIndex="9"
                    rows="1"
                    label="Subheading"
                    disabled={isLocked}
                    placeholder="Subheading"
                    value={formik.values.learnMoreSubheading}
                    onChange={(value) =>
                      formik.setFieldValue('learnMoreSubheading', value)
                    }
                    {...(formik.touched.learnMoreSubheading &&
                    formik.errors.learnMoreSubheading
                      ? {
                          isError: true,
                          captionText: formik.errors.learnMoreSubheading,
                        }
                      : {
                          captionText: `${formik.values.learnMoreSubheading.length}/120`,
                        })}
                  />
                </div>

                <div className={styles.form__col}>
                  <TextInput
                    tabIndex="7"
                    label="Button Text"
                    disabled={isLocked}
                    placeholder="Claim Code"
                    value={formik.values.learnMoreBtnTxt}
                    onChange={(value) =>
                      formik.setFieldValue('learnMoreBtnTxt', value)
                    }
                    {...(formik.touched.learnMoreBtnTxt &&
                    formik.errors.learnMoreBtnTxt
                      ? {
                          isError: true,
                          captionText: formik.errors.learnMoreBtnTxt,
                        }
                      : {
                          captionText: `${formik.values.learnMoreBtnTxt.length}/36`,
                        })}
                  />
                </div>
              </div>
              <div className={styles['form__row--heading']}>
                Ready to Join Us?
              </div>
              <div className={styles.form__row}>
                <div className={styles.form__col}>
                  <TextInput
                    tabIndex="9"
                    rows="1"
                    label="Subheading"
                    disabled={isLocked}
                    placeholder="Subheading"
                    value={formik.values.enrollSubheading}
                    onChange={(value) =>
                      formik.setFieldValue('enrollSubheading', value)
                    }
                    {...(formik.touched.enrollSubheading &&
                    formik.errors.enrollSubheading
                      ? {
                          isError: true,
                          captionText: formik.errors.enrollSubheading,
                        }
                      : {
                          captionText: `${formik.values.enrollSubheading.length}/120`,
                        })}
                  />
                </div>

                <div className={styles.form__col}>
                  <TextInput
                    tabIndex="10"
                    label="Button Text"
                    disabled={isLocked}
                    placeholder="Claim Code"
                    value={formik.values.enrollBtnTxt}
                    onChange={(value) =>
                      formik.setFieldValue('enrollBtnTxt', value)
                    }
                    {...(formik.touched.enrollBtnTxt &&
                    formik.errors.enrollBtnTxt
                      ? {
                          isError: true,
                          captionText: formik.errors.enrollBtnTxt,
                        }
                      : {
                          captionText: `${formik.values.enrollBtnTxt.length}/36`,
                        })}
                  />
                </div>
              </div>

              <div className={styles.form__row}>
                <div className={styles.form__col}></div>
                <div className={styles.form__col}>
                  <div className={styles.landing__preview__container}>
                    <div className={styles.landing__preview__button__wrapper}>
                      <SecondaryButton
                        className={styles.landing__preview__button}
                        label="Preview"
                        onClick={handleClickLandingPagePreview}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </AccordionSection>
            <AccordionSection
              className={styles.accordion_section}
              title="Learn More Pop Up Page"
              opened={true}
              // opened={sectionOpened === 'claim-code'}
              onActionToOpen={() => setSectionOpened('claim-code')}
              onActionToClose={() => setSectionOpened(null)}
            >
              <div className={styles.form__row_space}>
                <div className={styles.form__col}>
                  <TextInput
                    tabIndex="9"
                    rows="1"
                    label="Heading"
                    disabled={isLocked}
                    placeholder="Click the Links to Learn more"
                    value={formik.values.learnMorePopupHeading}
                    onChange={(value) =>
                      formik.setFieldValue('learnMorePopupHeading', value)
                    }
                    {...(formik.touched.learnMorePopupHeading &&
                    formik.errors.learnMorePopupHeading
                      ? {
                          isError: true,
                          captionText: formik.errors.learnMorePopupHeading,
                        }
                      : {
                          captionText: `${formik.values.learnMorePopupHeading.length}/120`,
                        })}
                  />
                </div>
                <div className={styles.form__col}></div>
              </div>
              <div className={styles['form__row--heading']}>Required Links</div>
              <div className={styles.form__row_space}>
                <div className={styles.form__col}>
                  <TextInput
                    tabIndex="9"
                    rows="1"
                    label="Link 1"
                    disabled={isLocked}
                    placeholder="Link 1"
                    value={formik.values.link1}
                    onChange={(value) => formik.setFieldValue('link1', value)}
                    {...(formik.touched.link1 && formik.errors.link1
                      ? {
                          isError: true,
                          captionText: formik.errors.link1,
                        }
                      : {
                          captionText: `${formik.values.link1.length}/36`,
                        })}
                  />

                  <TextInput
                    tabIndex="3"
                    label=""
                    disabled={isLocked}
                    placeholder="http://"
                    value={formik.values.linkToWebsite}
                    onChange={(value) => {
                      formik.setFieldValue('linkToWebsite', value)
                    }}
                    {...(formik.touched.linkToWebsite &&
                    formik.errors.linkToWebsite
                      ? {
                          isError: true,
                          captionText: formik.errors.linkToWebsite,
                        }
                      : {})}
                  />
                </div>
                <div className={styles.form__col}>
                  <TextInput
                    tabIndex="9"
                    rows="1"
                    label="Link 2"
                    disabled={isLocked}
                    placeholder="Link 2"
                    value={formik.values.link2}
                    onChange={(value) => formik.setFieldValue('link2', value)}
                    {...(formik.touched.link2 && formik.errors.link2
                      ? {
                          isError: true,
                          captionText: formik.errors.link2,
                        }
                      : {
                          captionText: `${formik.values.link2.length}/36`,
                        })}
                  />
                  <TextInput
                    tabIndex="4"
                    label=""
                    disabled={isLocked}
                    placeholder="http://"
                    value={formik.values.linkToVideo}
                    onChange={(value) =>
                      formik.setFieldValue('linkToVideo', value)
                    }
                    {...(formik.touched.linkToVideo && formik.errors.linkToVideo
                      ? {
                          isError: true,
                          captionText: formik.errors.linkToVideo,
                        }
                      : {})}
                  />
                </div>
              </div>
              <div className={styles['form__row--heading']}>Optional Links</div>
              <div className={styles.form__row_space}>
                <div className={styles.form__col}>
                  <TextInput
                    tabIndex="9"
                    rows="1"
                    label="Link 3"
                    optionalShown
                    disabled={isLocked}
                    placeholder="Link 3"
                    value={formik.values.link3}
                    onChange={(value) => formik.setFieldValue('link3', value)}
                    {...(formik.touched.link3 && formik.errors.link3
                      ? {
                          isError: true,
                          captionText: formik.errors.link3,
                        }
                      : {
                          captionText: `${formik.values.link3.length}/36`,
                        })}
                  />
                  <TextInput
                    tabIndex="5"
                    label=""
                    disabled={isLocked}
                    optionalShown
                    placeholder="http://"
                    value={formik.values.linkToSchedule}
                    onChange={(value) =>
                      formik.setFieldValue('linkToSchedule', value)
                    }
                    {...(formik.touched.linkToSchedule &&
                    formik.errors.linkToSchedule
                      ? {
                          isError: true,
                          captionText: formik.errors.linkToSchedule,
                        }
                      : {})}
                  />
                </div>
                <div className={styles.form__col}>
                  <TextInput
                    tabIndex="9"
                    rows="1"
                    label="Link 4"
                    optionalShown
                    disabled={isLocked}
                    placeholder="Link 4"
                    value={formik.values.link4}
                    onChange={(value) => formik.setFieldValue('link4', value)}
                    {...(formik.touched.link4 && formik.errors.link4
                      ? {
                          isError: true,
                          captionText: formik.errors.link4,
                        }
                      : {
                          captionText: `${formik.values.link4.length}/36`,
                        })}
                  />

                  <TextInput
                    tabIndex="6"
                    label=""
                    disabled={isLocked}
                    optionalShown
                    placeholder="http://"
                    value={formik.values.linkToPricingInfo}
                    onChange={(value) =>
                      formik.setFieldValue('linkToPricingInfo', value)
                    }
                    {...(formik.touched.linkToPricingInfo &&
                    formik.errors.linkToPricingInfo
                      ? {
                          isError: true,
                          captionText: formik.errors.linkToPricingInfo,
                        }
                      : {})}
                  />
                </div>
              </div>
              <div className={styles.form__row_space}>
                <div className={styles.form__col}>
                  <TextInput
                    tabIndex="9"
                    rows="1"
                    label="Button Text"
                    disabled={isLocked}
                    placeholder="Register Today. Claim your promo code!"
                    value={formik.values.learnMorePopupBtnTxt}
                    onChange={(value) =>
                      formik.setFieldValue('learnMorePopupBtnTxt', value)
                    }
                    {...(formik.touched.learnMorePopupBtnTxt &&
                    formik.errors.learnMorePopupBtnTxt
                      ? {
                          isError: true,
                          captionText: formik.errors.learnMorePopupBtnTxt,
                        }
                      : {
                          captionText: `${formik.values.learnMorePopupBtnTxt.length}/55`,
                        })}
                  />
                </div>
                <div className={styles.form__col}>
                  <div className={styles.landing__preview__container}>
                    <label>Preview</label>
                    <div className={styles.landing__preview__button__wrapper}>
                      <SecondaryButton
                        className={styles.landing__preview__button}
                        label="Preview"
                        onClick={handleClickLearnMorePreview}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </AccordionSection>
            <AccordionSection
              className={styles.accordion_section}
              title="Claim Code Pop Up Page"
              opened={true}
              // opened={sectionOpened === 'claim-code'}
              onActionToOpen={() => setSectionOpened('claim-code')}
              onActionToClose={() => setSectionOpened(null)}
            >
              <div className={styles.form__row}>
                <div className={styles.form__col}>
                  <TextInput
                    tabIndex="9"
                    rows="1"
                    label="Heading"
                    disabled={isLocked}
                    placeholder="Thanks!"
                    value={formik.values.enrollPopupHeading}
                    onChange={(value) =>
                      formik.setFieldValue('enrollPopupHeading', value)
                    }
                    {...(formik.touched.enrollPopupHeading &&
                    formik.errors.enrollPopupHeading
                      ? {
                          isError: true,
                          captionText: formik.errors.enrollPopupHeading,
                        }
                      : {
                          captionText: `${formik.values.enrollPopupHeading.length}/65`,
                        })}
                  />
                  <TextInput
                    tabIndex="11"
                    label="Enrollment URL"
                    disabled={isLocked}
                    placeholder="http//:"
                    value={formik.values.linkToRegistrationPage}
                    onChange={(value) =>
                      formik.setFieldValue('linkToRegistrationPage', value)
                    }
                    {...(formik.touched.linkToRegistrationPage &&
                    formik.errors.linkToRegistrationPage
                      ? {
                          isError: true,
                          captionText: formik.errors.linkToRegistrationPage,
                        }
                      : {})}
                  />
                </div>

                <div className={styles.form__col}>
                  <TextInput
                    tabIndex="11"
                    label="Button Text"
                    disabled={isLocked}
                    placeholder="Registration Page"
                    value={formik.values.enrollPopupBtnTxt}
                    onChange={(value) =>
                      formik.setFieldValue('enrollPopupBtnTxt', value)
                    }
                    {...(formik.touched.enrollPopupBtnTxt &&
                    formik.errors.enrollPopupBtnTxt
                      ? {
                          isError: true,
                          captionText: formik.errors.enrollPopupBtnTxt,
                        }
                      : {
                          captionText: `${formik.values.enrollPopupBtnTxt.length}/25`,
                        })}
                  />
                  <TextArea
                    tabIndex="12"
                    label="Enrollment Instructions"
                    disabled={isLocked}
                    placeholder="Copy and insert this code on the camp registration form"
                    value={formik.values.enrollInstructions}
                    onChange={(value) =>
                      formik.setFieldValue('enrollInstructions', value)
                    }
                    {...(formik.touched.enrollInstructions &&
                    formik.errors.enrollInstructions
                      ? {
                          isError: true,
                          captionText: formik.errors.enrollInstructions,
                        }
                      : {
                          captionText: `${formik.values.enrollInstructions.length}/120`,
                        })}
                  />

                  <div className={styles.landing__preview__container}>
                    <label>Preview</label>
                    <div className={styles.landing__preview__button__wrapper}>
                      <SecondaryButton
                        className={styles.landing__preview__button}
                        label="Preview"
                        onClick={handleClickPromocodePreview}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </AccordionSection>
          </fieldset>

          <fieldset ref={emailsRef} className={styles.section__emails}>
            <h4 className={styles.section__title}>Emails to Prospects</h4>

            <div className={styles.emails_to__wrapper}>
              {[
                MESSAGE.P__HELP,
                MESSAGE.P__CONFIRM,
                // MESSAGE.P__PROMO_CODE,
                // MESSAGE.P__REGISTRATION,
              ].map((TEMPLATE, index) => (
                <div className={styles.field__email_to} key={index}>
                  <Checkbox
                    className={styles.field__email_to__checkbox}
                    label={TEMPLATE.LABEL}
                    disabled={isLocked}
                    readOnly={true}
                    isChecked={true}
                  />

                  <TertiaryButton
                    disabled={isLocked}
                    onClick={() => handleClickEmailEditor(TEMPLATE)}
                  >
                    <PencilIcon
                      className={clsx(
                        styles.button__email__icon,
                        isLocked && styles['button__email__icon--disabled']
                      )}
                    />
                    Edit
                  </TertiaryButton>

                  <SecondaryButton
                    onClick={() =>
                      window
                        .open(
                          `${PATH.PREVIEW__PS__EMAIL}?campaignId=${campaignId}&messageType=${TEMPLATE.TYPE}`,
                          '_blank'
                        )
                        .focus()
                    }
                  >
                    <EyeVisibleColorIcon
                      className={clsx(
                        styles.button__email__icon,
                        isLocked && styles['button__email__icon--disabled']
                      )}
                    />
                    View
                  </SecondaryButton>
                </div>
              ))}
            </div>
          </fieldset>

          <div className={styles.section__footer}>
            <SecondaryButton
              className={styles.button__back}
              label="Back"
              onClick={onBack}
            />

            <PrimaryButton
              className={styles.button__next}
              label="Save"
              onClick={() => handleSubmitOnly()}
            />

            <PrimaryButton
              className={styles.button__next}
              label="Save & Next"
              onClick={() => handleClickSubmit(true)}
            />

            <SecondaryButton
              label="Save & Close"
              onClick={() => handleClickSubmit(false)}
            />
          </div>
        </form>
      </div>

      <EmailTemplateEditorModal
        campaignId={campaignId}
        setupType="PS"
        {...emailTemplateToEdit}
        show={isEmailEditorOpened}
        onClose={() => setEmailEditorOpened(false)}
        isLocked={isLocked}
      />

      {activeCamp && (
        <PSPromoCodeModal
          show={showPromoCode}
          linkToRegistrationPage={formik.values.linkToRegistrationPage}
          enrollInstructions={formik.values.enrollInstructions}
          promocode={promocode}
          enrollPopupBtnTxt={formik.values.enrollPopupBtnTxt}
          enrollPopupHeading={formik.values.enrollPopupHeading}
          camp={activeCamp}
          onClose={() => setShowPromoCode(false)}
        />
      )}

      {activeCamp && (
        <PSLearnMoreModal
          show={showLearnMore}
          linkToWebsite={formik.values.linkToWebsite}
          linkToSchedule={formik.values.linkToSchedule}
          linkToVideo={formik.values.linkToVideo}
          linkToPricingInfo={formik.values.linkToPricingInfo}
          camp={activeCamp}
          link1={formik.values.link1}
          link2={formik.values.link2}
          link3={formik.values.link3}
          link4={formik.values.link4}
          learnMorePopupHeading={formik.values.learnMorePopupHeading}
          learnMorePopupBtnTxt={formik.values.learnMorePopupBtnTxt}
          onClose={() => setShowLearnMore(false)}
          onClickRegister={() => {
            setShowLearnMore(false)
            handleClickPromocodePreview()
          }}
        />
      )}
      <LoadingOverlay isLoading={isLoading} />
    </>
  )
}

export default ProspectSetup
