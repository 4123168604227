import { AUTH_STATE, STORAGE_NAME } from './constants'

export const setStorageItem = (key, value) => {
  localStorage.setItem(
    key,
    typeof value === 'object' ? JSON.stringify(value) : value
  )
}

export const getStorageItem = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key))
  } catch (error) {
    return localStorage.getItem(key)
  }
}

export const removeStorageItem = (key) => {
  localStorage.removeItem(key)
}

export const readAuthState = () => {
  return getStorageItem(STORAGE_NAME.AUTH_STATE) || AUTH_STATE.SIGNED_OUT
}

export const readAuthToken = () => {
  return getStorageItem(STORAGE_NAME.AUTH_TOKEN)
}

export const saveAuthToken = (authToken) => {
  setStorageItem(STORAGE_NAME.AUTH_TOKEN, authToken)
  setStorageItem(STORAGE_NAME.AUTH_STATE, AUTH_STATE.SIGNED_IN)
}

export const clearAuthToken = () => {
  removeStorageItem(STORAGE_NAME.AUTH_TOKEN)
  removeStorageItem(STORAGE_NAME.AUTH_STATE)
  // removeStorageItem(STORAGE_NAME.CAMP_IN_USE)
}

