import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { Link, useLocation } from 'react-router-dom'
import { useStateValue } from 'state'
import styles from './MobileHeader.module.scss'
import {
  HamburgerIcon,
  CloseIcon,
  UserIcon,
  CampIcon,
  DashboardIcon,
  CampaignIcon,
  UserMultiIplecon,
  SignOutIcon,
  LogoIcon,
  ExternalIcon,
  HelpIcon
} from 'components'
import { useScrollLock } from 'hooks'
import { getTextInitials } from 'utils'
import { PATH } from 'utils/constants'

const MobileHeader = () => {
  const {
    state: { auth, deviceSize },
    action: { userSignOut, getBilling },
  } = useStateValue()
  const [isOpened, setOpened] = useState(false)
  const [billingUrl, setBillingUrl] = useState('')
  const { pathname } = useLocation()

  useScrollLock(isOpened)

  const goToBilling = () => {
    if (auth.user)
      getBilling(auth.user.id, (url) => window.open(url, '_blank').focus())
  }

  useEffect(
    () => (['lg', 'xl', 'xxl'].includes(deviceSize) ? setOpened(false) : null),
    [deviceSize]
  )

  useEffect(() => setOpened(false), [pathname])

  const handleClickMenuIcon = () => {
    document.querySelector(`.${styles.menu}`).scrollTo(0, 0)
    setOpened(!isOpened)
  }

  return (
    <>
      <div className={styles.top_bar}>
        <div className={styles.avatar}>
          {getTextInitials(auth.user.name)}
          <span className={styles.badge}>2</span>
        </div>

        <Link className={styles.top_bar__logo} to="/">
          <LogoIcon />
        </Link>

        <div
          className={styles.top_bar__menu_button}
          onClick={handleClickMenuIcon}
        >
          {isOpened ? <CloseIcon /> : <HamburgerIcon />}
        </div>
      </div>

      <div className={clsx(styles.menu, isOpened && styles.menu__opened)}>
        {[
          { Icon: UserIcon, text: 'User Profile', to: PATH.AUTH__YOUR_PROFILE },
          { Icon: CampIcon, text: 'My Camps', to: PATH.CAMPS },
          { Icon: DashboardIcon, text: 'Dashboard', to: PATH.DASHBOARD },
          { Icon: CampaignIcon, text: 'Campaigns', to: PATH.CAMPAIGNS },
          { Icon: UserMultiIplecon, text: 'Ambassadors', to: PATH.AMBASSADORS },
          { Icon: UserMultiIplecon, text: 'Prospects', to: PATH.PROSPECTS },
          { Icon: HelpIcon, text: 'Help', to: PATH.HELP },

        ].map(({ Icon, text, to }, mIdx) => (
          <Link key={mIdx} className={styles.menu__item} to={to}>
            <Icon className={styles.menu__item__icon} />
            <span
              className={styles.menu__item__text}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          </Link>
        ))}
        {billingUrl && (
          <a
            className={styles.menu__item}
            to={{ pathname: billingUrl }}
            onClick={goToBilling}
          >
            <ExternalIcon className={styles.menu__item__icon} />
            <span
              className={styles.menu__item__text}
              dangerouslySetInnerHTML={{ __html: 'Billing' }}
            />
          </a>
        )}

        <button
          type="button"
          className={clsx(styles.menu__item, styles.menu__item__logout)}
          onClick={userSignOut}
        >
          <SignOutIcon className={styles.menu__item__icon} />
          <span
            className={clsx(
              styles.menu__item__text,
              styles.menu__item__logout_text
            )}
          >
            Logout
          </span>
        </button>
      </div>
    </>
  )
}

export default MobileHeader
