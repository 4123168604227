import {
  getAxiosInstance,
  getStorageItem,
  setStorageItem,
  handleError,
} from 'utils'
import { ACTION, STORAGE_NAME } from 'utils/constants'

export const addCamp = (dispatch) => async (campDetails, callback, onError) => {
  try {
    dispatch({ type: ACTION.START })

    const formData = new FormData()

    Object.keys(campDetails).forEach((key) => {
      const data = campDetails[key]

      if (data) {
        if (['roles', 'seasons'].includes(key))
          formData.append(key, JSON.stringify(data))
        else formData.append(key, data)
      }
    })

    const response = await getAxiosInstance().post('/api/camps/add', formData)

    if (response) {
      const { success, code, message, camp } = response.data

      if (success) {
        dispatch({ type: ACTION.CAMP__ADD, payload: { camp } })

        callback(camp)
      } else {
        onError()
        throw new Error(JSON.stringify({ code, message }))
      }
    }
  } catch (error) {
    handleError(dispatch, error)
  } finally {
    dispatch({ type: ACTION.END })
  }
}

export const deleteCamp = (dispatch) => async (id, callback) => {
  try {
    dispatch({ type: ACTION.START })

    const response = await getAxiosInstance().delete(`/api/camps/${id}`)

    if (response) {
      const { success, code, message } = response.data

      if (success) {
        dispatch({ type: ACTION.CAMP__DELETE, payload: { id } })
        callback()
      } else throw new Error(JSON.stringify({ code, message }))
    }
  } catch (error) {
    handleError(dispatch, error)
  } finally {
    dispatch({ type: ACTION.END })
  }
}

export const getCamp =
  (dispatch) =>
  async (id, callback = () => null) => {
    try {
      dispatch({ type: 'CT_ACTION_START' })

      const response = await getAxiosInstance().get(`/api/camps/${id}`)

      const { success, camp, code, message } = response.data
      if (response) {
        if (success) callback(camp)
        else throw new Error(JSON.stringify({ code, message }))
      }
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: 'CT_ACTION_END' })
    }
  }

export const getCampSeasons =
  (dispatch) =>
  async (id, callback = () => null, errCallback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().get(`/api/camps/${id}`)

      if (response) {
        const { success, camp, code, message } = response.data

        if (success) callback(camp.seasons)
        else throw new Error(JSON.stringify({ code, message }))
      }
    } catch (error) {
      errCallback(error)
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const getActiveCamp =
  (dispatch) =>
  async (callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const campId = getStorageItem(STORAGE_NAME.CAMP_IN_USE)

      const response = await getAxiosInstance().get(`/api/camps/${campId}`)

      if (response) {
        const { success, camp, code, message } = response.data

        if (success) {
          dispatch({ type: ACTION.CAMP__GET_ACTIVE, payload: { camp } })
          callback(camp)
        } else throw new Error(JSON.stringify({ code, message }))
      }
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const activeCamp = (dispatch) => async (id) => {
  setStorageItem(STORAGE_NAME.CAMP_IN_USE, id)

  dispatch({ type: ACTION.CAMP__SET_ACTIVE, payload: { id } })
}

export const updateCamp = (dispatch) => async (id, campDetails, callback) => {
  try {
    dispatch({ type: ACTION.START })

    const formData = new FormData()

    Object.keys(campDetails).forEach((key) => {
      const data = campDetails[key]

      if (data) {
        if (['roles', 'seasons'].includes(key))
          formData.append(key, JSON.stringify(data))
        else formData.append(key, data)
      }
    })

    const response = await getAxiosInstance().put(`/api/camps/${id}`, formData)
    if (response) {
      const { success, code, message, camp } = response.data

      if (success) {
        dispatch({ type: ACTION.CAMP__UPDATE, payload: { camp } })

        callback(camp)
      } else throw new Error(JSON.stringify({ code, message }))
    }
  } catch (error) {
    handleError(dispatch, error)
  } finally {
    dispatch({ type: ACTION.END })
  }
}

export const getCampStats =
  (dispatch) =>
  async (id, callback = () => null, errCallback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().get(`/api/camps/${id}/stats`)

      if (response) {
        const { success, stats, code, message } = response.data

        if (success) callback(stats)
        else throw new Error(JSON.stringify({ code, message }))
      }
    } catch (error) {
      errCallback(error)
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const getCampCsvFiles =
  (dispatch) =>
  async (id, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().get(
        `/api/camps/${id}/csv-files`
      )

      if (response) {
        const { success, files, code, message } = response.data

        if (success) callback(files)
        else throw new Error(JSON.stringify({ code, message }))
      }
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }
