import React from 'react';
import styles from './Layout.module.scss';
import { Header } from 'components';

const Layout = ({ children, bgColor = null }) => {
  return (
    <div className={styles.layout}>
      <Header />

      <div className={styles.main__wrapper} style={bgColor ? { backgroundColor: bgColor } : {}}>
        <div className={styles.main}>
          {children}
        </div>
      </div>
    </div >
  );
};

export default Layout;
