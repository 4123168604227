import { getAxiosInstance, handleError } from 'utils'
import { ACTION, ERR_CODE } from 'utils/constants'

export const getProspectSetupData =
  (dispatch) =>
  async (uniqueLink, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().get(`/api/share/${uniqueLink}`)

      // { "success": false, "code": "AMBASSADOR_NOT_FOUND", "message": "No ambassador was found for unique link 'abajaba.' Please check your share link."
      // console.log(JSON.stringify(response.data, null, 3))
      const { success, code, message, ambassador } = response.data
      // console.log(`code: ${code}`)
      // console.log(`message: ${message}`)

      if (success || code === ERR_CODE.AMBASSADOR_NOT_FOUND) {
        callback({ ambassador, code, message })
      } else {
        throw new Error(JSON.stringify({ code, message }))
      }
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const getAmbassadorSetupData =
  (dispatch) =>
  async ({ type, uniqueLink, preview = false }, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      let response = null
      if (type === 'l' || type === 'd') {
        response = await getAxiosInstance().get(`/api/ambassador/${uniqueLink}`)
      }

      if (type === 'c') {
        // http://localhost:3000/a/c/sun-flower-2
        // This is really a slug for a public landing link
        const url = preview
          ? `/api/ambassador/setup/${uniqueLink}?preview=true`
          : `/api/ambassador/setup/${uniqueLink}`
        response = await getAxiosInstance().get(url)
      }
      // TODO: Do we need error handling here? What happens when gibberish
      // uniqueId is submitted? Unknown error with blank screen repeating.
      const { success, code, ambassador, registrations, message } =
        response.data
      if (ambassador) ambassador.registrations = registrations
      if (success) callback(ambassador)
      else {
        callback(ambassador)
        throw new Error(JSON.stringify({ code, message }))
      }
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

// TODO Implement this
export const confirmEmail =
  (dispatch) =>
  async ({ token }, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })
      let response = null
      response = await getAxiosInstance().get(
        `/api/ambassador/confirm-email/${token}`
      )
      const { success, code, message, invitation, ambassador } = response.data

      if (success) callback({ invitation, ambassador })
      else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const getCampInvitation =
  (dispatch) =>
  async (id, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })
      let response = null
      response = await getAxiosInstance().get(`/api/camps/invitations/${id}`)

      const { success, invitation, code, message } = response.data

      if (success) callback({ invitation })
      else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: 'CT_ACTION_END' })
    }
  }
