import React from 'react';
import {
  LogoIcon, GreenLeafIcon, CheckMarkCircleIcon, PrimaryButton
} from 'components';
import styles from './Success.module.scss';
import { useNavigate } from 'hooks';
import { PATH } from 'utils/constants';

const Success = () => {
  const navigate = useNavigate();

  const handleClickGetStarted = () => navigate({ pathname: PATH.HOME });

  return (
    <div className={styles.page__container}>
      <div className={styles.page__wrapper}>
        <div className={styles.page__bg}>
          <GreenLeafIcon />
          <GreenLeafIcon />
        </div>

        <div className={styles.page}>
          <LogoIcon className={styles.logo} />

          <CheckMarkCircleIcon className={styles.check_mark} />

          <h3 className={styles.success}>Success!</h3>

          <p className={styles.message}>Success!</p>

          <PrimaryButton className={styles.get_started} label='GET STARTED' onClick={handleClickGetStarted} />
        </div>
      </div>
    </div>
  );
};

export default Success;
