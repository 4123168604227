import React, { } from 'react';
import styles from './CampSeason.module.scss';
import { Modal, ExcludedIcon, PrimaryButton, SecondaryButton, TagInput } from 'components';
import { useStateValue } from 'state';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getStorageItem } from 'utils';
import { STORAGE_NAME } from 'utils/constants';


const CampSeason = ({ show, seasons = [], onSave = () => null, onClose = () => null }) => {
  const campIdInUse = getStorageItem(STORAGE_NAME.CAMP_IN_USE);
  const { state: { deviceSize }, action: { updateCamp } } = useStateValue();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { seasons },
    validationSchema: Yup.object({
      // seasons: Yup.array().min(1, 'Please input at least one season.')
    }),
    onSubmit: ({ seasons }) => {
      if (campIdInUse) updateCamp(campIdInUse, { seasons });

      onSave(seasons);
      onClose();
    }
  });

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  return (
    <Modal classNames={{ modal: styles.modal, content: styles.modal__content }} show={show} onClose={handleClose}>
      <h6 className={styles.title}>Camp Seasons</h6>

      <button type='button' className={styles.close} onClick={handleClose}>
        <ExcludedIcon />
      </button>

      <div className={styles.seasons}>
        <TagInput
          tags={formik.values.seasons}
          onChange={tags => formik.setFieldValue('seasons', tags)}
          {...(formik.touched.seasons && formik.errors.seasons ? { isError: true, captionText: formik.errors.seasons } : {})}
        />
      </div>

      <div className={styles.buttons}>
        <SecondaryButton
          className={styles.button__cancel}
          label={['xs', 'sm', 'md'].includes(deviceSize) ? 'Back' : 'Cancel'}
          onClick={handleClose}
        />

        <PrimaryButton
          className={styles.button__save}
          label='Save'
          onClick={formik.handleSubmit}
        />
      </div>
    </Modal >
  );
};

export default CampSeason;
