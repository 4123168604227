import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import clsx from 'clsx'
import {
  LogoIcon,
  TextInput,
  PasswordInput,
  PrimaryButton,
  TertiaryButton,
  Illustration1,
  SetupForm,
  CardSetupResponse,
  LoadingOverlay,
  Subscriptions,
} from 'components'
import styles from './SignUpInvitation.module.scss'
import { useStateValue } from 'state'
import { useNavigate } from 'hooks'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { PATH } from 'utils/constants'
import { toast } from 'react-toastify'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

const SignUp = () => {
  const {
    REACT_APP_STRIPE_SETUP_FEE,
    REACT_APP_STRIPE_YEARLY,
    REACT_APP_STRIPE_YEARLY_PRICE,
    REACT_APP_STRIPE_MONTHLY,
    REACT_APP_STRIPE_MONTHLY_PRICE,
    REACT_APP_STRIPE_TRIAL_PERIOD,
  } = process.env
  const { id } = useParams()
  const navigate = useNavigate()
  const {
    state: { deviceSize, error },
    action: {
      userSignUpInvitation,
      getCampInvitation,
      createSetupIntentStripe,
    },
  } = useStateValue()
  const [setupComplete, setSetupComplete] = useState(false)
  const [showPaymentMethod, setShowPaymentMethod] = useState(false)
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false)
  const [clientSecret, setClientSecret] = useState('')
  const [userId, setUserId] = useState('')
  const [campInvitation, setCampInvitation] = useState({
    businessName: '',
    fName: '',
    lName: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
    expires: null,
    status: '',
    invitationId: id,
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: campInvitation,
    validationSchema: Yup.object({
      businessName: Yup.string().required('Please enter your Business name.'),
      fName: Yup.string().required('Please enter account first owner name'),
      lName: Yup.string().required('Please enter account last owner name'),
      phone: Yup.string().required('Please enter a phone number'),
      email: Yup.string()
        .required('Please enter your email.')
        .email('Please enter a valid email.'),
      password: Yup.string()
        .required('Please enter a password.')
        .min(8, ({ min }) => `Password should be ${min} chars minimum.`)
        .max(20, ({ max }) => `Password should be ${max} chars maximum.`)
        .matches(/[A-Z]/, 'Must contain at least one uppercase character.')
        .matches(/[a-z]/, 'Must contain at least one lowercase character.')
        .matches(/[0-9]/, 'Must contain at least one number.'),
      confirmPassword: Yup.string()
        .required('Please enter a confirm password.')
        .oneOf([Yup.ref('password')], 'Passwords must be matched.'),
    }),
    onSubmit: (formValues) => {
      setShowSubscriptionModal(true)
    },
  })

  const handleInvitation = ({ invitation }) => {
    setCampInvitation({
      ...invitation,
      businessName: invitation.campName ?? '',
      fName: invitation.firstName ?? '',
      lName: invitation.lastName ?? '',
      email: invitation.email ?? '',
      phone: invitation.phone ?? '',
      password: '',
      confirmPassword: '',
      expires: null,
    })
  }

  useEffect(() => {
    getCampInvitation(id, handleInvitation)
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickLogIn = () => navigate({ pathname: PATH.AUTH__SIGN_IN })

  if (error)
    setTimeout(() => {
      handleClickLogIn()
    }, 1500)

  const saveUserInfo = () => {
    userSignUpInvitation({ ...formik.values, userId }, () => {
      toast.info('Account created successfully! Please login to setup Camps')
      navigate({ pathname: PATH.AUTH__SIGN_IN })
    })
  }

  return (
    <div className={styles.page__container}>
      <div className={styles.page__wrapper}>
        <div
          className={clsx(
            styles.page,
            showSubscriptionModal && styles.page__payment
          )}
        >
          <LogoIcon className={styles.logo} />
          {['xs', 'sm', 'md'].includes(deviceSize) ? (
            <h4 className={styles.page__title}>
              {showPaymentMethod | showSubscriptionModal
                ? ''
                : ' Welcome to Camp Tree!'}
              <br />{' '}
              {showPaymentMethod
                ? 'Please provide payment information.'
                : showSubscriptionModal
                ? 'Please select your billing plan.'
                : 'Enter account details.'}
            </h4>
          ) : (
            <h5 className={styles.page__title}>
              {showPaymentMethod | showSubscriptionModal
                ? ''
                : ' Welcome to Camp Tree!'}
              <br />{' '}
              {showPaymentMethod
                ? 'Please provide payment information.'
                : showSubscriptionModal
                ? 'Please select your billing plan.'
                : 'Enter account details.'}
            </h5>
          )}

          {showPaymentMethod ? (
            <>
              <div className={styles.payment_info}>
                Your account will not be charged until after the 30-day trial
                period.
              </div>
              <Elements
                stripe={stripePromise}
                options={{ clientSecret: clientSecret }}
              >
                <SetupForm
                  paymentSuccessful={() => {
                    setSetupComplete(true)
                  }}
                />
              </Elements>
              {setupComplete ? (
                <>
                  <LoadingOverlay isLoading={true} />
                  <Elements
                    stripe={stripePromise}
                    options={{ setupIntentClientSecret: clientSecret }}
                  >
                    <CardSetupResponse
                      clientSecret={clientSecret}
                      saveUserInfo={saveUserInfo}
                      errorSaving={() => {
                        setSetupComplete(false)
                      }}
                    />
                  </Elements>
                </>
              ) : null}
            </>
          ) : showSubscriptionModal ? (
            <Subscriptions
              monthly_price={REACT_APP_STRIPE_MONTHLY_PRICE}
              yearly_price={REACT_APP_STRIPE_YEARLY_PRICE}
              onOK={(subs, promoCode) => {
                const subsData = {
                  subscriptionPrice:
                    subs === 'monthly'
                      ? REACT_APP_STRIPE_MONTHLY
                      : REACT_APP_STRIPE_YEARLY,
                  setupPrice: REACT_APP_STRIPE_SETUP_FEE,
                  promoCode: promoCode,
                  email: formik.values.email,
                  name: formik.values.fName + formik.values.lName,
                  trialPeriodDays: REACT_APP_STRIPE_TRIAL_PERIOD || 0,
                }
                createSetupIntentStripe(subsData, (client_secret, userId) => {
                  setClientSecret(client_secret)
                  setUserId(userId)
                  setShowPaymentMethod(true)
                  setShowSubscriptionModal(false)
                })
              }}
              purpose="new_camp"
              includes={[
                'Unlimited Campaigns',
                'Unlimited Ambassadors',
                '30-day Free Trial',
              ]}
              terms={[
                'Pricing is based on 12-month subscription.',
                'A one-time Onboarding fee of $99 will be added to your first payment.',
                'No payment will be charged until after a 30-day free trial.',
                'Includes 1 location. Additional locations/entities may be added at a 25% discounted price.',
              ]}
              submitButtonText="Continue"
            />
          ) : (
            <form>
              <TextInput
                label="Business Name"
                value={formik.values.businessName}
                onChange={(val) => formik.setFieldValue('businessName', val)}
                {...(formik.touched.businessName && formik.errors.businessName
                  ? { isError: true, captionText: formik.errors.businessName }
                  : {})}
              />
              <TextInput
                label="Account Owner First Name"
                value={formik.values.fName}
                onChange={(val) => formik.setFieldValue('fName', val)}
                {...(formik.touched.fName && formik.errors.fName
                  ? { isError: true, captionText: formik.errors.fName }
                  : {})}
              />
              <TextInput
                label="Account Owner Last Name"
                value={formik.values.lName}
                onChange={(val) => formik.setFieldValue('lName', val)}
                {...(formik.touched.lName && formik.errors.lName
                  ? { isError: true, captionText: formik.errors.lName }
                  : {})}
              />
              <TextInput
                label="Account Owner Email (will be your login)"
                value={formik.values.email}
                disabled
              />
              <TextInput
                label="Phone"
                value={formik.values.phone}
                onChange={(phone) => formik.setFieldValue('phone', phone)}
                {...(formik.touched.phone && formik.errors.phone
                  ? { isError: true, captionText: formik.errors.phone }
                  : {})}
              />
              <PasswordInput
                label="New Password"
                value={formik.values.password}
                onChange={(password) =>
                  formik.setFieldValue('password', password)
                }
                {...(formik.touched.password && formik.errors.password
                  ? { isError: true, captionText: formik.errors.password }
                  : {})}
              />

              <PasswordInput
                classNames={{ container: styles.confirm_password }}
                label="Confirm New Password"
                value={formik.values.confirmPassword}
                onChange={(confirmPassword) =>
                  formik.setFieldValue('confirmPassword', confirmPassword)
                }
                {...(formik.touched.confirmPassword &&
                formik.errors.confirmPassword
                  ? {
                      isError: true,
                      captionText: formik.errors.confirmPassword,
                    }
                  : {})}
              />
              {error && <div className={styles.error}>{error.message}</div>}

              <div className={styles.buttons__container}>
                <PrimaryButton
                  className={styles.button__sign_up}
                  label="CONFIRM"
                  onClick={formik.handleSubmit}
                  disabled={error}
                />

                {['xs', 'sm'].includes(deviceSize) && (
                  <TertiaryButton
                    className={styles.button__login}
                    label="LOGIN"
                    onClick={handleClickLogIn}
                  />
                )}
              </div>
            </form>
          )}
        </div>

        {deviceSize === 'xxl' ? (
          <Illustration1 className={styles.page__illustration} />
        ) : null}
      </div>
    </div>
  )
}

export default SignUp
