import { ACTION, ERR_CODE } from './constants'

export const handleError = (dispatch, error) => {
  let code = null,
    message = null

  try {
    const errData = JSON.parse(error?.message || error?.error || '{}')
    code = errData.code
    message = errData.message || errData.error
  } catch (error) {
  } finally {
    if (code === ERR_CODE.AUTH_TOKEN) dispatch({ type: ACTION.AUTH__SIGN_OUT })

    dispatch({
      type: ACTION.ERROR,
      payload: {
        code: code || ERR_CODE.UNKNOWN,
        message: message || 'Unknown Error.',
      },
    })
  }
}

export const isAuthError = (error) => error?.code.startsWith('AUTH_')
