import React from 'react';

const Illustration2 = ({ className }) => (
  <svg className={className} width="390" height="394" viewBox="0 0 390 394" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M33.8882 166.174C33.8882 259.64 129.632 316.422 211.142 304.324C359.925 282.275 385.665 102.173 349.395 52.6102C270.616 -55.1 33.8882 74.0742 33.8882 166.174Z" fill="#DDEDDA" />
    <path d="M1.8418 167.438C23.5594 167.438 23.5594 159.277 45.2771 159.277C66.9947 159.277 66.9947 167.438 88.7123 167.438C110.43 167.438 110.43 159.277 132.148 159.277" stroke="#FCAD37" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M1.84131 185.194C23.5589 185.194 23.5589 177.033 45.2766 177.033C66.9942 177.033 66.9942 185.194 88.7118 185.194C110.429 185.194 110.429 177.033 132.147 177.033" stroke="#FCAD37" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M1.84131 202.949C23.5589 202.949 23.5589 194.788 45.2766 194.788C66.9942 194.788 66.9942 202.949 88.7118 202.949C110.429 202.949 110.429 194.788 132.147 194.788" stroke="#FCAD37" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M1.84131 220.705C23.5589 220.705 23.5589 212.544 45.2766 212.544C66.9942 212.544 66.9942 220.705 88.7118 220.705C110.429 220.705 110.429 212.544 132.147 212.544" stroke="#FCAD37" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M1.84131 238.46C23.5589 238.46 23.5589 230.299 45.2766 230.299C66.9942 230.299 66.9942 238.46 88.7118 238.46C110.429 238.46 110.429 230.299 132.147 230.299" stroke="#FCAD37" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M1.84131 256.215C23.5589 256.215 23.5589 248.055 45.2766 248.055C66.9942 248.055 66.9942 256.215 88.7118 256.215C110.429 256.215 110.429 248.055 132.147 248.055" stroke="#FCAD37" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <g opacity="0.5">
      <path d="M103.126 68.1077C95.565 68.1077 89.4353 62.1532 89.4353 54.8079C89.4353 47.4626 95.565 41.508 103.126 41.508C110.688 41.508 116.817 47.4626 116.817 54.8079C116.817 62.1532 110.688 68.1077 103.126 68.1077Z" fill="#5992FF" />
      <path d="M345.212 84.8612C349.171 84.8612 352.381 81.6518 352.381 77.6928C352.381 73.7339 349.171 70.5245 345.212 70.5245C341.253 70.5245 338.044 73.7339 338.044 77.6928C338.044 81.6518 341.253 84.8612 345.212 84.8612Z" fill="#E47E7D" />
      <path d="M175.493 393.563C182.839 393.563 188.793 387.609 188.793 380.263C188.793 372.918 182.839 366.963 175.493 366.963C168.148 366.963 162.193 372.918 162.193 380.263C162.193 387.609 168.148 393.563 175.493 393.563Z" fill="#E47E7D" />
      <path d="M349.637 218.463C371.929 218.463 390.001 200.392 390.001 178.1C390.001 155.808 371.929 137.736 349.637 137.736C327.345 137.736 309.274 155.808 309.274 178.1C309.274 200.392 327.345 218.463 349.637 218.463Z" fill="#E47E7D" />
      <path d="M284.397 50.012C270.815 50.012 259.804 39.0014 259.804 25.4191C259.804 11.8368 270.815 0.826141 284.397 0.826141C297.98 0.826141 308.99 11.8368 308.99 25.4191C308.99 39.0014 297.98 50.012 284.397 50.012Z" fill="#5992FF" />
      <path d="M56.9344 270.797C65.2178 270.797 71.9328 264.082 71.9328 255.799C71.9328 247.515 65.2178 240.8 56.9344 240.8C48.651 240.8 41.936 247.515 41.936 255.799C41.936 264.082 48.651 270.797 56.9344 270.797Z" fill="#5992FF" />
    </g>
    <path d="M103.495 263.72C103.225 263.72 103.044 263.54 103.044 263.27V127.378C103.044 127.107 103.225 126.927 103.495 126.927H303.05C303.32 126.927 303.501 127.107 303.501 127.378V263.179C303.501 263.45 303.32 263.63 303.05 263.63H103.495V263.72Z" fill="#DDEDDA" />
    <path d="M103.497 127.829C103.316 127.829 103.136 127.739 103.046 127.558C102.955 127.378 103.046 127.198 103.136 127.017L188.801 51.9929C192.768 48.4761 197.908 46.5825 203.229 46.5825C208.549 46.5825 213.689 48.4761 217.656 51.9929L303.321 127.017C303.502 127.108 303.502 127.378 303.412 127.558C303.321 127.739 303.141 127.829 302.961 127.829H103.497Z" fill="#F1F8F0" />
    <path d="M127.573 113.852L114.317 127.108V169.94H292.32V127.108L278.975 113.852H127.573Z" fill="#91C688" />
    <path d="M127.573 236.494C127.302 236.494 127.122 236.314 127.122 236.044V64.8037C127.122 64.5332 127.302 64.3529 127.573 64.3529H278.975C279.245 64.3529 279.425 64.5332 279.425 64.8037V236.044C279.425 236.314 279.245 236.494 278.975 236.494H127.573Z" fill="white" />
    <path d="M202.799 103.238C205.234 103.238 207.209 102.879 207.209 102.436C207.209 101.993 205.234 101.634 202.799 101.634C200.364 101.634 198.39 101.993 198.39 102.436C198.39 102.879 200.364 103.238 202.799 103.238Z" fill="#56A748" />
    <path d="M204.127 71.9706C204.127 71.9706 195.183 79.8358 195.183 86.688C195.183 91.9796 200.201 95.2204 202.757 95.2204C205.313 95.2204 209.614 91.9549 209.614 87.3716C209.614 82.7884 202.969 78.7569 204.127 71.9706Z" fill="#56A748" />
    <path d="M203.157 96.0221C200.347 96.0221 195.183 92.6082 195.183 87.0301C195.183 84.0796 196.714 80.6368 199.74 76.7966C201.116 75.0549 202.626 73.4276 204.257 71.9303L205.12 71.1689L204.925 72.3151C204.286 76.0973 206.118 78.9608 207.89 81.7334C209.189 83.7651 210.415 85.6811 210.415 87.7377C210.415 92.4675 206.012 96.0221 203.157 96.0221ZM203.996 73.3082C201.83 75.4187 195.997 81.6175 195.997 87.0301C195.997 92.0165 200.697 95.1945 203.157 95.1945C205.418 95.1945 209.601 92.1199 209.601 87.746C209.601 85.9335 208.436 84.1169 207.206 82.1927C205.601 79.6767 203.805 76.8628 203.996 73.3082Z" fill="#34642B" />
    <path d="M203.905 92.7289C203.867 92.7516 203.824 92.7651 203.779 92.768C203.734 92.7709 203.689 92.7633 203.648 92.7457C203.607 92.7281 203.572 92.7013 203.546 92.6677C203.519 92.6341 203.503 92.5948 203.497 92.5537L202.947 88.1523C202.849 88.1119 202.756 88.0625 202.669 88.0049L202.175 91.2353C202.169 91.2741 202.153 91.3112 202.129 91.3433C202.104 91.3754 202.072 91.4015 202.034 91.4195C201.996 91.4375 201.954 91.4468 201.911 91.4467C201.868 91.4465 201.826 91.4368 201.789 91.4185L200.084 90.6218L199.993 90.9126C199.993 90.9126 201.502 92.4501 201.728 92.9042C202.097 93.665 201.819 100.388 201.728 102.065C201.726 102.113 201.735 102.161 201.754 102.206C201.773 102.251 201.801 102.292 201.838 102.327C201.874 102.361 201.918 102.389 201.966 102.407C202.014 102.426 202.066 102.436 202.118 102.436H203.268C203.319 102.436 203.369 102.428 203.417 102.41C203.464 102.392 203.507 102.366 203.543 102.333C203.58 102.3 203.608 102.261 203.628 102.218C203.648 102.175 203.658 102.128 203.658 102.081V94.2027C203.658 94.1708 203.665 94.1392 203.678 94.1098C203.691 94.0804 203.711 94.0537 203.736 94.0314L205.605 92.3147L205.341 91.8805L203.905 92.7289Z" fill="#34642B" />
    <path d="M202.387 77.988C202.45 77.6663 202.244 77.4615 201.968 77.6621C201.101 78.3181 199.417 80.4407 199.208 81.6566C198.961 83.1023 201.558 84.1093 201.47 81.9365C201.382 79.7638 202.11 79.3209 202.387 77.988Z" fill="#91C688" />
    <path d="M264.366 117.435H142.18C141.098 117.435 140.287 116.624 140.287 115.542C140.287 114.46 141.098 113.648 142.18 113.648H264.366C265.448 113.648 266.259 114.46 266.259 115.542C266.349 116.534 265.448 117.435 264.366 117.435Z" fill="#C4E0BF" />
    <path d="M264.366 126.368H142.18C141.098 126.368 140.287 125.556 140.287 124.474C140.287 123.392 141.098 122.581 142.18 122.581H264.366C265.448 122.581 266.259 123.392 266.259 124.474C266.349 125.556 265.448 126.368 264.366 126.368Z" fill="#C4E0BF" />
    <path d="M214.734 135.385H142.18C141.098 135.385 140.287 134.573 140.287 133.491C140.287 132.409 141.098 131.597 142.18 131.597H214.734C215.816 131.597 216.628 132.409 216.628 133.491C216.718 134.573 215.816 135.385 214.734 135.385Z" fill="#C4E0BF" />
    <path d="M279.452 134.701V240.101H126.857V134.701L170.936 168.605L183.024 177.958C185.023 179.487 187.841 179.397 189.749 177.778C197.384 171.123 208.835 171.123 216.469 177.778C218.378 179.397 221.195 179.487 223.195 177.958L279.452 134.701Z" fill="#F1F8F0" />
    <path d="M303.051 127.375V263.266L215.672 195.366L303.051 127.375Z" fill="#C4E0BF" />
    <path d="M190.965 195.275L103.496 263.266V127.375L169.233 178.413L190.965 195.275Z" fill="#C4E0BF" />
    <path d="M215.672 195.279L303.051 263.18V259.122C289.254 241.989 242.724 200.148 229.649 184.458L215.672 195.279Z" fill="#91C688" />
    <path d="M190.965 195.279L103.586 263.18V259.122C117.383 241.989 163.913 200.148 176.988 184.458L190.965 195.279Z" fill="#91C688" />
    <path d="M303.05 263.269H103.496L189.161 188.245C197.277 181.121 209.36 181.121 217.385 188.245L303.05 263.269Z" fill="#C4E0BF" />
  </svg>
);

export default Illustration2;