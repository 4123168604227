import React from 'react'
import ReactDOM from 'react-dom'
import './assets/scss/global.scss'
import 'react-toggle/style.css'
import { RouterProvider } from 'react-router-dom'
import router from 'App'
import { StateProvider } from 'state'
import { AuthOverlay } from 'components'
import { Helmet } from 'react-helmet'
import { ToastContainer } from 'react-toastify'

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <title>Camp Tree</title>
      <meta name="description" content="Friends sharing camp referrals" />
    </Helmet>
    <StateProvider>
      <AuthOverlay>
        <RouterProvider router={router} />
      </AuthOverlay>
    </StateProvider>
    <ToastContainer />
  </React.StrictMode>,
  document.getElementById('root')
)
