import { getAxiosInstance, handleError } from 'utils';
import { ACTION } from 'utils/constants';

export const getTermsTemplate = dispatch => async (campaignId, callback = () => null) => {
  try {
    dispatch({ type: ACTION.START });

    const response = await getAxiosInstance().get(`/api/campaigns/${campaignId}/templates/terms`);

    const { success, code, message, templates } = response.data;

    if (success) callback(templates);
    else throw new Error(JSON.stringify({ code, message }));
  } catch (error) {
    handleError(dispatch, error);
  } finally {
    dispatch({ type: ACTION.END });
  }
};

export const updateTermsTemplate = dispatch => async ({ campaignId, templateType, fileType, data }, callback = () => null) => {
  try {
    dispatch({ type: ACTION.START });

    const response = await getAxiosInstance().put(`/api/campaigns/${campaignId}/templates/terms/${templateType}/${fileType}`, data);

    const { success, code, message, template } = response.data;

    if (success) callback(template);
    else throw new Error(JSON.stringify({ code, message }));
  } catch (error) {
    handleError(dispatch, error);
  } finally {
    dispatch({ type: ACTION.END });
  }
};

export const getEmailTemplate = dispatch => async (campaignId, messageType, callback = () => null) => {
  try {
    dispatch({ type: ACTION.START });

    const response = await getAxiosInstance().get(`/api/campaigns/${campaignId}/templates/${messageType}`);

    const { success, code, message, templates } = response.data;

    if (success) callback(templates);
    else throw new Error(JSON.stringify({ code, message }));
  } catch (error) {
    handleError(dispatch, error);
  } finally {
    dispatch({ type: ACTION.END });
  }
};

export const updateEmailTemplate = dispatch => async ({ campaignId, messageType, templateType, fileType, data }, callback = () => null) => {
  try {
    dispatch({ type: ACTION.START });

    const response = await getAxiosInstance().put(`/api/campaigns/${campaignId}/templates/${messageType}/${templateType}/${fileType}`, data);

    const { success, code, message, template } = response.data;

    if (success) callback(template);
    else throw new Error(JSON.stringify({ code, message }));
  } catch (error) {
    handleError(dispatch, error);
  } finally {
    dispatch({ type: ACTION.END });
  }
};