import React from 'react'
import { Modal, PrimaryButton, SecondaryButton, ExcludedIcon } from 'components'
import styles from './Confirm.module.scss'

const Confirm = ({
  show,
  title = 'Confirm',
  description = 'Are you sure you want to delete?',
  isLoading = false,
  onCancel = () => null,
  onOK = () => null,
  okLabel = 'OK',
}) => {
  const handleClose = () => {
    if (isLoading) return

    onCancel()
  }

  return (
    <Modal
      classNames={{ modal: styles.modal }}
      show={show}
      onClose={handleClose}
    >
      <h6
        className={styles.title}
        dangerouslySetInnerHTML={{ __html: title }}
      />

      <p
        className={styles.body}
        dangerouslySetInnerHTML={{ __html: description }}
      />

      <button type="button" className={styles.close} onClick={handleClose}>
        <ExcludedIcon />
      </button>

      <div className={styles.buttons}>
        <SecondaryButton
          className={styles.button__cancel}
          label="Cancel"
          disabled={isLoading}
          onClick={handleClose}
        />

        <PrimaryButton
          className={styles.button__ok}
          label={okLabel}
          disabled={isLoading}
          onClick={onOK}
        />
      </div>
    </Modal>
  )
}

export default Confirm
