import React from "react";
import { useFormik } from "formik";
import styles from "./SendTestEmails.module.scss";
import {
  Modal,
  PrimaryButton,
  SecondaryButton,
  ExcludedIcon,
} from "components";
import { useStateValue } from "state";
import clsx from "clsx";

const SendTestEmails = ({
  classNames = { modal: "", content: "" },
  show,
  data: initData = {},
  onClose = () => null,
  onSend = () => null,
}) => {
  const {
    state: { deviceSize },
  } = useStateValue();
  const formik = useFormik({
    enableReinitialize: true,
    onSubmit: (args) => {
      console.log("formik submit args: ", args);
      onSend(args);
      handleClose();
    },
  });

  const handleSubmit = () => {
    onSend(initData);
    handleClose();
  };

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <Modal
      classNames={{
        modal: clsx(styles.modal, classNames.modal),
        content: clsx(styles.modal__content, classNames.content),
      }}
      show={show}
      onClose={onClose}
    >
      <h6 className={styles.title}>Sending Test Email(s) To:</h6>

      <button type="button" className={styles.close} onClick={handleClose}>
        <ExcludedIcon />
      </button>

      {Object.keys(initData)
        .filter((k) => initData[k])
        .map((k) => (
          <div key={k} className={styles.email_list__row}>
            {initData[k]}
          </div>
        ))}
      <div className={styles.form__buttons}>
        <SecondaryButton
          className={styles.form__cancel}
          label={["xs", "sm", "md"].includes(deviceSize) ? "Back" : "Cancel"}
          onClick={handleClose}
        />

        <PrimaryButton
          className={styles.form__save}
          label={"Send"}
          onClick={handleSubmit}
        />
      </div>
    </Modal>
  );
};

export default SendTestEmails;
