import React, { useRef, useState } from 'react'
import styles from './CampaignItem.module.scss'
import clsx from 'clsx'
import {
  ActionIcon,
  PencilIcon,
  EyeVisibleColorIcon,
  CopyIcon,
  RemoveIcon,
  ConfirmModal,
} from 'components'
import moment from 'moment'
import { CAMPAIGN_STATUS, PATH, STORAGE_NAME } from 'utils/constants'
import { useClickOutside, useNavigate } from 'hooks'
import { useStateValue } from 'state'
import { getStorageItem, getCampaignTypeAsText } from 'utils'

const CampaignItem = ({ className, campaign }) => {
  const campIdInUse = getStorageItem(STORAGE_NAME.CAMP_IN_USE)
  const {
    action: { deleteCampaign, closeCampaign, copyCampaign },
  } = useStateValue()
  const [isOpened, setOpened] = useState(false)
  const actionMenuRef = useRef(null)
  const navigate = useNavigate()
  const percentage = 30
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [isLoading, setLoading] = useState(false)

  useClickOutside(actionMenuRef, () => setOpened(false))

  const handleClickAction = (e) => {
    e.preventDefault()

    setOpened(true)
  }

  const handleClickEdit = () =>
    navigate({
      pathname: [CAMPAIGN_STATUS.ACTIVE, CAMPAIGN_STATUS.CLOSED].includes(
        campaign?.status
      )
        ? `${PATH.CAMPAIGNS__EDIT}/performance/${campaign.id}`
        : `${PATH.CAMPAIGNS__EDIT}/define/${campaign.id}`,
      state: { campaign },
    })

  const handleClickCopy = () =>
    campIdInUse &&
    copyCampaign(campIdInUse, campaign.id, () => setOpened(false))

  const handleClickOpenConfirm = () => {
    if (campaign.status === CAMPAIGN_STATUS.CLOSED)
      deleteCampaign(campaign.id, () => setOpened(false))
    else {
      setOpened(false)
      setShowConfirmModal(campaign.status)
    }
  }

  const handleClickConfirmClose = () => setShowConfirmModal(false)

  const handleClickConfirmOK = () => {
    setLoading(true)

    if (campaign.status === CAMPAIGN_STATUS.INCOMPLETE)
      deleteCampaign(campaign.id, () => {
        setLoading(false)
        setShowConfirmModal(false)
      })

    if (campaign.status === CAMPAIGN_STATUS.ACTIVE) {
      closeCampaign(campaign.id, () => {
        setLoading(false)
        setShowConfirmModal(false)
      })
    }
  }

  const CampaignActionMenu = () => (
    <div
      ref={actionMenuRef}
      className={clsx(
        styles.action_menu,
        isOpened && styles['action_menu--opened']
      )}
    >
      {!campaign.deleted_at && (
        <div className={styles.action_menu__item} onClick={handleClickEdit}>
          {campaign.status === CAMPAIGN_STATUS.INCOMPLETE ? (
            <PencilIcon />
          ) : (
            <EyeVisibleColorIcon />
          )}
          <span>
            {campaign.status === CAMPAIGN_STATUS.INCOMPLETE
              ? 'Continue'
              : campaign.status === CAMPAIGN_STATUS.ACTIVE
              ? 'Open'
              : 'View'}
          </span>
        </div>
      )}

      {(campaign.status === CAMPAIGN_STATUS.CLOSED ||
        campaign.status === CAMPAIGN_STATUS.ACTIVE) && (
        <div className={styles.action_menu__item} onClick={handleClickCopy}>
          <CopyIcon />
          <span>Copy to New Campaign</span>
        </div>
      )}

      {!campaign.deleted_at && (
        <div
          className={clsx(
            styles.action_menu__item,
            styles.action_menu__item__delete
          )}
          onClick={handleClickOpenConfirm}
        >
          <RemoveIcon />
          <span>
            {campaign.status === CAMPAIGN_STATUS.INCOMPLETE
              ? 'Delete'
              : campaign.status === CAMPAIGN_STATUS.ACTIVE
              ? 'Close'
              : 'Archive'}
          </span>
        </div>
      )}
    </div>
  )

  const getDateRange = () => {
    const startDay = moment(campaign.startDate)
    const endDay = moment(campaign.endDate)
    return `${startDay.format('M/D/YY')} - ${endDay.format('M/D/YY')}`
  }

  return (
    <>
      <div className={clsx(styles.campaign, className)}>
        {campaign.status === CAMPAIGN_STATUS.INCOMPLETE ? (
          <div
            className={clsx(
              styles.campaign__stats,
              styles[
                `campaign__stats--${
                  percentage >= 85
                    ? 'green'
                    : percentage >= 20
                    ? 'orange'
                    : 'red'
                }`
              ]
            )}
            style={{ '--value': percentage }}
          >{`${percentage}%`}</div>
        ) : null}

        <div
          className={clsx(
            styles.campaign__inner,
            styles[`campaign__inner--${campaign.status}`]
          )}
        >
          <div className={styles.campaign__inner__top}>
            <div className={styles.campaign__name}>{campaign.name}</div>
            <div className={styles.campaign__name}>
              {campaign.testMode ? (
                <span className={styles.campaign__name_test}>Test</span>
              ) : null}
            </div>
            <button
              type="button"
              className={styles.campaign__action_button}
              onClick={handleClickAction}
            >
              <ActionIcon />
            </button>

            <CampaignActionMenu />
          </div>

          <div className={styles.campaign__inner__bottom}>
            <span className={styles.campaign__type}>
              {getCampaignTypeAsText(campaign.type)}
            </span>

            {campaign.verified ? (
              <span className={styles['campaign__type--verified']}>
                Verified
              </span>
            ) : null}

            <span className={styles.campaign__created_at}>
              {getDateRange()}
            </span>
          </div>
        </div>
      </div>

      <ConfirmModal
        show={showConfirmModal}
        {...(showConfirmModal === CAMPAIGN_STATUS.ACTIVE
          ? { description: 'Closing this campaign will make it inactive.' }
          : {})}
        isLoading={isLoading}
        onCancel={handleClickConfirmClose}
        onOK={handleClickConfirmOK}
      />
    </>
  )
}

export default CampaignItem
