import React, { useEffect, useState } from 'react';
import styles from './AmbassadorSetupTerms.module.scss';
import { Modal, ExcludedIcon } from 'components';
import { useStateValue } from 'state';

const AmbassadorSetupTerms = ({ campaignId = null, show, onClose = () => null }) => {
  const { action: { getTermsTemplate } } = useStateValue();
  const [termsHtml, setTermsHtml] = useState(null);

  // eslint-disable-next-line
  useEffect(() => getTermsTemplate(campaignId, ({ htmlContentTemplate }) => setTermsHtml(htmlContentTemplate.text)), [campaignId]);

  return (
    <Modal
      classNames={{ modal: styles.modal, content: styles.modal__content }}
      show={show} onClose={onClose}
    >
      <h4 className={styles.title} dangerouslySetInnerHTML={{ __html: 'Terms' }} />

      <button type='button' className={styles.close} onClick={onClose}>
        <ExcludedIcon />
      </button>

      <div className={styles.terms} dangerouslySetInnerHTML={{ __html: termsHtml }} ></div>
    </Modal>
  );
};

export default AmbassadorSetupTerms;
