import globalReducer from './global';
import authReducer from './auth';
import campReducer from './camp';
import campaignReducer from './campaign';

const mainReducer = ({ isLoading, error, auth, camp, campaign }, action) => ({
  ...globalReducer({ isLoading, error }, action),
  auth: authReducer(auth, action),
  camp: campReducer(camp, action),
  campaign: campaignReducer(campaign, action)
});

export default mainReducer;