import { useNavigate } from 'hooks'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useStateValue } from 'state'
import { STORAGE_NAME, PATH } from 'utils/constants'
import { getStorageItem } from 'utils'
import { Breadcrumb, TextInput } from 'components'
import styles from './ProspectEdit.module.scss'

const ProspectEdit = () => {
  const campIdInUse = getStorageItem(STORAGE_NAME.CAMP_IN_USE)
  const [person, setPerson] = useState()
  const { id } = useParams()
  const {
    action: { getReferral },
  } = useStateValue()
  const navigate = useNavigate()

  useEffect(() => {
    if (!campIdInUse) navigate({ pathname: PATH.CAMPS })
    else if (!id) navigate({ pathname: PATH.CUSTOMERS })
    else
      getReferral(id, (person) => {
        setPerson(person)
      })

    // eslint-disable-next-line
  }, [])

  const getFullName = () => `${person.firstName} ${person.lastName}`

  if (!person) return <React.Fragment />

  return (
    <>
      <Breadcrumb
        items={[
          { to: PATH.PROSPECTS, text: 'Prospects' },
          { to: `${PATH.PROSPECTS__EDIT}/${person.id}`, text: getFullName() },
        ]}
      />

      <div className={styles.page}>
        <div className={styles.page__header}>
          <h4 className={styles.text__person__name}>{getFullName()}</h4>

          <span className={styles.text__person__type}>Prospect</span>
        </div>

        <div className={styles.section}>
          <div className={styles.section__title}>Contact Info</div>

          <div className={styles.form}>
            <div className={styles.form__row}>
              <div className={styles.form__col}>
                <TextInput
                  label="First Name"
                  placeholder="First Name"
                  value={person?.firstName}
                  readOnly={true}
                />
              </div>

              <div className={styles.form__col}>
                <TextInput
                  label="Last Name"
                  placeholder="Last Name"
                  value={person?.lastName}
                  readOnly={true}
                />
              </div>
            </div>

            <div className={styles.form__row}>
              <div className={styles.form__col}>
                <TextInput
                  label="Email"
                  placeholder="Email"
                  value={person?.email}
                  readOnly={true}
                />
              </div>

              <div className={styles.form__col}>
                <TextInput
                  label="Phone"
                  placeholder="Phone"
                  value={person?.phone}
                  readOnly={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProspectEdit
