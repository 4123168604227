import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { ConfirmModal, Steps } from 'components'
import styles from './Edit.module.scss'
import {
  Performance,
  Verification,
  DefineCampaign,
  AmbassadorSetup,
  ProspectSetup,
  LaunchCampaign,
} from 'pages/Campaigns/Step'
import { CAMPAIGN_STATUS, CAMPAIGN_STEP, PATH } from 'utils/constants'
import { useNavigate } from 'hooks'
import _ from 'lodash'
import { useStateValue } from 'state'
import { getStorageItem, setStorageItem } from 'utils'
import { DEFAULT_STEP_ITEMS } from 'components/Steps/Steps'
import ReactRouterPrompt from 'react-router-prompt'

const Edit = () => {
  const {
    action: { getCampaign },
  } = useStateValue()
  const [campaign, setCampaign] = useState({})
  const [ambassadorSetup, setAmbassadorSetup] = useState({})
  const [prospectSetup, setProspectSetup] = useState({})
  const [isLocked, setLocked] = useState(false)
  const [isLaunchLocked, setLaunchLocked] = useState(false)
  const [stepId, setStepId] = useState(CAMPAIGN_STEP.DEFINE__CAMPAIGN)
  const navigate = useNavigate()
  const { id: campaignId } = useParams()
  const [stepItems, setStepItems] = useState(DEFAULT_STEP_ITEMS)
  const { pathname } = useLocation()
  const [isStepDirty, setIsStepDirty] = useState(false)

  useEffect(() => {
    if (campaignId)
      getCampaign(campaignId, (campaign) => {
        setCampaign(campaign)
        setAmbassadorSetup(campaign.AmbassadorSetup)
        setProspectSetup(campaign.ProspectSetup)
        let isLocked = false
        if (campaign?.status === CAMPAIGN_STATUS.CLOSED) {
          isLocked = true
        } else if (campaign?.status === CAMPAIGN_STATUS.ACTIVE) {
          isLocked = true
        }
        const isLaunchLocked = [CAMPAIGN_STATUS.CLOSED].includes(
          campaign?.status
        )
        setLocked(isLocked)
        setLaunchLocked(isLaunchLocked)
      })
    else navigate({ pathname: PATH.CAMPAIGNS })
    // eslint-disable-next-line
  }, [campaignId])

  useEffect(() => {
    if (pathname.includes('define')) {
      setStepId(CAMPAIGN_STEP.DEFINE__CAMPAIGN)
      setStorageItem('step', CAMPAIGN_STEP.DEFINE__CAMPAIGN)
    } else if (pathname.includes('performance')) {
      setStepId(CAMPAIGN_STEP.PERFORMANCE)
      setStorageItem('step', CAMPAIGN_STEP.PERFORMANCE)
    } else if (pathname.includes('ambassador')) {
      setStepId(CAMPAIGN_STEP.AMBASSADOR_SETUP__INVITATIONS)
      setStorageItem('step', CAMPAIGN_STEP.AMBASSADOR_SETUP__INVITATIONS)
    } else if (pathname.includes('prospect')) {
      setStepId(CAMPAIGN_STEP.PROSPECT_SETUP__INVITATIONS)
      setStorageItem('step', CAMPAIGN_STEP.PROSPECT_SETUP__INVITATIONS)
    } else if (pathname.includes('launch')) {
      setStepId(CAMPAIGN_STEP.LAUNCH)
      setStorageItem('step', CAMPAIGN_STEP.LAUNCH)
    } else if (pathname.includes('verification')) {
      setStepId(CAMPAIGN_STEP.VERIFICATION)
      setStorageItem('step', CAMPAIGN_STEP.VERIFICATION)
    }
  }, [pathname])

  const handleBack = (e) => {
    e.preventDefault()
    window.scrollTo({ top: 0 })
    if (stepId.startsWith(CAMPAIGN_STEP.AMBASSADOR_SETUP)) {
      navigate({
        pathname: `${
          stepItems.find((step) => step.id === CAMPAIGN_STEP.DEFINE).route
        }${campaignId}`,
      })
    } else if (stepId.startsWith(CAMPAIGN_STEP.PROSPECT_SETUP)) {
      navigate({
        pathname: `${
          stepItems.find((step) => step.id === CAMPAIGN_STEP.AMBASSADOR_SETUP)
            .route
        }${campaignId}`,
      })
    } else if (stepId.startsWith(CAMPAIGN_STEP.LAUNCH)) {
      navigate({
        pathname: `${
          stepItems.find((step) => step.id === CAMPAIGN_STEP.PROSPECT_SETUP)
            .route
        }${campaignId}`,
      })
    } else if (isLocked) {
      if (stepId.startsWith(CAMPAIGN_STEP.DEFINE)) {
        navigate({
          pathname: `${
            stepItems.find((step) => step.id === CAMPAIGN_STEP.VERIFICATION)
              .route
          }${campaignId}`,
        })
      } else if (stepId === CAMPAIGN_STEP.VERIFICATION) {
        navigate({
          pathname: `${
            stepItems.find((step) => step.id === CAMPAIGN_STEP.PERFORMANCE)
              .route
          }${campaignId}`,
        })
      }
    } else navigate({ pathname: PATH.CAMPAIGNS })
  }

  const handleNext = (args) => {
    window.scrollTo({ top: 0 })
    setIsStepDirty(false)
    if (stepId.startsWith(CAMPAIGN_STEP.DEFINE)) {
      if (args) {
        setCampaign((campaign) => ({ ...campaign, ...args }))
        navigate({
          pathname: `${
            stepItems.find((step) => step.id === CAMPAIGN_STEP.AMBASSADOR_SETUP)
              .route
          }${campaignId}`,
        })
      }
    } else if (stepId.startsWith(CAMPAIGN_STEP.AMBASSADOR_SETUP)) {
      if (args) {
        setAmbassadorSetup(args)
        navigate({
          pathname: `${
            stepItems.find((step) => step.id === CAMPAIGN_STEP.PROSPECT_SETUP)
              .route
          }${campaignId}`,
        })
      }
    } else if (stepId.startsWith(CAMPAIGN_STEP.PROSPECT_SETUP)) {
      if (args) {
        setProspectSetup(args)
        navigate({
          pathname: `${
            stepItems.find((step) => step.id === CAMPAIGN_STEP.LAUNCH).route
          }${campaignId}`,
        })
      }
    } else if (stepId.startsWith(CAMPAIGN_STEP.LAUNCH)) {
    } else if (isLocked) {
      if (stepId === CAMPAIGN_STEP.PERFORMANCE) {
        navigate({
          pathname: `${
            stepItems.find((step) => step.id === CAMPAIGN_STEP.VERIFICATION)
              .route
          }${campaignId}`,
        })
      } else if (stepId === CAMPAIGN_STEP.VERIFICATION) {
        navigate({
          pathname: `${
            stepItems.find((step) => step.id === CAMPAIGN_STEP.DEFINE).route
          }${campaignId}`,
        })
      }
    } else navigate({ pathname: PATH.CAMPAIGNS })
  }

  return (
    <div className={styles.page}>
      <ReactRouterPrompt when={() => isStepDirty}>
        {({ isActive, onConfirm, onCancel }) =>
          isActive && (
            <ConfirmModal
              show={true}
              title="Unsaved Changes"
              description="You have unsaved changes. Do you want to discard them and leave?"
              onCancel={onCancel}
              onOK={() => {
                setIsStepDirty(false)
                onConfirm()
              }}
              okLabel="Discard"
            />
          )
        }
      </ReactRouterPrompt>
      <Steps
        className={styles.left}
        stepId={stepId}
        campaign={campaign}
        activeCampaign={campaign?.status === 'ACTIVE' && !campaign?.testMode}
        testCampaign={campaign?.status === 'ACTIVE' && campaign?.testMode}
        onChangeStep={({ id, route }) => {
          if (!isStepDirty || id.split('__')[0] === stepId.split('__')[0])
            setStepId(id)
          if (route) {
            navigate({ pathname: `${route}${campaignId}` })
          }
        }}
        setSteps={setStepItems}
      />

      <div className={styles.right}>
        {stepId === CAMPAIGN_STEP.PERFORMANCE ? (
          <Performance
            isLocked={isLocked}
            onNext={handleNext}
            campaignId={campaignId}
            activeCampaign={
              campaign?.status === 'ACTIVE' && !campaign?.testMode
            }
            hideWarning={getStorageItem('step')}
          />
        ) : stepId === CAMPAIGN_STEP.VERIFICATION ? (
          <Verification
            isLocked={isLocked}
            onBack={handleBack}
            onNext={handleNext}
            campaignId={campaignId}
            campaign={campaign}
          />
        ) : stepId.startsWith(CAMPAIGN_STEP.DEFINE) ? (
          <DefineCampaign
            isLocked={isLocked}
            step={stepId}
            onBack={handleBack}
            onNext={handleNext}
            onSave={(args) => {
              setCampaign((campaign) => ({ ...campaign, ...args }))
              setIsStepDirty(false)
            }}
            data={_.omit(campaign, ['AmbassadorSetup', 'ProspectSetup'])}
            activeCampagin={campaign?.status === CAMPAIGN_STATUS.ACTIVE}
            campaign={campaign}
            setDirty={setIsStepDirty}
            saveAndClose={() => {
              setIsStepDirty(false)
              setTimeout(() => {
                navigate({ pathname: PATH.CAMPAIGNS })
              }, 100)
            }}
          />
        ) : stepId.startsWith(CAMPAIGN_STEP.AMBASSADOR_SETUP) ? (
          <AmbassadorSetup
            isLocked={false}
            step={stepId}
            onBack={handleBack}
            onNext={handleNext}
            onSave={(args) => {
              setAmbassadorSetup(args)
              setIsStepDirty(false)
            }}
            data={ambassadorSetup}
            campaignId={campaignId}
            campaignSlug={campaign?.slug}
            setDirty={setIsStepDirty}
            saveAndClose={() => {
              setIsStepDirty(false)
              setTimeout(() => {
                navigate({ pathname: PATH.CAMPAIGNS })
              }, 100)
            }}
          />
        ) : stepId.startsWith(CAMPAIGN_STEP.PROSPECT_SETUP) ? (
          <ProspectSetup
            isLocked={false}
            step={stepId}
            onBack={handleBack}
            onNext={handleNext}
            onSave={(args) => {
              setProspectSetup(args)
              setIsStepDirty(false)
            }}
            data={{
              ...prospectSetup,
              landingBgImgURL: ambassadorSetup.landingBgImgURL,
            }}
            campaignId={campaignId}
            campaignSlug={campaign.slug}
            promocode={
              campaign && campaign.ProspectRewards
                ? campaign.ProspectRewards[0].promocode
                : ''
            }
            setDirty={setIsStepDirty}
            saveAndClose={() => {
              setIsStepDirty(false)
              setTimeout(() => {
                navigate({ pathname: PATH.CAMPAIGNS })
              }, 100)
            }}
          />
        ) : stepId.startsWith(CAMPAIGN_STEP.LAUNCH) ? (
          <LaunchCampaign
            isLocked={isLaunchLocked}
            step={stepId}
            onBack={handleBack}
            onNext={handleNext}
            emailData={{
              emailSubject: ambassadorSetup?.emailSubject,
              emailHeading: ambassadorSetup?.emailHeading,
              emailSubheading: ambassadorSetup?.emailSubheading,
              emailContent: ambassadorSetup?.emailContent,
            }}
            campaignId={campaignId}
            campaignType={campaign?.type}
            status={campaign?.status}
            testMode={campaign?.testMode}
            startDate={campaign?.startDate}
            endDate={campaign?.endDate}
            campaignSlug={campaign?.slug}
          />
        ) : null}
      </div>
    </div>
  )
}

export default Edit
