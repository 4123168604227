import React, { useRef, useState } from 'react'
import { useFormik } from 'formik'
import styles from './ProspectLdg.module.scss'
import { getTextInitials } from 'utils'
import { useStateValue } from 'state'
import {
  PrimaryButton,
  SecondaryButton,
  TextInput,
  LogoIcon,
  PSLearnMoreModal,
  PSPromoCodeModal,
  ChevronArrowIcon,
} from 'components'
import * as Yup from 'yup'
import clsx from 'clsx'

const ProspectLdg = ({
  landingBgImgURL,
  landingHeading,
  promocode,
  landingSubheading,
  linkToWebsite,
  linkToSchedule,
  linkToVideo,
  linkToPricingInfo,
  link1,
  link2,
  link3,
  link4,
  learnMoreSubheading,
  learnMorePopupHeading,
  learnMorePopupBtnTxt,
  enrollPopupHeading,
  enrollPopupBtnTxt,
  learnMoreBtnTxt,
  enrollSubheading,
  enrollBtnTxt,
  linkToRegistrationPage,
  enrollInstructions,
  camp = {},
  onSubmitLM = () => null,
  onSubmitE = () => null,
}) => {
  const {
    state: { deviceSize },
  } = useStateValue()
  const isSmall = () => ['sm', 'xs'].includes(deviceSize)

  const [showLearnMore, setShowLearnMore] = useState(false)
  const [learnMoreArgs, setLearnMoreArgs] = useState({})

  const [expandLearnMore, setExpandLearnMore] = useState(!isSmall())
  const [showPromoCode, setShowPromoCode] = useState(false)
  const [expandPromoCode, setExpandPromoCode] = useState(!isSmall())

  const learnMoreFormRef = useRef(null)
  const enrollFormRef = useRef(null)

  // Learn More formik
  const formikLM = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      // ...initPerson,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First Name is required.'),
      lastName: Yup.string().required('Last Name is required.'),
      email: Yup.string()
        .required('Email is required.')
        .email('Please enter a valid email.'),
      phone: Yup.string()
        .matches(
          /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
          'Please enter a valid phone number.'
        )
        .nullable(),
    }),
    onSubmit: (args) => {
      setLearnMoreArgs(args)
      if (!isSmall()) {
        setShowLearnMore(true)
        onSubmitLM(args)
      } else if (!expandLearnMore) {
        setExpandPromoCode(false)
        setExpandLearnMore(true)
        learnMoreFormRef.current.scrollIntoView({ behavior: 'smooth' })
      } else {
        setShowLearnMore(true)
        setExpandLearnMore(false)
        onSubmitLM(args)
      }
    },
  })

  // Enroll formik
  const formikE = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      // ...initPerson,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First Name is required.'),
      lastName: Yup.string().required('Last Name is required.'),
      email: Yup.string()
        .required('Email is required.')
        .email('Please enter a valid email.'),
      phone: Yup.string()
        .matches(
          /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
          'Please enter a valid phone number.'
        )
        .nullable(),
    }),
    onSubmit: (args) => {
      if (!isSmall()) {
        setShowPromoCode(true)
        onSubmitE(args)
      } else if (!expandPromoCode) {
        setExpandLearnMore(false)
        setExpandPromoCode(true)
        enrollFormRef.current.scrollIntoView({ behavior: 'smooth' })
      } else {
        setShowPromoCode(true)
        setExpandPromoCode(false)
        onSubmitE(args)
      }
    },
  })
  return (
    <>
      <div className={styles.page}>
        <div
          className={styles.page__background}
          style={{ backgroundImage: `url("${landingBgImgURL}")` }}
        />
        <div className={styles.header}>
          <img
            className={styles.header__img}
            src={landingBgImgURL}
            alt={`${camp.name} scenery`}
          />
          <div className={styles.header__brand}>
            <div className={styles.header__logo}>
              {camp.logo ? (
                <img src={camp.logo} alt={camp.name} />
              ) : (
                <span>{getTextInitials(camp.name)}</span>
              )}
            </div>

            <h6 className={styles.header__camp_name}>{camp.name}</h6>
          </div>

          <SecondaryButton
            className={styles.header__visit}
            label="Visit Website"
            onClick={() => window.open(camp.website, '_blank').focus()}
          />
        </div>

        <div className={styles.main__wrapper}>
          <div className={styles.main}>
            <div className={styles.info}>
              <div className={styles.info__logo}>
                {camp.logo ? (
                  <img src={camp.logo} alt={camp.name} />
                ) : (
                  <span>{getTextInitials(camp.name)}</span>
                )}
              </div>

              <div className={styles.info__text}>
                <h5>{landingHeading}</h5>
                <p>{landingSubheading}</p>
              </div>
            </div>

            <div className={styles.main__inner}>
              <form
                className={clsx(styles.form, styles['form--orange'])}
                ref={learnMoreFormRef}
              >
                <ChevronArrowIcon
                  className={clsx(
                    styles.form__arrow,
                    !expandLearnMore && styles['form__arrow--down']
                  )}
                  onClick={(e) => {
                    setExpandLearnMore(!expandLearnMore)
                    learnMoreFormRef.current?.scrollIntoView({
                      behavior: 'smooth',
                    })
                  }}
                />
                <div className={styles.form__texts}>
                  <label
                    className={clsx(
                      styles.form__title,
                      styles['form__title--orange']
                    )}
                  >
                    Want to learn More?
                  </label>
                  <p className={styles.form__desc}>{learnMoreSubheading}</p>
                </div>

                <fieldset
                  className={clsx(
                    styles.form__fieldset,
                    !expandLearnMore &&
                      isSmall() &&
                      styles['form__fieldset--hidden']
                  )}
                >
                  <TextInput
                    label="First Name"
                    placeholder="First Name"
                    value={formikLM.values.firstName}
                    onChange={(firstName) =>
                      formikLM.setFieldValue('firstName', firstName)
                    }
                    requiredShown
                    {...(formikLM.touched.firstName && formikLM.errors.firstName
                      ? {
                          isError: true,
                          captionText: formikLM.errors.firstName,
                        }
                      : {})}
                  />

                  <TextInput
                    label="Last Name"
                    placeholder="Last Name"
                    value={formikLM.values.lastName}
                    onChange={(lastName) =>
                      formikLM.setFieldValue('lastName', lastName)
                    }
                    requiredShown
                    {...(formikLM.touched.lastName && formikLM.errors.lastName
                      ? { isError: true, captionText: formikLM.errors.lastName }
                      : {})}
                  />

                  <TextInput
                    label="Email"
                    placeholder="Email"
                    value={formikLM.values.email}
                    onChange={(email) => formikLM.setFieldValue('email', email)}
                    requiredShown
                    {...(formikLM.touched.email && formikLM.errors.email
                      ? { isError: true, captionText: formikLM.errors.email }
                      : {})}
                  />

                  <TextInput
                    label="Phone (Optional)"
                    placeholder="Phone"
                    value={formikLM.values.phone}
                    onChange={(phone) => formikLM.setFieldValue('phone', phone)}
                    {...(formikLM.touched.phone && formikLM.errors.phone
                      ? { isError: true, captionText: formikLM.errors.phone }
                      : {})}
                  />
                </fieldset>

                <div className={styles.form__button__wrapper}>
                  <PrimaryButton
                    className={styles.form__button}
                    label={
                      isSmall() && expandLearnMore ? 'Submit' : learnMoreBtnTxt
                    }
                    onClick={(e) => {
                      if (!expandLearnMore) {
                        setExpandLearnMore(true)
                        e.preventDefault()
                        return
                      }
                      learnMoreFormRef.current.scrollIntoView({
                        behavior: 'smooth',
                      })
                      formikLM.handleSubmit(e)
                    }}
                  />
                </div>
              </form>

              <form
                ref={enrollFormRef}
                className={clsx(styles.form, styles['form--green'])}
              >
                <ChevronArrowIcon
                  className={clsx(
                    styles.form__arrow,
                    !expandPromoCode && styles['form__arrow--down']
                  )}
                  onClick={() => {
                    setExpandPromoCode(!expandPromoCode)
                    enrollFormRef.current?.scrollIntoView({
                      behavior: 'smooth',
                    })
                  }}
                />
                <div className={styles.form__texts}>
                  <label
                    className={clsx(
                      styles.form__title,
                      styles['form__title--green']
                    )}
                  >
                    Ready to Join Us?
                  </label>

                  <p className={styles.form__desc}>{enrollSubheading}</p>
                </div>

                <fieldset
                  className={clsx(
                    styles.form__fieldset,
                    !expandPromoCode &&
                      isSmall() &&
                      styles['form__fieldset--hidden']
                  )}
                >
                  <TextInput
                    label="First Name"
                    placeholder="First Name"
                    value={formikE.values.firstName}
                    onChange={(firstName) =>
                      formikE.setFieldValue('firstName', firstName)
                    }
                    requiredShown
                    {...(formikE.touched.firstName && formikE.errors.firstName
                      ? { isError: true, captionText: formikE.errors.firstName }
                      : {})}
                  />

                  <TextInput
                    label="Last Name"
                    placeholder="Last Name"
                    value={formikE.values.lastName}
                    onChange={(lastName) =>
                      formikE.setFieldValue('lastName', lastName)
                    }
                    requiredShown
                    {...(formikE.touched.lastName && formikE.errors.lastName
                      ? { isError: true, captionText: formikE.errors.lastName }
                      : {})}
                  />

                  <TextInput
                    label="Email"
                    placeholder="Email"
                    value={formikE.values.email}
                    onChange={(email) => formikE.setFieldValue('email', email)}
                    requiredShown
                    {...(formikE.touched.email && formikE.errors.email
                      ? { isError: true, captionText: formikE.errors.email }
                      : {})}
                  />

                  <TextInput
                    label="Phone (Optional)"
                    placeholder="Phone"
                    value={formikE.values.phone}
                    onChange={(phone) => formikE.setFieldValue('phone', phone)}
                    {...(formikE.touched.phone && formikE.errors.phone
                      ? { isError: true, captionText: formikE.errors.phone }
                      : {})}
                  />
                </fieldset>

                <div className={styles.form__button__wrapper}>
                  <PrimaryButton
                    className={[
                      styles.form__button,
                      styles['form__button--green'],
                    ]}
                    label={
                      isSmall() && expandPromoCode ? 'Submit' : enrollBtnTxt
                    }
                    onClick={(e) => {
                      if (!expandPromoCode) {
                        setExpandPromoCode(true)
                        e.preventDefault()
                        return
                      }
                      enrollFormRef.current.scrollIntoView({
                        behavior: 'smooth',
                      })
                      formikE.handleSubmit(e)
                    }}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className={styles.powered_by}>
          <span>Powered by</span>
          <LogoIcon className={styles.powered_by__LogoIcon} />
        </div>
      </div>

      <PSLearnMoreModal
        show={showLearnMore}
        linkToWebsite={linkToWebsite}
        linkToSchedule={linkToSchedule}
        linkToVideo={linkToVideo}
        linkToPricingInfo={linkToPricingInfo}
        camp={camp}
        link1={link1}
        link2={link2}
        link3={link3}
        link4={link4}
        learnMorePopupHeading={learnMorePopupHeading}
        learnMorePopupBtnTxt={learnMorePopupBtnTxt}
        onClose={() => setShowLearnMore(false)}
        onClickRegister={() => {
          setShowLearnMore(false)
          setShowPromoCode(true)
          onSubmitE(learnMoreArgs)
        }}
      />

      <PSPromoCodeModal
        show={showPromoCode}
        linkToRegistrationPage={linkToRegistrationPage}
        enrollInstructions={enrollInstructions}
        promocode={promocode}
        enrollPopupBtnTxt={enrollPopupBtnTxt}
        enrollPopupHeading={enrollPopupHeading}
        camp={camp}
        onClose={() => setShowPromoCode(false)}
      />
    </>
  )
}

export default ProspectLdg
