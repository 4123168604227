import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import styles from './AmbassadorReward.module.scss'
import {
  TextInput,
  Select,
  GiftIcon,
  ImagePlaceholderFillIcon,
  MoneyDollarIcon,
  MoneyPercentageIcon,
  CheckMarkIcon,
  RemoveIcon,
  TextSpan,
} from 'components'
import {
  REWARD__AR_TYPE,
  REWARD__AR__ITEM,
  REWARD__AR__USE_OPTIONS,
} from 'utils/constants'
import _ from 'lodash'
import { getFormikCaptionTextData, oneMbSizeLimit } from 'utils'

const AmbassadorReward = ({
  className,
  reward: {
    id,
    type: initType,
    imgURL: initImgURL,
    for: initFor,
    desc: initDesc,
    giftDesc: initGiftDesc,
    use: initUse,
    amount: initAmount,
    promocode: initPromocode,
  } = REWARD__AR__ITEM,
  disabled = false,
  camp = {},
  rIndex = 0,
  error: { desc: descError, amount: amountError } = {},
  onClose = () => null,
  onChange = () => null,
  formik,
}) => {
  const getImgFileNameFromURL = (imgURL) => {
    if (!imgURL) return ''

    if (typeof imgURL === 'string') return _.last(imgURL.split('/'))

    if (typeof imgURL === 'object') return imgURL.name

    return ''
  }

  const fileRef = useRef(null)
  const [type, setType] = useState(initType)
  const [isImgUploadable, setImgUploadable] = useState(!!initImgURL)
  const [imgURL, setImgURL] = useState(initImgURL)
  const [imgFilename, setImgFilename] = useState(
    getImgFileNameFromURL(initImgURL)
  )
  const [_for, setFor] = useState(initFor)
  const [desc, setDesc] = useState(initDesc)
  const [giftDesc, setGiftDesc] = useState(initGiftDesc)
  const [use, setUse] = useState(initUse)
  const [amount, setAmount] = useState(initAmount)
  const [promocode, setPromocode] = useState(initPromocode)
  const [isPromoCode, setIsPromoCode] = useState(!!initPromocode)

  useEffect(() => {
    if (type !== initType) setType(initType)
    if (imgURL !== initImgURL) {
      setImgUploadable(!!initImgURL)
      setImgFilename(getImgFileNameFromURL(initImgURL))
      setImgURL(initImgURL)
    }
    if (_for !== initFor) setFor(initFor)
    if (desc !== initDesc) setDesc(initDesc)
    if (giftDesc !== initGiftDesc) setGiftDesc(initGiftDesc)
    if (use !== initUse) setUse(initUse)
    if (amount !== initAmount) setAmount(initAmount)
    if (promocode !== initPromocode) {
      setIsPromoCode(!!initPromocode)
      setPromocode(initPromocode)
    }
    // eslint-disable-next-line
  }, [
    initType,
    initImgURL,
    initFor,
    initDesc,
    initGiftDesc,
    initUse,
    initAmount,
    initPromocode,
  ])

  useEffect(() => {
    onChange({
      id,
      type,
      imgURL: isImgUploadable ? imgURL : null,
      for: _for,
      desc,
      giftDesc: type === REWARD__AR_TYPE.GIFT ? giftDesc : null,
      use,
      amount,
      promocode: isPromoCode ? promocode : null,
    })
    // eslint-disable-next-line
  }, [
    type,
    isImgUploadable,
    imgURL,
    _for,
    desc,
    giftDesc,
    use,
    amount,
    promocode,
    isPromoCode,
  ])

  const handleClickClose = (e) => {
    e.preventDefault()
    onClose()
  }

  const handleAddImage = ({ target: { files } }) => {
    if (!files[0]) return

    if (oneMbSizeLimit(files[0])) {
      setImgFilename(files[0].name)
      setImgURL(files[0])
    }
  }

  return (
    <div className={clsx(className, styles.ambassador_reward)}>
      <div className={styles.title}>
        Reward #{rIndex + 1}
        {rIndex ? (
          <button
            type="button"
            className={styles.button__close}
            onClick={handleClickClose}
          >
            <RemoveIcon />
          </button>
        ) : null}
      </div>

      <div className={styles.field__types__wrapper}>
        {[
          {
            value: REWARD__AR_TYPE.GIFT,
            text: 'Gift',
            Icon: GiftIcon,
          },
          {
            value: REWARD__AR_TYPE.MONEY_DOLLAR,
            text: `Money (${camp && camp.currency})`,
            Icon: MoneyDollarIcon,
          },
          {
            value: REWARD__AR_TYPE.MONEY_PERCENTAGE,
            text: 'Money (%)',
            Icon: MoneyPercentageIcon,
          },
        ].map(({ value, text, Icon }, tIndex) => (
          <div
            className={clsx(
              styles.field__type,
              type === value && styles['field__type--selected'],
              disabled && styles['field__type--disabled']
            )}
            onClick={() => {
              if (!disabled) {
                setType(value)
                if (value === 'PERCENT' && amount > 100) setAmount(100)
              }
            }}
            key={tIndex}
          >
            <span
              className={clsx(
                styles.icon__check_box,
                type === value && styles['icon__check_box--checked'],
                disabled && styles['icon__check_box--disabled']
              )}
            >
              {type === value ? <CheckMarkIcon /> : null}
            </span>
            <span
              className={clsx(
                styles.field__type__text,
                type === value && styles['field__type__text--selected'],
                disabled && styles['field__type__text--disabled']
              )}
            >
              {text}
            </span>
            <Icon className={styles.field__type__icon} />
          </div>
        ))}
      </div>

      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.row}>
            <div className={styles.col}>
              <div
                className={styles.field__is_upload}
                onClick={() => setImgUploadable(!isImgUploadable)}
              >
                <span
                  className={clsx(
                    styles.icon__check_box,
                    isImgUploadable && styles['icon__check_box--checked'],
                    disabled && styles['icon__check_box--disabled']
                  )}
                >
                  {isImgUploadable ? <CheckMarkIcon /> : null}
                </span>
                <span
                  className={clsx(
                    styles.field__is_upload__text,
                    disabled && styles['field__is_upload__text--disabled']
                  )}
                >
                  Reward Image
                </span>
              </div>
            </div>
            <div className={styles.col}>
              <div
                className={styles.field__is_upload}
                onClick={() => setIsPromoCode(!isPromoCode)}
              >
                <span
                  className={clsx(
                    styles.icon__check_box,
                    isPromoCode && styles['icon__check_box--checked'],
                    disabled && styles['icon__check_box--disabled']
                  )}
                >
                  {isPromoCode ? <CheckMarkIcon /> : null}
                </span>
                <span
                  className={clsx(
                    styles.field__is_upload__text,
                    disabled && styles['field__is_upload__text--disabled']
                  )}
                >
                  Promo Code
                </span>
              </div>
            </div>
          </div>

          {/* <div className={styles.field__for}>
            <Select
              label='Reward Type'
              disabled={disabled}
              options={REWARD__AR__FOR_OPTIONS}
              value={_for}
              onChange={setFor}
            />
            <TextSpan label="Reward Type" span="Registration" />
          </div> */}

          <div className={styles.field__desc}>
            <TextInput
              label="Reward Description"
              disabled={disabled}
              value={desc}
              placeholder="Description"
              onChange={setDesc}
              isError={descError}
              captionText={descError}
              {...getFormikCaptionTextData(formik, 'desc', {
                captionText: `${formik.values.ambassadorRewards[rIndex].desc.length}/50`,
              })}
            />
          </div>
          <div
            className={clsx(
              type === REWARD__AR_TYPE.GIFT && styles['col--reversed']
            )}
          >
            <div className={styles.row}>
              <div className={styles.col}>
                <div className={styles.field__amount}>
                  <span
                    className={clsx(
                      styles.field__amount__unit,
                      disabled && styles['field__amount__unit--disabled'],
                      !amount && styles['field__amount__unit--placeholder']
                    )}
                  >
                    {type === REWARD__AR_TYPE.MONEY_PERCENTAGE
                      ? '%'
                      : camp && camp.currency}
                  </span>

                  <TextInput
                    classNames={{
                      input: isPromoCode
                        ? styles.field__amount__input_promo
                        : styles.field__amount__input,
                    }}
                    label={
                      type === REWARD__AR_TYPE.GIFT
                        ? 'Estimated Value'
                        : 'Amount'
                    }
                    disabled={disabled}
                    type="number"
                    value={amount}
                    placeholder={
                      type === REWARD__AR_TYPE.MONEY_PERCENTAGE ? '10' : '100'
                    }
                    onChange={setAmount}
                    isError={amountError}
                    captionText={'Must be a numeric amount'}
                    max={
                      type === REWARD__AR_TYPE.MONEY_PERCENTAGE ? '100' : null
                    }
                    min={0}
                  />
                </div>
              </div>
              {isPromoCode && (
                <div className={styles.col}>
                  <div className={styles.field__promocode}>
                    <TextInput
                      classNames={{ input: styles.field__promocode__input }}
                      label="Promo Code"
                      disabled={disabled}
                      value={promocode}
                      onChange={setPromocode}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          {type === REWARD__AR_TYPE.GIFT ? (
            <div className={styles.field__gift_desc}>
              <TextInput
                label="Gift Description"
                disabled={disabled}
                value={giftDesc}
                placeholder="Description"
                onChange={setGiftDesc}
              />
            </div>
          ) : null}
        </div>

        <div className={styles.col}>
          {isImgUploadable ? (
            <div
              className={styles.field__image}
              onClick={() => !disabled && fileRef.current.click()}
            >
              <input
                ref={fileRef}
                type="file"
                accept="image/*"
                hidden
                onChange={handleAddImage}
              />

              <div className={styles.field__image__preview}>
                {imgURL ? (
                  <img
                    src={
                      typeof imgURL === 'string'
                        ? imgURL
                        : URL.createObjectURL(imgURL)
                    }
                    alt={imgFilename}
                  />
                ) : (
                  <ImagePlaceholderFillIcon />
                )}
              </div>

              <div className={styles.field__image__info}>
                <span className={styles.field__image__info__filename}>
                  {imgFilename}
                </span>
                <span
                  className={clsx(
                    styles.field__image__info__text,
                    disabled && styles['field__image__info__text--disabled']
                  )}
                >
                  Upload Photo
                </span>
                <span className={clsx(styles.field__image__info__img_limit)}>
                  (Image max size - 1mb)
                </span>
              </div>
            </div>
          ) : (
            <div className={styles.field__gap} />
          )}
          <div className={styles.col}>
            <div className={styles.field__use}>
              <Select
                label="Use"
                disabled={disabled}
                options={REWARD__AR__USE_OPTIONS}
                value={use}
                onChange={setUse}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AmbassadorReward
