import { useEffect } from 'react'
import { useNavigate as useHistory, useLocation } from 'react-router-dom'

export const useNavigate = () => {
  const history = useHistory()
  const { pathname } = useLocation()

  useEffect(() => window.scrollTo(0, 0), [pathname])

  return ({ pathname, state = {} }) => history(pathname, { state })
}
