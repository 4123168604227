import { getAxiosInstance, getStorageItem, handleError } from 'utils'
import { ACTION, STORAGE_NAME } from 'utils/constants'

export const sendTestAmbassadorInvitations =
  (dispatch) =>
  async (
    {
      campaignId,
      emailSubject,
      emailHeading,
      emailSubheading,
      emailContent,
      emails,
    },
    callback = () => null
  ) => {
    try {
      dispatch({ type: ACTION.START })

      const campId = getStorageItem(STORAGE_NAME.CAMP_IN_USE)

      const response = await getAxiosInstance().post(
        `/api/test/${campaignId}/ambassador-invitation`,
        {
          emailSubject,
          emailHeading,
          emailSubheading,
          emailContent,
          emails,
          campId,
        }
      )

      const { success, code, message } = response.data

      if (success) callback()
      else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const uploadCampaignCsvFile =
  (dispatch) =>
  async (id, file, personType, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const formData = new FormData()
      formData.append('file', file)

      const response = await getAxiosInstance().post(
        `/api/campaigns/${id}/csv/${personType}`,
        formData
      )

      const { success, ambassadors, code, message } = response.data
      ambassadors.forEach((ambassador) => {
        ambassador.fullName =
          ambassador.Person.firstName + ' ' + ambassador.Person.lastName
      })
      if (success) callback(ambassadors)
      else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const getCampaignAmbassadors =
  (dispatch) =>
  async (id, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().get(
        `/api/campaigns/${id}/ambassadors`
      )

      const { success, ambassadors, code, message } = response.data
      ambassadors.forEach((ambassador) => {
        ambassador.fullName =
          ambassador.Person.firstName + ' ' + ambassador.Person.lastName
      })
      if (success) callback(ambassadors)
      else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const getAllCampaignAmbassadors =
  (dispatch) =>
  async (id, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().get(
        `/api/campaigns/${id}/ambassadors?deleted=true`
      )

      const { success, ambassadors, code, message } = response.data
      ambassadors.forEach((ambassador) => {
        ambassador.fullName =
          ambassador.Person.firstName + ' ' + ambassador.Person.lastName
      })
      if (success) callback(ambassadors)
      else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const updateCampaignAmbassador =
  (dispatch) =>
  async (id, data, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().put(
        `/api/campaigns/${id}/ambassadors/${data.personId}`,
        data
      )

      const { success, ambassador, code, message } = response.data
      ambassador.fullName =
        ambassador.Person.firstName + ' ' + ambassador.Person.lastName

      if (success) callback(ambassador)
      else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const addCampaignAmbassador =
  (dispatch) =>
  async (id, data, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().post(
        `/api/campaigns/${id}/ambassadors`,
        data
      )

      const { success, ambassador, code, message } = response.data
      if (success && ambassador) {
        ambassador.fullName = ambassador.firstName + ' ' + ambassador.lastName
        callback(ambassador)
      } else if (success && !ambassador) {
        callback()
      } else {
        throw new Error(JSON.stringify({ code, message }))
      }
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const deleteCampaignAmbassadors =
  (dispatch) =>
  async (id, ids, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().delete(
        `/api/campaigns/${id}/ambassadors`,
        { params: { ids } }
      )

      const { success, result, code, message } = response.data

      if (success) callback(result)
      else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }
