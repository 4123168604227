import _ from 'lodash';

export const getTextInitials = text => {
  const texts = text.split(' ').filter(str => !!str);

  if (texts.length > 1) return `${_.first(texts)[0]}${_.last(texts)[0]}`;

  if (texts.length) return _.first(texts)[0];

  return '';
};

export const getCapitalizedText = text => _.capitalize(text);