import React, { useState, useRef, useEffect } from 'react'
import clsx from 'clsx'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import styles from './DatePicker.module.scss'
import { useClickOutside } from 'hooks'
import 'react-datepicker/dist/react-datepicker.css'

const CTDatePicker = ({
  classNames = { container: '', label: '', button: '' },
  label = null,
  captionText = null,
  isError = false,
  requiredShown = false,
  optionalShown = false,
  onChange = () => null,
  date: initDate = null,
  dateFormat = 'MM/DD/YYYY',
}) => {
  const datePickerRef = useRef(null)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [date, setDate] = useState(initDate)

  useClickOutside(datePickerRef, () => {
    setShowDatePicker(false)
  })

  // eslint-disable-next-line
  useEffect(
    () => (initDate !== date ? setDate(new Date(initDate)) : null),
    [initDate]
  )

  const handleChangeDate = (date) => {
    onChange(date)
    setShowDatePicker(false)
  }

  return (
    <div className={clsx(classNames.container, styles.date_range)}>
      {label ? (
        <label className={clsx(classNames.label, styles.date_range__label)}>
          {label === ' ' ? <>&nbsp;</> : label}
          {requiredShown ? (
            <span className={styles.date_range__required}>&nbsp;*</span>
          ) : null}
          {optionalShown ? (
            <span className={styles.text_input__optional}>
              &nbsp;(optional)
            </span>
          ) : null}
        </label>
      ) : null}

      <button
        type="button"
        className={clsx(
          classNames.button,
          styles.date_range__button,
          isError && styles[`date_range__button--error`]
        )}
        onClick={(e) => {
          e.preventDefault()
          setShowDatePicker(!showDatePicker)
        }}
      >
        <span>
          {date ? moment(date).format(dateFormat).toString() : 'Pay Date'}
        </span>
      </button>

      <p className={clsx(styles.caption, isError && styles.caption__error)}>
        {captionText ? <span>{captionText}</span> : null}
      </p>

      {showDatePicker ? (
        <div ref={datePickerRef} className={styles.date_picker}>
          <DatePicker selected={date} onChange={handleChangeDate} inline />
        </div>
      ) : null}
    </div>
  )
}

export default CTDatePicker
