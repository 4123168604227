import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PATH } from 'utils/constants';
import './404.css';

const Page404 = () => {
  useEffect(() => {
    document.querySelector('.cont_principal').className = "cont_principal";

    setTimeout(() => {
      document.querySelector('.cont_principal').className = "cont_principal cont_error_active";
    }, 100);
  }, []);

  return (
    <div className="page-404">
      <div className="cont_principal">
        <div className="cont_error">
          <h1>Oops</h1>
          <p>The Page you're looking for isn't here. <Link to={PATH.HOME} dangerouslySetInnerHTML={{ __html: 'Go To Home' }} /></p>
        </div>
        <div className="cont_aura_1"></div>
        <div className="cont_aura_2"></div>
      </div>
    </div>
  );
};

export default Page404;
