import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateValue } from 'state';
import styles from './Stats.module.scss';
import StatItem from './StatItem';
import { Breadcrumb, PDFIcon, SecondaryButton, PrimaryButton } from 'components';
import _ from 'lodash';
import { PATH } from 'utils/constants';

const Stats = () => {
  const { state: { camp: { activeCamp, otherCamps } } } = useStateValue();
  const history = useNavigate();

  return (
    <>
      <Breadcrumb
        items={[
          { to: PATH.CAMPS, text: 'My Camps' },
          { to: PATH.CAMPS__STATS, text: 'Stats' }
        ]}
      />

      <div className={styles.legend}>
        <div className={styles.legend__symbol}>
          <span className={styles['legend__symbol--green']} />
          <label>Campaigns</label>
        </div>

        <div className={styles.legend__symbol}>
          <span className={styles['legend__symbol--orange']} />
          <label>Ampassadors</label>
        </div>

        <div className={styles.legend__symbol}>
          <span className={styles['legend__symbol--blue']} />
          <label>Prospect Referrals</label>
        </div>

        <div className={styles.legend__symbol}>
          <span className={styles['legend__symbol--red']} />
          <label>Prospect Registrations</label>
        </div>
      </div>

      <div className={styles.page}>
        <div className={styles.stat_items__wrapper}>
          {(activeCamp ? _.concat(otherCamps, activeCamp) : otherCamps)
            .map((camp, cIndex) => <StatItem key={cIndex} className={styles.stat_item} camp={camp} />)}
        </div>

        <div className={styles.buttons__wrapper}>
          <SecondaryButton
            label='Back'
            className={styles.button__back}
            onClick={history.goBack}
          />

          <PrimaryButton className={styles.button__export}>
            <PDFIcon /> Export
          </PrimaryButton>
        </div>
      </div>
    </>
  );
};

export default Stats;