import React, { useState, useEffect } from 'react';
import { useStateValue } from 'state';
import { useLocation } from 'react-router-dom';

const EmailPreview = () => {
  const { search } = useLocation();
  const [html, setHtml] = useState(null);
  const { action: { getEmailTemplate } } = useStateValue();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const campaignId = params.get('campaignId');
    const messageType = params.get('messageType');

    getEmailTemplate(campaignId, messageType, ({ htmlContentTemplate, htmlLayoutTemplate, templateVars }) => {
      let html = htmlLayoutTemplate.text;

      html = html.replaceAll('{{{email-content}}}', htmlContentTemplate.text);

      Object.keys(templateVars).forEach(key => {
        html = html.replaceAll(`{{{${key}}}}`, templateVars[key]).replaceAll(`{{${key}}}`, templateVars[key]);
      });

      setHtml(html);
    });
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    if (!html) return;

    document.getElementsByTagName('html')[0].remove();
    document.write(html);
  }, [html]);

  return <React.Fragment />;
};

export default EmailPreview;