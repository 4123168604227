import React, { useState } from 'react'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import { PrimaryButton } from 'components'
import styles from './SetupForm.module.scss'

const SetupForm = ({ paymentSuccessful }) => {
  const stripe = useStripe()
  const elements = useElements()

  const [errorMessage, setErrorMessage] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!stripe || !elements) {
      return null
    }

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: process.env.REACT_APP_FPC_SUCCESS_URL,
      },
      redirect: 'if_required',
    })

    if (error) {
      setErrorMessage(error.message)
    } else {
      setErrorMessage('')
      paymentSuccessful()
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <PrimaryButton
        className={styles.submit__btn}
        label="Submit"
        disabled={!stripe}
        type="submit"
      />
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  )
}

export default SetupForm
