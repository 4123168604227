import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Dashboard from '../Home'
import { useStateValue } from 'state'

const Preview = () => {
  const {
    action: { getAmbassadorSetupData },
  } = useStateValue()
  const { uniqueLink } = useParams()
  const [ambassadorSetup, setAmbassadorSetup] = useState(null)
  const [ambassadorRewards, setAmbassadorRewards] = useState([])
  const [prospectRewards, setProspectRewards] = useState([])
  const [campaignId, setCampaignId] = useState()
  const [camp, setCamp] = useState(null)

  useEffect(
    () =>
      getAmbassadorSetupData(
        { type: 'c', uniqueLink },
        ({
          Campaign: {
            id,
            AmbassadorSetup,
            Camp,
            AmbassadorRewards,
            ProspectRewards,
          },
        }) => {
          setCampaignId(id)
          setAmbassadorSetup(AmbassadorSetup)
          setAmbassadorRewards(AmbassadorRewards)
          setProspectRewards(ProspectRewards)
          setCamp(Camp)
        }
      // eslint-disable-next-line
      ), [uniqueLink]
  )

  if (ambassadorSetup && camp)
    return (
      <Dashboard
        {...ambassadorSetup}
        prospectRewards={prospectRewards}
        ambassadorRewards={ambassadorRewards}
        camp={camp}
        campaignId={campaignId}
        uniqueLink={uniqueLink}
        isJoined={true}
        onSubmit={(args, callback) => callback(args)}
        isPreview
      />
    )

  return <React.Fragment />
}

export default Preview

