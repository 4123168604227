import _ from "lodash";
import { getStorageItem } from 'utils';
import { ACTION, STORAGE_NAME } from 'utils/constants';

const campReducer = (state, { type, payload }) => {
  const idInUse = getStorageItem(STORAGE_NAME.CAMP_IN_USE);

  switch (type) {
    case ACTION.CAMP__GET_ALL:
      return {
        ...state,
        activeCamp: payload.camps.filter(({ id }) => id === idInUse)[0] || null,
        otherCamps: payload.camps.filter(({ id }) => id !== idInUse)
      };

    case ACTION.CAMP__ADD:
      return {
        ...state,
        otherCamps: _.concat(state.otherCamps, payload.camp)
      };

    case ACTION.CAMP__UPDATE:
      return {
        ...state,
        ...(
          state.activeCamp?.id === payload.camp.id ? { activeCamp: payload.camp } : {
            otherCamps: state.otherCamps.map(item => {
              if (item.id === payload.camp.id) return {
                ...item,
                ...payload.camp
              };

              return item;
            })
          }
        )
      };

    case ACTION.CAMP__DELETE:
      return {
        ...state,
        otherCamps: state.otherCamps.filter(item => item.id !== payload.id)
      };

    case ACTION.CAMP__SET_ACTIVE:
      return {
        ...state,
        activeCamp: state.otherCamps.filter(item => item.id === payload.id)[0],
        otherCamps: (state.activeCamp ? _.concat(state.otherCamps, state.activeCamp) : state.otherCamps)
          .filter(item => item.id !== payload.id)
      };

    case ACTION.CAMP__GET_ACTIVE:
      return {
        ...state,
        activeCamp: payload.camp
      };

    default:
      return state;
  }
};

export default campReducer;
