import { ACTION } from 'utils/constants';

const globalReducer = (state, { type, payload }) => {
  switch (type) {
    case ACTION.START:
      return {
        ...state,
        isLoading: true,
        error: null
      };

    case ACTION.END:
      return {
        ...state,
        isLoading: false
      };

    case ACTION.ERROR:
      return {
        ...state,
        error: payload
      };

    default:
      return state;
  };
};

export default globalReducer;