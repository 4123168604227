import React, { useRef, useState } from 'react'
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappIcon,
  WhatsappShareButton,
  EmailShareButton,
  EmailIcon,
} from 'react-share'
import styles from './AmbDashboard.module.scss'
import { getTextInitials } from 'utils'
import {
  PrimaryButton,
  SecondaryButton,
  TextInput,
  AmbassadorSetupTermsModal,
} from 'components'
import clsx from 'clsx'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet'

const AmbDashboard = ({
  landingBgImgURL = null,
  dashboardHeading = null,
  dashboardSubheading = null,
  ambassadorRewards = [],
  camp = null,
  campaignId = null,
  person: initPerson = {},
  ambassador = {},
  isPreview = false,
  campaignName = null,
}) => {
  const [showTermsModal, setShowTermsModal] = useState(false)
  const termsButtonRef = useRef(null)
  const handleClickCopyUniqueLink = () => {
    if (isPreview) return

    navigator.clipboard.writeText(
      `${window.location.origin}/p/${ambassador.uniqueLink}`
    )
    toast.info(`Unique Link is copied.`)
  }

  const useBackground = window.matchMedia('(min-width: 768px)')
  const pageStyle = useBackground.matches
    ? {
        backgroundImage: `url("${landingBgImgURL}")`,
      }
    : {}

  const mobileIntroImageStyle = useBackground.matches
    ? {}
    : { backgroundImage: `url("${landingBgImgURL}")` }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{campaignName}</title>
      </Helmet>
      <div className={styles.page} style={pageStyle}>
        <div className={styles.header}>
          <div className={styles.header__brand}>
            <div className={styles.header__logo}>
              {camp.logo ? (
                <img src={camp.logo} alt={camp.name} />
              ) : (
                <span>{getTextInitials(camp.name)}</span>
              )}
            </div>

            <h6>{camp.name}</h6>
          </div>

          <SecondaryButton
            className={styles.header__visit}
            label="Visit Website"
            onClick={() => window.open(camp.website, '_blank').focus()}
          />
        </div>

        <div className={styles.main__wrapper}>
          <div className={styles.mobile_intro}>
            <h3 className={styles.mobile_intro__title}>Ambassador Dashboard</h3>
            <div
              className={styles.mobile_intro__image}
              style={mobileIntroImageStyle}
            ></div>
          </div>
          <div className={styles.summary__wrapper}>
            <div className={styles.summary}>
              <div className={styles.summary__logo}>
                {camp.logo ? (
                  <img
                    src={camp.logo}
                    alt={camp.name}
                    className={styles.summary__logo_img}
                  />
                ) : (
                  <span>{getTextInitials(camp.name)}</span>
                )}
              </div>
              <div className={styles.summary__person}>
                <span className={styles.summary__person_name}>
                  {`${initPerson.firstName || 'Jane'} ${
                    initPerson.lastName || 'Doe'
                  }`}
                </span>
                <span className={styles.summary__person_email}>
                  {initPerson.email || 'jane@doe.com'}
                </span>
              </div>
              <div className={styles.summary__data_wrapper}>
                <div className={styles.summary__data}>
                  <span
                    className={styles.summary__data_label}
                    title="how many times your unique link has been viewed"
                  >
                    UL View
                  </span>
                  <span className={styles.summary__data_value}>
                    {ambassador?.sharePageViews || 0}
                  </span>
                </div>
                <div className={styles.summary__data}>
                  <span className={styles.summary__data_label}>Inquiry</span>
                  <span className={styles.summary__data_value}>
                    {ambassador?.infoRequests || 0}
                  </span>
                </div>
                <div className={styles.summary__data}>
                  <span className={styles.summary__data_label}>Promo Code</span>
                  <span className={styles.summary__data_value}>
                    {ambassador?.codesClaimed || 0}
                  </span>
                </div>
                <div className={styles.summary__data}>
                  <span className={styles.summary__data_label}>
                    Registration
                  </span>
                  <span className={styles.summary__data_value}>
                    {ambassador?.registrations || 0}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={clsx(styles.main, styles['main--joined'])}>
            <div className={styles.intro}>
              <h3 className={styles.intro__title}>{dashboardHeading}</h3>
              <p className={styles.intro__paragraph}>{dashboardSubheading}</p>
            </div>
            <div className={styles.rewards}>
              {ambassadorRewards.map((reward) => (
                <div key={reward.id} className={styles.reward}>
                  {reward.imgURL && (
                    <img
                      className={styles.reward__img}
                      src={reward.imgURL}
                      alt="Reward"
                    />
                  )}
                  {!reward.imgURL && <div className={styles.reward__img}></div>}
                  <h4 className={styles.reward__type}>Ambassador Reward</h4>
                  <p className={styles.reward__description}>{reward.desc}</p>
                </div>
              ))}
            </div>
            <div className={styles.share__wrapper}>
              <div className={styles.share__title}>Unique Link</div>
              <div className={styles.share__desc}>
                Copy and share your Unique Link today!
              </div>
              <div className={styles.share}>
                <TextInput
                  classNames={{ container: styles.share__input }}
                  disabled={isPreview}
                  value={`${window.location.origin}/p/${ambassador.uniqueLink}`}
                  readOnly
                />

                <PrimaryButton
                  label="Copy"
                  disabled={isPreview}
                  className={styles.share__copy}
                  onClick={handleClickCopyUniqueLink}
                />
              </div>
              <div className={styles.share__buttons}>
                <FacebookShareButton
                  url={`${window.location.origin}/p/${ambassador.uniqueLink}`}
                  quote={`See ${camp.name}`}
                  hashtag={`#${camp.name.split(' ').join('')}`}
                >
                  <FacebookIcon size={36} />
                </FacebookShareButton>
                <TwitterShareButton
                  url={`${window.location.origin}/p/${ambassador.uniqueLink}`}
                  quote={`See ${camp.name}`}
                  hashtag={`#${camp.name.split(' ').join('')}`}
                >
                  <TwitterIcon size={36} />
                </TwitterShareButton>
                <WhatsappShareButton
                  url={`${window.location.origin}/p/${ambassador.uniqueLink}`}
                  quote={`See ${camp.name}`}
                  hashtag={`#${camp.name.split(' ').join('')}`}
                >
                  <WhatsappIcon size={36} />
                </WhatsappShareButton>
                <EmailShareButton
                  url={`${window.location.origin}/p/${ambassador.uniqueLink}`}
                  quote={`See ${camp.name}`}
                  hashtag={`#${camp.name.split(' ').join('')}`}
                >
                  <EmailIcon size={36} />
                </EmailShareButton>
              </div>
            </div>
            <button
              type="button"
              ref={termsButtonRef}
              className={styles.main__terms}
              onClick={() => setShowTermsModal(true)}
            >
              Terms
            </button>{' '}
          </div>
        </div>
      </div>

      <AmbassadorSetupTermsModal
        campaignId={campaignId}
        show={showTermsModal}
        onClose={() => setShowTermsModal(false)}
      />
    </>
  )
}

export default AmbDashboard
