import clsx from 'clsx'
import React from 'react'
import styles from './CampItem.module.scss'
import { PrimaryButton, SecondaryButton, RemoveIcon } from 'components'
import { getTextInitials } from 'utils'
import { useStateValue } from 'state'
import { useNavigate } from 'hooks'
import { PATH } from 'utils/constants'

const CampItem = ({
  className,
  inUse = false,
  camp: {
    id,
    name,
    website,
    logo,
    address_1,
    address_2,
    city,
    state,
    zipCode,
    Users: roles,
  },
}) => {
  const navigate = useNavigate()
  const {
    action: { activeCamp, deleteCamp, getSubscription, deleteSubscription },
  } = useStateValue()
  // const { action: { activeCamp, deleteCamp } } = useStateValue();

  const handleClickRemove = (e) => {
    e.preventDefault()

    deleteCamp(id, () => {
      getSubscription({ campId: id }, (subscription) => {
        if (subscription.length > 0) deleteSubscription(subscription[0].id)
      })
    })
  }

  return (
    <div
      className={clsx(className, styles.camp, inUse && styles['camp--active'])}
    >
      {inUse ? null : (
        <button
          type="button"
          className={styles.camp__remove}
          onClick={handleClickRemove}
        >
          <RemoveIcon />
        </button>
      )}

      <div className={styles.camp__block__1}>
        <div className={styles.camp__logo}>
          {logo ? (
            <img src={logo} alt={name} />
          ) : (
            <h6>{getTextInitials(name)}</h6>
          )}
        </div>

        <div className={styles.camp__block__2}>
          <p
            className={styles.camp__name}
            dangerouslySetInnerHTML={{ __html: `${name}` }}
          />

          <p
            className={styles.camp__address}
            dangerouslySetInnerHTML={{
              __html: `${address_1}${
                address_2 || ''
              }, ${city}, ${state} ${zipCode}`,
            }}
          />

          <p className={styles.camp__website}>
            <a href={website} target="_blank" rel="noreferrer">
              Camp Website
            </a>
          </p>
        </div>
      </div>

      <div className={styles.camp__block__5}>
        <label className={styles.camp__label}>
          Last activity – 2 hours ago
        </label>

        <div className={styles.camp__buttons}>
          <SecondaryButton
            className={styles.camp__button__edit}
            label="Edit"
            onClick={() => navigate({ pathname: `${PATH.CAMPS__EDIT}/${id}` })}
          />

          {inUse ? null : (
            <PrimaryButton
              className={styles.camp__button__use}
              label="Use"
              onClick={() => activeCamp(id)}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default CampItem
