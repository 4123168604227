import React, { useRef } from 'react'
import clsx from 'clsx'
import { TextInput, HtmlEditor } from 'components'
import styles from './EmailTemplate.module.scss'

const EmailTemplate = ({
  className,
  subject = { id: '', value: '', isError: false, captionText: '' },
  heading = { id: '', value: '', isError: false, captionText: '' },
  subheading = { id: '', value: '', isError: false, captionText: '' },
  content = { id: '', value: '', isError: false, captionText: '' },
  isLocked = false,
  optionalTags = [],
  requiredTags = [],
  templateVars = [],
  onChange = () => null,
  onPreview = () => null,
}) => {
  const contentRef = useRef(null)

  const checkTag = (tag, type) => {
    try {
      return tag.split('-')?.pop() === type
    } catch (error) {
      return false
    }
  }

  const isImageTag = (tag) => checkTag(tag, 'image')

  const isLinkTag = (tag) => checkTag(tag, 'link')

  const handleTagClick = (tag) => {
    if (isLinkTag(tag))
      contentRef.current.execCommand(
        'mceInsertContent',
        false,
        `<a href="{{{${tag}}}}" target="_blank">${tag}</a>`
      )
    else if (isImageTag(tag))
      contentRef.current.execCommand(
        'mceInsertContent',
        false,
        `<img src="${templateVars[tag]}" alt="${tag}" height="200" />`
      )
    else contentRef.current.execCommand('mceInsertContent', false, `{{${tag}}}`)

    onHtmlEditorChange()
  }

  const onHtmlEditorChange = () => {
    if (contentRef.current) {
      // console.log('html content', contentRef.current.getContent())
      onChange(content.id, contentRef.current.getContent())
    }
  }

  return (
    <div className={clsx(className, styles.section)}>
      <div className={styles.form__row}>
        <div className={styles.form__col}>
          <TextInput
            label="Subject"
            disabled={isLocked}
            placeholder="Subject"
            value={subject.value}
            onChange={(value) => onChange(subject.id, value)}
            isError={subject.isError}
            captionText={subject.captionText}
          />
        </div>
        <div className={styles.form__col}></div>
      </div>
      <div className={styles.form__row}>
        <div className={styles.form__col}>
          <TextInput
            label="Heading"
            disabled={isLocked}
            placeholder="Heading"
            value={heading.value}
            onChange={(value) => onChange(heading.id, value)}
            isError={heading.isError}
            captionText={heading.captionText}
          />
        </div>
        <div className={styles.form__col}>
          <TextInput
            label="Subheading"
            disabled={isLocked}
            placeholder="Subheading"
            value={subheading.value}
            onChange={(value) => onChange(subheading.id, value)}
          />
        </div>
      </div>

      <div className={styles.form__row}>
        <div className={styles.form__col}>
          <div className={styles.subtitle}>Merge Tags</div>
          <p className={styles.field__merge_tags__wrapper}>
            <span className={styles.field__merge_tag__text}>Required:</span>
            {requiredTags?.map((tag, tIndex) => (
              <button
                key={tIndex}
                type="button"
                className={clsx(
                  styles.field__merge_tag,
                  styles['field__merge_tag--required']
                )}
                dangerouslySetInnerHTML={{
                  __html: isLinkTag(tag) ? `{{{${tag}}}}` : `{{${tag}}}`,
                }}
                onClick={() => handleTagClick(tag)}
              />
            ))}
          </p>

          <p className={styles.field__merge_tags__wrapper}>
            <span className={styles.field__merge_tag__text_green}>
              Optional:
            </span>
            {optionalTags?.map((tag, tIndex) => (
              <button
                key={tIndex}
                type="button"
                className={clsx(
                  styles.field__merge_tag,
                  styles['field__merge_tag--optional']
                )}
                dangerouslySetInnerHTML={{
                  __html: isLinkTag(tag) ? `{{{${tag}}}}` : `{{${tag}}}`,
                }}
                onClick={() => handleTagClick(tag)}
              />
            ))}
          </p>
        </div>
      </div>

      <div className={styles.form__row}>
        <div className={styles.form__col}>
          <HtmlEditor
            disabled={isLocked}
            editorRef={contentRef}
            value={content.value}
            onPreview={onPreview}
            onChange={onHtmlEditorChange}
            isError={content.isError}
            captionText={content.captionText}
            requiredTags
          />
        </div>
      </div>
    </div>
  )
}

export default EmailTemplate
