import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import styles from './TextInput.module.scss';

const TextInput = ({
  classNames = { container: '', label: '', input: '' },
  tabIndex = '',
  label = null, placeholder = '', value: initValue = '', captionText = null,
  requiredShown = false, optionalShown = false,
  isError = false, readOnly = false, disabled = false, type='text', max = null, min = null,
  onChange: handleChangeValue = () => null,
  onSubmit = () => null,
}) => {
  const [value, setValue] = useState('');

  useEffect(() => setValue(initValue || ''), [initValue]);

  const handleChange = e => {
    if(max !== null && e.target.value <= 100){
      setValue(e.target.value);
      handleChangeValue(e.target.value);
    }else if(max === null){
      setValue(e.target.value);
      handleChangeValue(e.target.value);
    }
  };

  return (
    <div className={clsx(classNames.container, styles.text_input__wrapper)}>
      {label ? (
        <label className={clsx(classNames.label, styles.text_input__label)}>
          {label === ' ' ? <>&nbsp;</> : label}
          {requiredShown ? <span className={styles.text_input__required}>&nbsp;*</span> : null}
          {optionalShown ? <span className={styles.text_input__optional}>&nbsp;(optional)</span> : null}
        </label>
      ) : null}

      <input
        className={clsx(
          classNames.input,
          styles.text_input,
          isError && styles[`text_input--error`]
        )}
        tabIndex={tabIndex}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onKeyUp={({ key }) => key === 'Enter' && onSubmit()}
        readOnly={readOnly}
        disabled={disabled}
        type={type}
        max={max}
        min={min}
      />

      <p className={clsx(styles.caption, isError && styles.caption__error)}>{captionText ? <span>{captionText}</span> : null}</p>
    </div>
  );
};

export default TextInput;