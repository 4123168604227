import React, { useState } from 'react'
import { useStateValue } from 'state'
import styles from './CampsHome.module.scss'
import {
  PrimaryButton,
  PlusCircleIcon,
  CampItem,
  SubscriptionMethod,
  LoadingOverlay,
} from 'components'
import { NoCamp } from 'sections/Camps'
import { useNavigate } from 'hooks'
import clsx from 'clsx'
import { PATH } from 'utils/constants'

const Home = () => {
  const {
    REACT_APP_STRIPE_ADDITIONAL_YEARLY_PRICE,
    REACT_APP_STRIPE_ADDITIONAL_MONTHLY_PRICE,
  } = process.env
  const navigate = useNavigate()
  const {
    state: {
      isLoading,
      camp: { activeCamp, otherCamps },
      deviceSize,
    },
  } = useStateValue()
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false)

  // const canAddCamp = () => {
  //   let count = activeCamp ? 1 : 0
  //   count += otherCamps.length
  //   return count < user.campCount
  // }

  const addNewCamp = () => {
    // TODO Get the user's default payment method using billing api
    setShowSubscriptionModal(true)
  }

  return otherCamps.length === 0 && activeCamp === null ? (
    <>
      <NoCamp />
      <LoadingOverlay isLoading={isLoading} />
    </>
  ) : (
    <div className={styles.page}>
      <div className={styles.page__header}>
        <div className={styles.page__header__title}>
          {['xs', 'sm', 'md'].includes(deviceSize) ? (
            <h3>My Camps</h3>
          ) : (
            <h2>My Camps</h2>
          )}
          <h4>{otherCamps.length + (activeCamp ? 1 : 0)}</h4>
        </div>

        <PrimaryButton
          className={styles.page__header__button}
          onClick={() => addNewCamp()}
          // disabled={!canAddCamp()}
        >
          <PlusCircleIcon />
          Add New Camp
        </PrimaryButton>
      </div>

      {activeCamp ? (
        <div className={styles.camp__list__view__wrapper}>
          <p className={styles.camp__list__status}>Using Now</p>

          <div className={styles.camp__list__view}>
            <CampItem className={styles.camp} camp={activeCamp} inUse />
          </div>
        </div>
      ) : null}

      {otherCamps.length ? (
        <div className={styles.camp__list__view__wrapper}>
          <p
            className={clsx(
              styles.camp__list__status,
              !activeCamp && styles['camp__list__status--hidden']
            )}
          >
            Other Camps
          </p>

          <div className={styles.camp__list__view}>
            {otherCamps.map((camp, cIndex) => (
              <CampItem className={styles.camp} camp={camp} key={cIndex} />
            ))}
          </div>
        </div>
      ) : null}

      <div className={styles.page__footer}>
        <PrimaryButton
          className={styles.page__footer__button}
          onClick={() => addNewCamp()}
          // disabled={!canAddCamp()}
        >
          <PlusCircleIcon />
          Add New Camp
        </PrimaryButton>
      </div>
      <SubscriptionMethod
        title="Select your billing plan."
        show={showSubscriptionModal}
        monthly_price={REACT_APP_STRIPE_ADDITIONAL_MONTHLY_PRICE}
        yearly_price={REACT_APP_STRIPE_ADDITIONAL_YEARLY_PRICE}
        note="Your default payment method will be charged"
        onCancel={() => setShowSubscriptionModal(false)}
        submitButtonText="Subscribe and add camp"
        onOK={(subs, coupon) => {
          navigate({ pathname: PATH.CAMPS__ADD, state: { subs, coupon } })
        }}
      />
      <LoadingOverlay isLoading={isLoading} />
    </div>
  )
}

export default Home
