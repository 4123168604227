import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useStateValue } from 'state'
import ProspectLdg from '../Home'
import { getAxiosInstance } from 'utils'
import moment from 'moment'
import {
  CAMPAIGN_STATUS,
  CAMP_STATUS,
  ERR_CODE,
  PATH,
  REFERRAL_TYPE,
} from 'utils/constants'
import { Helmet } from 'react-helmet'

const Prospect = () => {
  const [amb, setAmb] = useState(null)
  const [prospectSetup, setProspectSetup] = useState(null)
  const [camp, setCamp] = useState(null)
  const {
    action: { getProspectSetupData, closeCampaign },
  } = useStateValue()
  const { uniqueLink } = useParams()
  const [isClosed, setClosed] = useState(false)
  const [isAmbNotFound, setAmbNotFound] = useState(false)
  // const [person, setPerson] = useState({})
  const [promocode, setPromocode] = useState('')

  useEffect(
    () =>
      getProspectSetupData(uniqueLink, (obj) => {
        if (obj.code === ERR_CODE.AMBASSADOR_NOT_FOUND) {
          setAmbNotFound(true)
          return
        }
        const amb = obj.ambassador
        setAmb(amb)
        const campaign = amb.Campaign
        setPromocode(campaign.ProspectRewards[0].promocode)
        setProspectSetup(campaign.ProspectSetup)
        setCamp(campaign.Camp)
        // setPerson({ firstName, lastName, email })
        setClosed(() => {
          if (
            campaign.status === CAMPAIGN_STATUS.CLOSED ||
            campaign.Camp.status !== CAMP_STATUS.ACTIVE
          )
            return true

          if (moment().diff(campaign.endDate, 'days') > 0) {
            closeCampaign(campaign.id)
            return true
          }

          return false
        })
      }),
    // eslint-disable-next-line
    [uniqueLink]
  )

  if (isClosed)
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: '100px',
        }}
      >
        <h6>We&apos;re sorry, but this campaign is closed.</h6>
      </div>
    )
  //if (isAmbNotFound) return <Redirect to={PATH.NOT_FOUND} />
  if (isAmbNotFound) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: '100px',
        }}
      >
        <h6>
          No ambassador with unique link '{uniqueLink}' was found.
          <br /> Please check with the person who shared the link.
        </h6>
      </div>
    )
  }

  if (prospectSetup && camp)
    return (
      <>
        <Helmet>
          <meta
            property="og:title"
            content={`${amb.Person.firstName} invited you to ${amb.Campaign.Camp.name}!`}
          />
          <meta
            property="og:description"
            content={`${amb.Person.firstName} has invited you to join them at ${amb.Campaign.Camp.name} this summer. Click the link to learn more!`}
          />
          <meta
            property="og:url"
            content={`${window.location.origin}/p/${uniqueLink}`}
          />
          <meta property="og:type" content="website" />
          <title>
            {amb.Person.firstName} invited you to {camp.name}!
          </title>
          <meta charSet="utf-8" />
        </Helmet>
        <ProspectLdg
          {...prospectSetup}
          promocode={promocode}
          camp={camp}
          onSubmitLM={(args) =>
            getAxiosInstance().post(`/api/share/referral/${uniqueLink}`, {
              ...args,
              type: REFERRAL_TYPE.INQUIRY,
            })
          }
          onSubmitE={(args) =>
            getAxiosInstance().post(`/api/share/referral/${uniqueLink}`, {
              ...args,
              type: REFERRAL_TYPE.REGISTRATION,
            })
          }
        />
      </>
    )

  return <React.Fragment />
}

export default Prospect
