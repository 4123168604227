import React from 'react';
import { useFormik } from 'formik';
import styles from './AddAmbassadorManually.module.scss';
import { Modal, TextInput, PrimaryButton, SecondaryButton, ExcludedIcon } from 'components';
import { useStateValue } from 'state';
import clsx from 'clsx';
import * as Yup from 'yup';

const AddAmbassadorManually = ({
  classNames = { modal: '', content: '' },
  show, data: initData = {},
  onClose = () => null,
  onAdd = () => null,
  description = null,
}) => {
  const { state: { deviceSize } } = useStateValue();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      ...initData
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First Name is required.'),
      lastName: Yup.string().required('Last Name is required'),
      phone: Yup.string()
        .matches(/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/, 'Please enter a valid phone number.')
        .nullable(),
      email: Yup.string()
        .required('Please enter camp email.')
        .email('Please enter a valid email.')
    }),
    onSubmit: args => {
      onAdd(args);
      handleClose();
    }
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <Modal
      classNames={{
        modal: clsx(styles.modal, classNames.modal),
        content: clsx(styles.modal__content, classNames.content)
      }}
      show={show} onClose={onClose}
    >
      <h6 className={styles.title}>{initData.id ? 'Update' : 'Add'} Ambassador</h6>

      <button type='button' className={styles.close} onClick={handleClose}>
        <ExcludedIcon />
      </button>

      <form className={styles.form}>
        <div className={styles.form__row}>
          <div className={styles.form__col}>
            <TextInput
              label='First Name'
              placeholder='First Name'
              requiredShown
              value={formik.values.firstName}
              onChange={value => formik.setFieldValue('firstName', value)}
              {...(formik.touched.firstName && formik.errors.firstName ? { isError: true, captionText: formik.errors.firstName } : {})}
            />

            <TextInput
              label='Last Name'
              placeholder='Last Name'
              requiredShown
              value={formik.values.lastName}
              onChange={value => formik.setFieldValue('lastName', value)}
              {...(formik.touched.lastName && formik.errors.lastName ? { isError: true, captionText: formik.errors.lastName } : {})}
            />

            <TextInput
              label='Phone (Optional)'
              placeholder='Phone'
              value={formik.values.phone}
              onChange={value => formik.setFieldValue('phone', value)}
              {...(formik.touched.phone && formik.errors.phone ? { isError: true, captionText: formik.errors.phone } : {})}
            />

            <TextInput
              label='Email'
              placeholder='Email'
              requiredShown
              value={formik.values.email}
              onChange={value => formik.setFieldValue('email', value)}
              {...(formik.touched.email && formik.errors.email ? { isError: true, captionText: formik.errors.email } : {})}
            />
             {description && <p style={{marginBottom: '5px'}}>{description}</p>}
          </div>
        </div>

        <div className={styles.form__buttons}>
          <SecondaryButton
            className={styles.form__cancel}
            label={['xs', 'sm', 'md'].includes(deviceSize) ? 'Back' : 'Cancel'}
            onClick={handleClose}
          />

          <PrimaryButton
            className={styles.form__save}
            label={initData.id ? 'Update' : 'Add'}
            onClick={formik.handleSubmit}
          />
        </div>
      </form>
    </Modal>
  );
};

export default AddAmbassadorManually;