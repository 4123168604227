import { getAxiosInstance, handleError } from 'utils'
import { ACTION } from 'utils/constants'

export const uploadCampCsvFile =
  (dispatch) => async (id, file, personType, callback) => {
    try {
      dispatch({ type: ACTION.START })

      const formData = new FormData()
      formData.append('file', file)

      const response = await getAxiosInstance().post(
        `/api/camps/${id}/csv/${personType}`,
        formData
      )

      const { success, code, message } = response.data

      if (success) callback()
      else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const removeCampCsvFile =
  (dispatch) => async (id, csvFile, callback) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().delete(
        `/api/camps/${id}/csv/${csvFile}`
      )

      const { success, code, message } = response.data

      if (success) callback()
      else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const removeCampRole =
  (dispatch) => async (campId, userId, callback) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().delete(
        `/api/camps/${campId}/roles/${userId}`
      )

      const { success, code, message } = response.data

      if (success) callback()
      else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const addCampRole =
  (dispatch) =>
  async (campId, data, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().post(
        `/api/camps/${campId}/roles`,
        { role: data }
      )

      const { success, code, message, role } = response.data

      if (success) callback(role)
      else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const getCampPeople =
  (dispatch) =>
  async (campId, filter, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })
      // console.log('getCampPeopleFilter', filter)
      const response = await getAxiosInstance().get(
        `/api/camps/${campId}/people`,
        {
          params: {
            key: filter.keyword,
            all: filter.all,
            campaignId: filter.campaignId,
          },
        }
      )

      const { success, people, code, message } = response.data

      if (success) callback(people)
      else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const getCampReferrals =
  (dispatch) =>
  async (campId, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })
      const response = await getAxiosInstance().get(
        `/api/camps/${campId}/people/referrals`
      )

      const { success, referrals: people, code, message } = response.data

      if (success) callback(people)
      else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const getCampPerson =
  (dispatch) =>
  async (campId, personId, include = [], callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().get(
        `/api/camps/${campId}/people/${personId}`,
        {
          params: { include },
        }
      )

      const { success, person, code, message } = response.data
      if (success) callback(person)
      else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      console.error(error)
      // handleError(dispatch, error);
    } finally {
      dispatch({ type: ACTION.END })
    }
  }

export const updateCampPerson =
  (dispatch) =>
  async (campId, personId, data, callback = () => null) => {
    try {
      dispatch({ type: ACTION.START })

      const response = await getAxiosInstance().put(
        `/api/camps/${campId}/people/${personId}`,
        data
      )

      const { success, code, message } = response.data

      if (success) callback()
      else throw new Error(JSON.stringify({ code, message }))
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: ACTION.END })
    }
  }
