import React, { useEffect } from 'react'
import { useStripe } from '@stripe/react-stripe-js'
import { toast } from 'react-toastify'

const CardSetupResponse = ({ clientSecret, saveUserInfo, errorSaving }) => {
  const stripe = useStripe()

  const createSubscription = async (setupIntent) => {
    const url =
      process.env.REACT_APP_STRIPE_API_URL + '/api/subscriptions/create-fm-si'
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: setupIntent.id }),
    })
    if (response.ok) saveUserInfo()
    else {
      toast.error('Error saving payment details')
      errorSaving()
    }
  }
  useEffect(() => {
    if (!stripe) {
      return
    }
    stripe.retrieveSetupIntent(clientSecret).then(async ({ setupIntent }) => {
      if (setupIntent.status) await createSubscription(setupIntent)
    })
  }, [stripe])

  return null
}

export default CardSetupResponse
