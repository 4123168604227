import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import styles from './AddReferralManually.module.scss'
import {
  Modal,
  TextInput,
  PrimaryButton,
  SecondaryButton,
  ExcludedIcon,
} from 'components'
import { STORAGE_NAME } from 'utils/constants'
import { getStorageItem } from 'utils'
import { useStateValue } from 'state'
import clsx from 'clsx'
import * as Yup from 'yup'

const AddReferralManually = ({
  classNames = { modal: '', content: '' },
  show,
  onClose = () => null,
  onAdd = () => null,
  addAmbassador = () => null,
  ambassador: newAmbassador,
}) => {
  const campIdInUse = getStorageItem(STORAGE_NAME.CAMP_IN_USE)
  const [people, setPeople] = useState([])
  const [showAddAmbassador, setShowAddAmbassador] = useState(newAmbassador)
  const [ambassador, setAmbassador] = useState(null)

  useEffect(() => {
    if (newAmbassador)
      getCampPeople(
        campIdInUse,
        { keyword: newAmbassador.email, all: true },
        (person) => {
          setAmbassador(person[0])
          formik.setFieldValue(
            'ambassadorName',
            person[0].firstName + ' ' + person[0].lastName
          )
          setPeople([])
        }
      )
  }, [newAmbassador]) // eslint-disable-line

  const {
    state: { deviceSize },
    action: { getCampPeople },
  } = useStateValue()

  const findAmbassador = (name) => {
    if (name.length === 0) {
      setPeople([])
      setShowAddAmbassador(false)
      formik.setFieldValue('ambassadorName', '')
      return
    }
    setShowAddAmbassador(true)
    getCampPeople(campIdInUse, { keyword: name, all: true }, (people) =>
      setPeople(people)
    )
  }
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      ambassadorName: newAmbassador
        ? newAmbassador.firstName + newAmbassador.lastName
        : '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First Name is required.'),
      lastName: Yup.string().required('Last Name is required'),
      phone: Yup.string()
        .matches(
          /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
          'Please enter a valid phone number.'
        )
        .nullable(),
      email: Yup.string()
        .required('Please enter camp email.')
        .email('Please enter a valid email.'),
      ambassadorName: Yup.string().required('Ambassador is required.'),
    }),
    onSubmit: (args) => {
      args.person = ambassador
      onAdd(args)
      handleClose()
    },
  })

  const handleClose = () => {
    formik.resetForm()
    setAmbassador(null)
    setPeople([])
    onClose()
  }

  return (
    <Modal
      classNames={{
        modal: clsx(styles.modal, classNames.modal, 'manual-referral'),
        content: clsx(styles.modal__content, classNames.content),
      }}
      show={show}
      onClose={() => {
        setAmbassador(null)
        setPeople([])
        onClose()
      }}
    >
      <h6 className={styles.title}>Add Manual Referral</h6>

      <button type="button" className={styles.close} onClick={handleClose}>
        <ExcludedIcon />
      </button>

      <form className={styles.form}>
        <div className={styles.form__row}>
          <div className={styles.form__col}>
            <TextInput
              label="Prospect First Name"
              placeholder="First Name"
              requiredShown
              value={formik.values.firstName}
              onChange={(value) => formik.setFieldValue('firstName', value)}
              {...(formik.touched.firstName && formik.errors.firstName
                ? { isError: true, captionText: formik.errors.firstName }
                : {})}
            />

            <TextInput
              label="Prospect Last Name"
              placeholder="Last Name"
              requiredShown
              value={formik.values.lastName}
              onChange={(value) => formik.setFieldValue('lastName', value)}
              {...(formik.touched.lastName && formik.errors.lastName
                ? { isError: true, captionText: formik.errors.lastName }
                : {})}
            />

            <TextInput
              label="Prospect Email"
              placeholder="Email"
              requiredShown
              value={formik.values.email}
              onChange={(value) => formik.setFieldValue('email', value)}
              {...(formik.touched.email && formik.errors.email
                ? { isError: true, captionText: formik.errors.email }
                : {})}
            />

            <TextInput
              label="Prospect Phone (Optional)"
              placeholder="Phone"
              value={formik.values.phone}
              onChange={(value) => formik.setFieldValue('phone', value)}
              {...(formik.touched.phone && formik.errors.phone
                ? { isError: true, captionText: formik.errors.phone }
                : {})}
            />

            <div className={styles.form__row}>
              <div className={styles.form__col}>
                <TextInput
                  label="Link Ambasador"
                  placeholder="Search Ambasador by Name"
                  requiredShown
                  value={formik.values.ambassadorName}
                  onChange={(value) => {
                    findAmbassador(value)
                  }}
                  {...(formik.touched.ambassadorName &&
                  formik.errors.ambassadorName
                    ? {
                        isError: true,
                        captionText: formik.errors.ambassadorName,
                      }
                    : {})}
                />
              </div>
              {showAddAmbassador && (
                <div className={styles.form__col}>
                  <div className={styles.new_ambassador}>
                    <PrimaryButton
                      label="Add New Ambassador"
                      onClick={addAmbassador}
                    />
                  </div>
                </div>
              )}
            </div>

            {people.length ? (
              <div
                className={clsx(
                  styles.list__view,
                  !people.length && styles['list__view--empty']
                )}
              >
                {people.map((person) => (
                  <div
                    key={person.id}
                    className={styles.person}
                    onClick={() => {
                      setAmbassador(person)
                      formik.setFieldValue(
                        'ambassadorName',
                        person.firstName + ' ' + person.lastName
                      )
                      setPeople([])
                    }}
                  >
                    <span className={styles.person__name}>
                      {person.firstName} {person.lastName}
                    </span>

                    <span className={styles.person__email}>{person.email}</span>

                    <span className={styles.person__phone}>{person.phone}</span>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>

        <div className={styles.form__buttons}>
          <SecondaryButton
            className={styles.form__cancel}
            label={['xs', 'sm', 'md'].includes(deviceSize) ? 'Back' : 'Cancel'}
            onClick={handleClose}
          />

          <PrimaryButton
            className={styles.form__save}
            label="Add"
            onClick={formik.handleSubmit}
          />
        </div>
      </form>
    </Modal>
  )
}

export default AddReferralManually
