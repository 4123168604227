import React, { useState, useEffect } from 'react'
import styles from './AmbassadorList.module.scss'
import {
  TextInput,
  IllustrationSearch,
  MagnifierIcon,
  SelectFilter,
} from 'components'

import clsx from 'clsx'
import { STORAGE_NAME, PATH } from 'utils/constants'
import { getStorageItem } from 'utils'
import { useNavigate } from 'hooks'
import { useStateValue } from 'state'
import { useLocation } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import _, { upperCase } from 'lodash'

const statusFilters = [
  { key: 'ALL', value: 'All' },
  { key: 'UNINVITED', value: 'Unsent' },
  { key: 'INVITED', value: 'Invited' },
  { key: 'JOINED', value: 'Joined' },
]

const AmbassadorList = () => {
  const campIdInUse = getStorageItem(STORAGE_NAME.CAMP_IN_USE)
  const [people, setPeople] = useState([])
  const [allPeople, setAllPeople] = useState([])
  const [searchText, setSearchText] = useState('')

  const [selectedStatus, setSelectedStatus] = useState({
    key: 'ALL',
    value: 'All',
  })
  const [selectedCampaign, setSelectedCampaign] = useState({
    key: 'all',
    name: 'All Campaigns',
    id: null,
  })

  const [campaigns, setCampaigns] = useState([])

  const navigate = useNavigate()
  const {
    action: { getCampPeople, getCampaignSummaries },
  } = useStateValue()

  const query = new URLSearchParams(useLocation().search)

  // table columns

  const columns = [
    {
      name: (
        <div
          className={clsx(
            styles.ambassador_list__th,
            styles.ambassador_list__th__name
          )}
        >
          Name
        </div>
      ),
      selector: (ambassador) =>
        ambassador.firstName + ' ' + ambassador.lastName,
      sortable: true,
      style: {
        cursor: 'pointer',
      },
    },
    {
      name: (
        <div
          className={clsx(
            styles.ambassador_list__th,
            styles.ambassador_list__th__status
          )}
        >
          Email
        </div>
      ),
      selector: (ambassador) => ambassador.email,
      sortable: true,
      style: {
        cursor: 'pointer',
      },
    },
    {
      name: (
        <div
          className={clsx(
            styles.ambassador_list__th,
            styles.ambassador_list__th__name
          )}
        >
          Phone
        </div>
      ),
      selector: (ambassador) => ambassador.phone,
      sortable: true,
      style: {
        cursor: 'pointer',
      },
    },
  ]

  // on load fetch all campaigns
  useEffect(
    () =>
      getCampaignSummaries(campIdInUse, 'ALL', (summaries) => {
        summaries = summaries.filter((summary) => !summary.testMode)
        summaries.unshift({ key: 'all', name: 'All Campaigns', id: null })
        setCampaigns(summaries)
      }),
    [campIdInUse]
  )

  useEffect(() => {
    if (!campIdInUse) {
      navigate({ pathname: PATH.CAMPS })
      return
    }
    // campaign id passed in case
    if (query.get('campaign')) {
      getCampPeople(
        campIdInUse,
        { campaignId: query.get('campaign') },
        (people) => {
          if (people) {
            if (query.get('status')) {
              let peopleWithStatus = _.filter(people, (p) =>
                p.CampaignAmbassadors.find(
                  (camp_amb) =>
                    camp_amb.status === upperCase(query.get('status'))
                )
              )
              setPeople(peopleWithStatus)
            } else {
              setAllPeople(people)
              setPeople(people)
            }
            setAllPeople(people)
          }
        }
      )
      return
    }

    if (query.get('status') && query.get('status') === 'Joined') {
      getCampPeople(campIdInUse, '', (people) => {
        if (people) {
          let peopleWithStatus = _.filter(people, (p) =>
            p.CampaignAmbassadors.find(
              (camp_amb) =>
                camp_amb.status === upperCase(query.get('status')) ||
                camp_amb.sendDate !== null
            )
          )
          setPeople(peopleWithStatus)
          setAllPeople(people)
        }
      })
      return
    }

    getCampPeople(campIdInUse, '', (people) => {
      setPeople(people)
      setAllPeople(people)
    })
  }, [])

  // setting campaign and status as per url params
  useEffect(() => {
    if (!query.get('campaign'))
      setSelectedCampaign({
        key: 'all',
        name: 'All Campaigns',
        id: null,
      })
    else if (query.get('campaign') && campaigns.length > 0)
      setSelectedCampaign(
        _.find(campaigns, (campaign) => campaign.id === query.get('campaign'))
      )

    if (!query.get('status')) setSelectedStatus(statusFilters[0])
    else if (query.get('status'))
      setSelectedStatus(
        _.find(statusFilters, (filter) => filter.value === query.get('status'))
      )
  }, [query.get('campaign'), query.get('status'), campaigns])

  const handleCampaignFilter = (obj) => {
    setSelectedCampaign(obj)
    setSearchText('')
    if (obj.key === 'all') {
      getCampPeople(campIdInUse, '', (people) => {
        setPeople(people)
        setAllPeople(people)
      })
      setSelectedStatus(statusFilters[0])
      return
    }
    getCampPeople(campIdInUse, { campaignId: obj.id }, (people) => {
      setAllPeople(people)
      setPeople(people)
    })
  }

  const handleStatusFilter = (filter) => {
    setSelectedStatus(filter)
    if (filter.key == 'ALL') {
      setPeople(allPeople)
      return
    }
    let peopleWithStatus = _.filter(allPeople, (p) =>
      p.CampaignAmbassadors.find((camp_amb) => camp_amb.status === filter.key)
    )
    setPeople(peopleWithStatus)
    setSearchText('')
  }

  const filterAmbassadorsBySearch = (searchText) => {
    if (searchText === '') {
      setPeople(allPeople)
      return
    }
    searchText = searchText.trim()
    setSearchText(searchText)
    let filteredPeople = _.filter(
      allPeople,
      (p) =>
        p.firstName.match(new RegExp(searchText, 'i')) ||
        p.lastName.match(new RegExp(searchText, 'i')) ||
        p.email.match(new RegExp(searchText, 'i'))
    )
    setPeople(filteredPeople)
  }

  return (
    <div className={styles.page}>
      <div className={styles.search__wrapper}>
        <div className={styles.search__title}>Ambassador Search</div>

        <div className={styles.search__form}>
          <TextInput
            classNames={{
              container: styles.search__input__wrapper,
              input: styles.search__input,
            }}
            value={searchText}
            onChange={(keyword) => {
              filterAmbassadorsBySearch(keyword)
            }}
          />
          <MagnifierIcon className={styles.search__icon} />
        </div>
      </div>

      <div className={styles.list__wrapper}>
        <div className={styles.list__header}>
          <SelectFilter
            filters={campaigns}
            showKey="name"
            uniqueKey="id"
            className={styles.filter_button}
            handleFilterClick={handleCampaignFilter}
            selectedStatus={selectedCampaign}
          />
          {/* {selectedCampaign.id !== null && (
            <SelectFilter
              filters={statusFilters}
              showKey="value"
              uniqueKey="key"
              className={styles.filter_button}
              handleFilterClick={handleStatusFilter}
              selectedStatus={selectedStatus}
            />
          )} */}
        </div>

        <div
          className={clsx(
            styles.list__view,
            !people.length && styles['list__view--empty']
          )}
        >
          {people.length ? (
            <DataTable
              columns={columns}
              data={people}
              pagination
              onRowClicked={(e) => {
                navigate({
                  pathname: `${PATH.AMBASSADORS__EDIT}/${e.id}`,
                  state: { keyword: searchText },
                })
              }}
            />
          ) : (
            <IllustrationSearch />
          )}
          {/* {people.length ? (
            <>
              <p className={styles.list__results}>{people.length} Results</p>

              {people.map((person) => (
                <div
                  key={person.id}
                  className={styles.person}
                  onClick={() =>
                    navigate({
                      pathname: `${PATH.AMBASSADORS__EDIT}/${person.id}`,
                      state: { keyword: searchForm.values.keyword },
                    })
                  }
                >
                  <span className={styles.person__name}>
                    {person.firstName} {person.lastName}
                  </span>

                  <span
                    className={clsx(
                      styles.person__type,
                      styles[`person__type--${person.type}`]
                    )}
                  >
                    {person.type.toLowerCase()}
                  </span>

                  <span className={styles.person__email}>{person.email}</span>

                  <span className={styles.person__phone}>{person.phone}</span>
                </div>
              ))}
            </>
          ) : (
            <IllustrationSearch />
          )} */}
        </div>
      </div>
    </div>
  )
}

export default AmbassadorList
