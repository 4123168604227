import { getAxiosInstance, handleError } from 'utils';
import { ACTION, REWARD_TYPE } from 'utils/constants';

export const setCampaignRewards = dispatch => async (campaignId, campaignRewards, callback = () => null) => {
  try {
    dispatch({ type: ACTION.START });

    const returnData = {
      [REWARD_TYPE.AMBASSADOR]: [],
      [REWARD_TYPE.PROSPECT]: []
    };

    for (const [type, rewards] of Object.entries(campaignRewards)) {
      try {
        const result = await Promise.all(
          rewards.map(reward => new Promise((resolve, reject) => {
            const formData = new FormData();

            Object.entries(reward).forEach(([key, value]) => formData.append(key, value));

            const url = `/api/campaigns/${campaignId}/${type.toLowerCase()}-rewards`;

            if (reward.id)
              getAxiosInstance().put(`${url}/${reward.id}`, formData)
                .then(({ data }) => resolve(data))
                .catch(reject);
            else
              getAxiosInstance().post(url, formData)
                .then(({ data }) => resolve(data))
                .catch(reject);
          }))
        );

        result.forEach(data => {
          if (data.success) returnData[type].push(data[`${type.toLowerCase()}Reward`]);
          else handleError(dispatch, new Error(JSON.stringify(data)));
        });
      } catch (error) {
        handleError(dispatch, error);
      }
    }

    callback(returnData);
  } catch (error) {
    handleError(dispatch, error);
  } finally {
    dispatch({ type: ACTION.END });
  }
};