import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import styles from './PSLearnMore.module.scss'
import { getTextInitials } from 'utils'
import { Modal, ExcludedIcon, UserIcon, ExternalIcon } from 'components'

const PSLearnMore = ({
  linkToWebsite: initLinkToWebsite = null,
  linkToSchedule: initLinkToSchedule = null,
  linkToVideo: initLinkToVideo = null,
  linkToPricingInfo: initlinkToPricingInfo = null,
  link1,
  link2,
  link3,
  link4,
  learnMorePopupHeading,
  learnMorePopupBtnTxt,
  onClickRegister = () => null,
  camp,
  show,
  onClose = () => null,
}) => {
  const [linkToWebsite, setLinkToWebsite] = useState(initLinkToWebsite)
  const [linkToSchedule, setLinkToSchedule] = useState(initLinkToSchedule)
  const [linkToVideo, setLinkToVideo] = useState(initLinkToVideo)
  const [linkToPricingInfo, setLinkToPricingInfo] = useState(
    initlinkToPricingInfo
  )

  useEffect(
    () =>
      linkToWebsite !== initLinkToWebsite
        ? setLinkToWebsite(initLinkToWebsite)
        : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initLinkToWebsite]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    () =>
      linkToSchedule !== initLinkToSchedule
        ? setLinkToSchedule(initLinkToSchedule)
        : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initLinkToSchedule]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    () =>
      linkToVideo !== initLinkToVideo ? setLinkToVideo(initLinkToVideo) : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initLinkToVideo]
  )

  // eslint-disable-next-line
  useEffect(
    () =>
      linkToPricingInfo !== initlinkToPricingInfo
        ? setLinkToPricingInfo(initlinkToPricingInfo)
        : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initlinkToPricingInfo]
  )

  const handleClose = (e) => {
    e.preventDefault()
    onClose()
  }

  return (
    <Modal
      classNames={{ modal: styles.modal, content: styles.modal__content }}
      show={show}
      onClose={onClose}
    >
      <button type="button" className={styles.close} onClick={handleClose}>
        <ExcludedIcon />
      </button>

      <div className={styles.camp__logo}>
        {camp.logo ? (
          <img src={camp.logo} alt={camp.name} />
        ) : (
          <span>{getTextInitials(camp.name)}</span>
        )}
      </div>

      <p className={styles.text_center}>{learnMorePopupHeading}</p>

      <div className={styles.links__wrapper}>
        {[
          { link: linkToWebsite, text: link1, Icon: ExternalIcon },
          {
            link: linkToVideo,
            text: link2,
            Icon: ExternalIcon,
          },
          {
            link: linkToSchedule,
            text: link3,
            Icon: ExternalIcon,
          },
          { link: linkToPricingInfo, text: link4, Icon: ExternalIcon },
        ].map(({ link, text, Icon }, index) => {
          if (link) {
            return (
              <div
                className={styles.link}
                onClick={() => window.open(link, '_blank').focus()}
                key={index}
              >
                <Icon />
                <span>{text}</span>
              </div>
            )
          } else return <></>
        })}

        <div
          className={clsx(styles.link, styles['link--promo-code'])}
          onClick={onClickRegister}
        >
          <UserIcon />
          <span>{learnMorePopupBtnTxt}</span>
        </div>
      </div>
    </Modal>
  )
}

export default PSLearnMore
