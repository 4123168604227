import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  LogoIcon,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  Illustration2,
  PasswordInput,
} from 'components'
import styles from './ResetPassword.module.scss'
import { useStateValue } from 'state'
import { useNavigate } from 'hooks'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { PATH } from 'utils/constants'
import { toast } from 'react-toastify'

const ResetPassword = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const user = params.get('user')

  if (!user) navigate({ pathname: PATH.AUTH__SIGN_IN })

  const {
    state: { deviceSize },
    action: { resetPassword },
  } = useStateValue()

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required('Please enter a password.')
        .min(
          8,
          ({ min }) => `Password is too short - should be ${min} chars minimum.`
        )
        .max(
          20,
          ({ max }) => `Password is too long - should be ${max} chars maximum.`
        )
        .matches(/[A-Z]/, 'Must contain at least one uppercase character.')
        .matches(/[a-z]/, 'Must contain at least one lowercase character.')
        .matches(/[0-9]/, 'Must contain at least one number.'),
      confirmPassword: Yup.string()
        .required('Please enter a confirm password.')
        .oneOf([Yup.ref('newPassword')], 'Passwords must match.'),
    }),
    onSubmit: ({ newPassword }) =>
      resetPassword({ user, newPassword }, () => {
        toast.success('Password is Changed Successfully')
        setTimeout(() => navigate({ pathname: PATH.AUTH__SIGN_IN }, 1000))
      }),
  })

  const handleClickSignIn = () => navigate({ pathname: PATH.AUTH__SIGN_IN })

  return (
    <div className={styles.page__container}>
      <div className={styles.page__wrapper}>
        <div className={styles.page}>
          <Link className={styles.logo} to="/">
            <LogoIcon />
          </Link>

          <p className={styles.description}>Password Reset</p>

          <form>
            <PasswordInput
              label="New Password"
              value={formik.values.newPassword}
              onChange={(newPassword) =>
                formik.setFieldValue('newPassword', newPassword)
              }
              {...(formik.touched.newPassword && formik.errors.newPassword
                ? { isError: true, captionText: formik.errors.newPassword }
                : {})}
            />
            <PasswordInput
              label="Confirm Password"
              value={formik.values.confirmPassword}
              onChange={(confirmPassword) =>
                formik.setFieldValue('confirmPassword', confirmPassword)
              }
              {...(formik.touched.confirmPassword &&
              formik.errors.confirmPassword
                ? { isError: true, captionText: formik.errors.confirmPassword }
                : {})}
            />

            <div className={styles.buttons__container}>
              {['md', 'lg', 'xl', 'xxl'].includes(deviceSize) ? (
                <SecondaryButton
                  className={styles.button__login}
                  label="LOGIN"
                  onClick={handleClickSignIn}
                />
              ) : null}
              <PrimaryButton
                className={styles.button__send}
                label="Save"
                onClick={formik.handleSubmit}
              />
              {['md', 'lg', 'xl', 'xxl'].includes(deviceSize) ? null : (
                <TertiaryButton
                  className={styles.button__login}
                  label="LOGIN"
                  onClick={handleClickSignIn}
                />
              )}
            </div>
          </form>
        </div>

        {deviceSize === 'xxl' ? (
          <Illustration2 className={styles.page__illustration} />
        ) : null}
      </div>
    </div>
  )
}

export default ResetPassword
