import { getAxiosInstance, handleError } from 'utils';
import { ACTION } from 'utils/constants';

export const updateProspectSetup = dispatch => async (prospectSetupDetails, callback = () => null) => {
  try {
    dispatch({ type: ACTION.START });

    const formData = new FormData();

    Object.keys(prospectSetupDetails).forEach(key => {
      const data = prospectSetupDetails[key];

      if (data || data === "") formData.append(key, data);
    });

    const args = [`/api/campaigns/${prospectSetupDetails.campaignId}/prospect-setup/`, formData]

    const response = await (
      prospectSetupDetails.id ? getAxiosInstance().put(...args) : getAxiosInstance().post(...args)
    );

    const { success, code, message, prospectSetup } = response.data;

    if (success) callback(prospectSetup);
    else throw new Error(JSON.stringify({ code, message }));
  } catch (error) {
    handleError(dispatch, error);
  } finally {
    dispatch({ type: ACTION.END });
  }
};