import React, { useState } from 'react';
import { Modal, PrimaryButton, SecondaryButton, ExcludedIcon, Checkbox } from 'components';
import styles from './CampSetupList.module.scss';
import moment from 'moment';
import _ from 'lodash';

const CampSetupList = ({ show, csvFiles = [], onClose = () => null, onImport = () => null }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleOnChange = sourceFile => setSelectedFiles(selectedFiles => {
    if (selectedFiles.includes(sourceFile))
      return selectedFiles.filter(file => file !== sourceFile);

    return _.concat(selectedFiles, sourceFile);
  });

  const handleClickCancel = () => {
    onClose();
    setSelectedFiles([]);
  };

  const handleClickImport = () => {
    onImport(selectedFiles);

    handleClickCancel();
  };

  return (
    <Modal classNames={{ modal: styles.modal }} show={show} onClose={handleClickCancel}>
      <h6 className={styles.title} dangerouslySetInnerHTML={{ __html: 'Select a File' }} />

      <p className={styles.body} dangerouslySetInnerHTML={{ __html: 'Import all ambassadors from selected list.' }} />

      <button type='button' className={styles.close} onClick={handleClickCancel}>
        <ExcludedIcon />
      </button>

      <table className={styles.table} cellSpacing={0}>
        <thead>
          <tr>
            <th />
            <th dangerouslySetInnerHTML={{ __html: 'File Name' }} />
            <th dangerouslySetInnerHTML={{ __html: 'Count' }} />
            <th dangerouslySetInnerHTML={{ __html: 'Created At' }} />
          </tr>
        </thead>
        <tbody>
          {csvFiles.map(({ count, sourceFile, createdAt }) => (
            <tr key={sourceFile}>
              <td><Checkbox onChange={() => handleOnChange(sourceFile)} /></td>
              <td dangerouslySetInnerHTML={{ __html: sourceFile }} />
              <td dangerouslySetInnerHTML={{ __html: count }} />
              <td dangerouslySetInnerHTML={{ __html: moment(createdAt).format('MM/DD/YYYY') }} />
            </tr>
          ))}
        </tbody>
      </table>

      <div className={styles.buttons}>
        <SecondaryButton
          className={styles.button__cancel}
          label='Cancel'
          onClick={handleClickCancel}
        />

        <PrimaryButton
          className={styles.button__import}
          label='Import'
          onClick={handleClickImport}
        />
      </div>
    </Modal >
  );
};

export default CampSetupList;
