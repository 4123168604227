import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import styles from './StatItem.module.scss';
import { getTextInitials } from 'utils';
import { useStateValue } from 'state';

const StatItem = ({ className, camp: {
  id,
  name,
  website,
  email,
  phone,
  mission,
  logo,
  address_1,
  address_2,
  city,
  state,
  country,
  zipCode,
  primaryColor,
  secondaryColor,
  buttonColor,
  seasons,
  roles,
  status,
  ambassadors,
  prospects
} }) => {
  const { action: { getCampStats } } = useStateValue();
  const [, setStatsData] = useState({});

  // eslint-disable-next-line
  useEffect(() => getCampStats(id, setStatsData), []);

  return (
    <div className={clsx(className, styles.stat_item)}>
      <div className={styles.stat_item__block__1}>
        <div className={styles.stat_item__logo}>
          {logo ? <img src={logo} alt={name} /> : <h6>{getTextInitials(name)}</h6>}
        </div>

        <div className={styles.stat_item__block__2}>
          <p
            className={styles.stat_item__name}
            dangerouslySetInnerHTML={{ __html: `${name} Camp` }}
          />

          <p
            className={styles.stat_item__address}
            dangerouslySetInnerHTML={{ __html: `${address_1}${address_2}, ${city}, ${state} ${zipCode}` }}
          />

          <p className={styles.stat_item__website}>
            <a href={website} target='_blank' rel="noreferrer">{website}</a>
          </p>
        </div>
      </div>

      <div className={styles.stat_item__block__3}>
        <div className={styles.stat_item__progress}>
          <div className={styles['stat_item__progress--green']} style={{ width: '30%' }} />
          <span>12</span>
        </div>

        <div className={styles.stat_item__progress}>
          <div className={styles['stat_item__progress--orange']} style={{ width: '100%' }} />
          <span>114</span>
        </div>

        <div className={styles.stat_item__progress}>
          <div className={styles['stat_item__progress--blue']} style={{ width: '60%' }} />
          <span>82</span>
        </div>

        <div className={styles.stat_item__progress}>
          <div className={styles['stat_item__progress--red']} style={{ width: '50%' }} />
          <span>68</span>
        </div>
      </div>
    </div>
  );
};

export default StatItem;