import React from 'react'
import styles from './Badge.module.scss'
import clsx from 'clsx'

const Badge = ({ children, className, ...other }) => {
  return (
    <div {...other} className={clsx(className, styles.badge)} >
      {children}
    </div>
  )
}

export default Badge
