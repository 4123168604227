import React from 'react'
import { CampPlusIcon, SecondaryButton } from 'components'
import { useStateValue } from 'state'
import styles from './NoCamp.module.scss'
import { useNavigate } from 'hooks'
import { PATH } from 'utils/constants'

const NoCamp = () => {
  const navigate = useNavigate()
  const {
    state: { deviceSize },
  } = useStateValue()

  const handleClickAddCamp = () => navigate({ pathname: PATH.CAMPS__ADD })

  // const canAddCamp = () => {
  //   let count = activeCamp ? 1 : 0
  //   console.log(`active camp count ${count}`)
  //   count += otherCamps.length
  //   return count < user.campCount
  // }

  return (
    <div className={styles.page}>
      <div className={styles.page__title}>
        {['xs', 'sm', 'md'].includes(deviceSize) ? (
          <>
            <h3>My Camps</h3>
            <p>Create a new camp!</p>
          </>
        ) : (
          <div>
            <h2>My Camps</h2>
            <p>Create a new camp!</p>
          </div>
        )}
      </div>

      <div className={styles.page__add_camp}>
        <CampPlusIcon className={styles.page__add_camp__icon} />

        <SecondaryButton
          className={styles.page__add_camp__button}
          label="Add Camp"
          size="medium"
          onClick={handleClickAddCamp}
          // disabled={!canAddCamp()}
        />
      </div>
    </div>
  )
}

export default NoCamp
