import { AccordionSection } from 'components'
import React, { useState } from 'react'
import styles from './Help.module.scss'

const Help = () => {
  const [sectionOpened, setSectionOpened] = useState(null)

  return (
    <div className={styles.page}>
      <div className={styles.wrapper}>
        <div className={styles.title}>Training Videos</div>
        Step by step instructions to guide you through the setup and launch of a
        Camp Tree word-of-mouth campaign.
      </div>
      <div className={styles.accordians}>
        <AccordionSection
          className={styles.accordion_section}
          titleClass={styles.accordian_title}
          title="Welcome to Camp Tree!"
          opened={sectionOpened === '1'}
          onActionToOpen={() => setSectionOpened('1')}
          onActionToClose={() => setSectionOpened(null)}
        >
          <div className={styles.form__row}>
            This video provides a quick overview of the Camp Tree app to help
            our new customers get started in setting up their account.{' '}
            <a
              href="https://www.youtube.com/watch?v=keuKwFV38t8&list=PLFG3Pvzsh8QSw6FCQN1uoEthQEVGvLzo-&index=1&ab_channel=CampTree"
              target="_blank"
              className={styles.link}
            >
              Welcome to Camp Tree!
            </a>
          </div>
        </AccordionSection>

        <AccordionSection
          className={styles.accordion_section}
          titleClass={styles.accordian_title}
          title="Camp Tree Account Setup"
          opened={sectionOpened === '2'}
          onActionToOpen={() => setSectionOpened('2')}
          onActionToClose={() => setSectionOpened(null)}
        >
          <div className={styles.form__row}>
            Getting started with Camp Tree is super simple. This video shows how
            you add and upload you default information (name, email, logo,
            colors, mission statement and more) so that all the communications
            you send from Camp Tree look like they are coming from you!{' '}
            <a
              href="https://www.youtube.com/watch?v=857DWKYI0oQ&list=PLFG3Pvzsh8QSw6FCQN1uoEthQEVGvLzo-&index=2&ab_channel=CampTree"
              target="_blank"
              className={styles.link}
            >
              Camp Tree Account Setup
            </a>
          </div>
        </AccordionSection>

        <AccordionSection
          className={styles.accordion_section}
          titleClass={styles.accordian_title}
          title="Name Your Campaign Name and Set the Dates"
          opened={sectionOpened === '3'}
          onActionToOpen={() => setSectionOpened('3')}
          onActionToClose={() => setSectionOpened(null)}
        >
          <div className={styles.form__row}>
            In this section of the Camp Tree wizard, you will define your
            referral campaign. Pick a name that uniquely tells you what this
            campaign is about. Then determine the dates that this campaign will
            run. The end date will close the campaign.{' '}
            <a
              href="https://www.youtube.com/watch?v=6Yug34TwdKk&list=PLFG3Pvzsh8QSw6FCQN1uoEthQEVGvLzo-&index=3&ab_channel=CampTree"
              target="_blank"
              className={styles.link}
            >
              Name Your Campaign Name and Set the Dates
            </a>
          </div>
        </AccordionSection>

        <AccordionSection
          className={styles.accordion_section}
          titleClass={styles.accordian_title}
          title="Define Ambassador and Prospect Rewards"
          opened={sectionOpened === '4'}
          onActionToOpen={() => setSectionOpened('4')}
          onActionToClose={() => setSectionOpened(null)}
        >
          <div className={styles.form__row}>
            Using the Camp Tree wizard allows you to set up your referral
            campaign. This video shows were you will setup the Ambassador
            section (audience and rewards) and the Prospect section (audience,
            rewards and adding a promo code).{' '}
            <a
              href="https://www.youtube.com/watch?v=pZg_s0uhikk&list=PLFG3Pvzsh8QSw6FCQN1uoEthQEVGvLzo-&index=4&ab_channel=CampTree"
              target="_blank"
              className={styles.link}
            >
              Define Ambassador and Prospect Rewards
            </a>
          </div>
        </AccordionSection>

        <AccordionSection
          className={styles.accordion_section}
          titleClass={styles.accordian_title}
          title="Ambassador Landing Page and Invitations to Join"
          opened={sectionOpened === '5'}
          onActionToOpen={() => setSectionOpened('5')}
          onActionToClose={() => setSectionOpened(null)}
        >
          <div className={styles.form__row}>
            This video explains how to setup the Ambassador Landing Page and
            Initial Invitation Email in the Ambassador Setup. The first step in
            a Camp Tree campaign is to invite people to join your campaign.
            There are three ways to do so and include: sending the Initial
            Invitation Email from Camp Tree, using the Public Link and using the
            provided widget to create a Landing page on your camp website.{' '}
            <a
              href="https://www.youtube.com/watch?v=-fHJ3RdPY_8&list=PLFG3Pvzsh8QSw6FCQN1uoEthQEVGvLzo-&index=5&ab_channel=CampTree"
              target="_blank"
              className={styles.link}
            >
              Ambassador Landing Page and Invitations to Join
            </a>
          </div>
        </AccordionSection>

        <AccordionSection
          className={styles.accordion_section}
          titleClass={styles.accordian_title}
          title="Ambassador Dashboard and Emails"
          opened={sectionOpened === '6'}
          onActionToOpen={() => setSectionOpened('6')}
          onActionToClose={() => setSectionOpened(null)}
        >
          <div className={styles.form__row}>
            There are 2 training videos for the Ambassador setup section. This
            2nd video explains how to setup the Ambassador Dashboard and the 4
            automated emails that will be sent to each Joined Ambassador.{' '}
            <a
              href="https://www.youtube.com/watch?v=Xwar5vmpbYA&list=PLFG3Pvzsh8QSw6FCQN1uoEthQEVGvLzo-&index=6&ab_channel=CampTree"
              target="_blank"
              className={styles.link}
            >
              Ambassador Dashboard and Emails
            </a>
          </div>
        </AccordionSection>

        <AccordionSection
          className={styles.accordion_section}
          titleClass={styles.accordian_title}
          title="Prospect Setup"
          opened={sectionOpened === '7'}
          onActionToOpen={() => setSectionOpened('7')}
          onActionToClose={() => setSectionOpened(null)}
        >
          <div className={styles.form__row}>
            This video explains how to setup the Prospect section of the Camp
            Tree wizard. When a Prospect gets a Unique Link from a friend
            (Ambassador), the link will bring them to the Prospect Landing page.
            When they enter their contact info to Learn More or Claim the Promo
            Code, they will receive customizable confirmation emails.{' '}
            <a
              href="https://www.youtube.com/watch?v=_5mlZSAvzmI&list=PLFG3Pvzsh8QSw6FCQN1uoEthQEVGvLzo-&index=7&ab_channel=CampTree"
              target="_blank"
              className={styles.link}
            >
              Prospect Setup
            </a>
          </div>
        </AccordionSection>

        <AccordionSection
          className={styles.accordion_section}
          titleClass={styles.accordian_title}
          title="Test & Launch Campaign"
          opened={sectionOpened === '8'}
          onActionToOpen={() => setSectionOpened('8')}
          onActionToClose={() => setSectionOpened(null)}
        >
          <div className={styles.form__row}>
            This is the final step in the Camp Tree wizard to setup your
            referral campaign. Here you will learn how to Test your campaign so
            you can send it to a few colleagues to review.{' '}
            <a
              href="https://www.youtube.com/watch?v=_LutHnPQW7U&list=PLFG3Pvzsh8QSw6FCQN1uoEthQEVGvLzo-&index=8&ab_channel=CampTree"
              target="_blank"
              className={styles.link}
            >
              Test & Launch Campaign
            </a>
          </div>
        </AccordionSection>

        <AccordionSection
          className={styles.accordion_section}
          titleClass={styles.accordian_title}
          title="Camp Tree Training Upload Ambassador CSV file to Launch Campaign"
          opened={sectionOpened === '9'}
          onActionToOpen={() => setSectionOpened('9')}
          onActionToClose={() => setSectionOpened(null)}
        >
          <div className={styles.form__row}>
            This video shows you how to access and download the Camp Tree CSV
            file. Then you can copy and paste your data into this file, save it
            and Upload it to the campaign. Once the potential Ambassador data is
            uploaded, you are ready to Launch the campaign and invite this list
            of people to join the Campaign and become Ambassadors.{' '}
            <a
              href="https://www.youtube.com/watch?v=jtrjUBA0tzI&list=PLFG3Pvzsh8QSw6FCQN1uoEthQEVGvLzo-&index=9&ab_channel=CampTree"
              target="_blank"
              className={styles.link}
            >
              Camp Tree Training Upload Ambassador CSV file to Launch Campaign
            </a>
          </div>
        </AccordionSection>
      </div>
    </div>
  )
}

export default Help
