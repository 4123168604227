import React, { useRef, useState } from 'react'
import { PrimaryButton, ChevronArrowIcon } from 'components'
import { STORAGE_NAME, CAMPAIGN_TYPE, PATH } from 'utils/constants'
import { useNavigate, useClickOutside } from 'hooks'
import { useStateValue } from 'state'
import { getStorageItem } from 'utils'
import styles from './CreateCampaignButton.module.scss'
import clsx from 'clsx'

const CreateCampaignButton = () => {
  const navigate = useNavigate()
  const campIdInUse = getStorageItem(STORAGE_NAME.CAMP_IN_USE)
  const [isNewMenuOpened, setNewMenuOpened] = useState(false)

  const {
    action: { getDefaultCampaign },
  } = useStateValue()

  const refItems = {
    createCampaignMenu: useRef(null),
  }

  useClickOutside(refItems.createCampaignMenu, () => { 
    setNewMenuOpened(false) }
  )

  // Handler for item click
  const handleCreateCampagin = (campaignType) =>
    getDefaultCampaign({ campId: campIdInUse, campaignType }, (campaign) =>
      navigate({
        pathname: `${PATH.CAMPAIGNS__EDIT}/define/${campaign.id}`,
        state: { campaign },
      })
    )
  return (
    <div className={styles.create_campaign__wrapper} ref={refItems.createCampaignMenu}>
      <PrimaryButton 
        onClick={() => setNewMenuOpened(!isNewMenuOpened)} 
        >
        Create Campaign
        <ChevronArrowIcon
          className={clsx(
            styles.create_campaign__icon,
            isNewMenuOpened && styles['create_campaign__icon--up']
          )}
        />
      </PrimaryButton>

      <div
        className={clsx(
          styles.create_campaign__menu,
          isNewMenuOpened && styles['create_campaign__menu--opened']
        )}
      >
        {[
          CAMPAIGN_TYPE.REGISTRATION,
          CAMPAIGN_TYPE.COUNSELOR,
          CAMPAIGN_TYPE.CUSTOM,
        ].map((type, mIdx) => (
          <button
            key={mIdx}
            className={styles.create_campaign__menu__item}
            onClick={() => handleCreateCampagin(type)}
          >{type}</button>
        ))}
      </div>
    </div>
  )
}

export default CreateCampaignButton
