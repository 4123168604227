import React, { useEffect, useRef, useState } from 'react'
import { ChevronArrowIcon, SecondaryButton, CSVIcon } from 'components'
import { useClickOutside } from 'hooks'

import styles from './DownloadReportButton.module.scss'
import clsx from 'clsx'
import { CSVLink } from 'react-csv'
import { REPORT_TYPE } from 'utils/constants'

const DownloadReportButton = ({
  reportBtnDisabled = false,
  reports = [],
  csvDownloadReady = false,
  headers = {},
  data = [],
  reportType,
  clickDownloadReport = () => null,
}) => {
  let csvLink = React.createRef()
  const [isNewMenuOpened, setNewMenuOpened] = useState(false)

  const refItems = {
    downloadReportMenu: useRef(null),
  }

  useClickOutside(refItems.downloadReportMenu, () => {
    setNewMenuOpened(false)
  })

  useEffect(() => {
    if (csvDownloadReady) csvLink.current.link.click()
  }, [csvDownloadReady])

  let today = new Date()
  let currentDateTime =
    `${today.getFullYear()}-` +
    `${today.getMonth()}-` +
    `${today.getDate()}-` +
    `${today.getHours()}-` +
    `${today.getMinutes()}-` +
    `${today.getSeconds()}-` +
    `${today.getMilliseconds()}`

  return (
    <div
      className={styles.download_report__wrapper}
      ref={refItems.downloadReportMenu}
    >
      <SecondaryButton
        className={styles.button__download_csv}
        onClick={() => setNewMenuOpened(!isNewMenuOpened)}
        disabled={reportBtnDisabled}
      >
        <CSVIcon className={styles.button__download__icon} /> Download Report
        <ChevronArrowIcon
          className={clsx(
            styles.download_report__icon,
            isNewMenuOpened && styles['download_report__icon--up']
          )}
        />
        <CSVLink
          headers={headers}
          data={data}
          target="_blank"
          filename={
            reportType === REPORT_TYPE.AMBASSADOR
              ? `Ambassador-${currentDateTime}.csv`
              : reportType === REPORT_TYPE.VERIFICATION
              ? `All-Data-${currentDateTime}.csv`
              : `Ambassador-Prospect-${currentDateTime}.csv`
          }
          ref={csvLink}
        />
      </SecondaryButton>

      <div
        className={clsx(
          styles.download_report__menu,
          isNewMenuOpened && styles['download_report__menu--opened']
        )}
      >
        {reports.map((type, mIdx) => (
          <button
            key={mIdx}
            className={styles.download_report__menu__item}
            onClick={() => clickDownloadReport(type)}
          >
            {type}
          </button>
        ))}
      </div>
    </div>
  )
}

export default DownloadReportButton
