import React, { useState } from 'react';
import clsx from 'clsx';
import styles from './SecondaryButton.module.scss';

const SecondaryButton = ({
  className,
  label = null,
  children = null,
  disabled = false,
  noTextTransform = false,
  onClick: handleClickButton = (e) => null
}) => {
  const [states, setStates] = useState([]);

  const handleClick = (e) => {
    if (disabled) return false;

    handleClickButton(e);
  };

  return (
    <button
      type="button"
      className={clsx(
        className,
        styles.secondary_button,
        !disabled && states.includes('hovered') && styles.secondary_button__hovered,
        !disabled && states.includes('pressed') && styles.secondary_button__pressed,
        disabled && styles.secondary_button__disabled,
        noTextTransform && styles.secondary_button__no_text_transform
      )}
      onClick={handleClick}
      onMouseEnter={() => !disabled && setStates(states => [...states, 'hovered'])}
      onMouseLeave={() => !disabled && setStates([])}
      onMouseDown={() => !disabled && setStates(states => [...states, 'pressed'])}
      onMouseUp={() => !disabled && setStates(states => states.filter(state => state !== 'pressed'))}
    >
      {label || children || 'Secondary Button'}
    </button>
  );
};

export default SecondaryButton;
