import React, { useEffect, useState } from 'react';
import {
  Modal, PrimaryButton, SecondaryButton, Select, TextInput, ExcludedIcon
} from 'components';
import styles from './ColorPicker.module.scss';
import { HexColorPicker, RgbColorPicker } from 'react-colorful';
import './ColorPicker.scss';
import * as convert from 'color-convert';

const ColorPicker = ({ show, onClose = () => null, options: { title, color: initHexValue, onUpdateColor = () => null } }) => {
  const [colorType, setColorType] = useState('Hex');
  const [hexValue, setHexValue] = useState('000000');
  const [rgbValue, setRGBValue] = useState([0, 0, 0]);

  useEffect(() => initHexValue && setHexValue(initHexValue.replace('#', '')), [initHexValue]);

  const handleClose = () => {
    setColorType('Hex');
    onClose();
  };

  const handleUpdateColor = () => {
    onUpdateColor(`#${hexValue.toUpperCase()}`);

    handleClose();
  };

  const handleChangeColorValue = color => {
    let hexValue = '', rgbValue = [];

    if (colorType === 'Hex') {
      hexValue = color.replace('#', '');
      rgbValue = convert.hex.rgb(hexValue);
    } else {
      rgbValue = color.replace('(', '').replace(')', '').split(', ');
      hexValue = convert.rgb.hex(rgbValue);
    }

    setHexValue(hexValue);
    setRGBValue(rgbValue);
  };

  const handleChangeColorType = newType => {
    if (colorType !== newType) {
      if (newType === 'Hex') setHexValue(convert.rgb.hex(rgbValue));
      else setRGBValue(convert.hex.rgb(hexValue));
    }

    setColorType(newType);
  };

  return (
    <Modal classNames={{ modal: styles.modal }} show={show} onClose={handleClose}>
      <h6 className={styles.title}>{title}</h6>

      <button type='button' className={styles.close} onClick={handleClose}>
        <ExcludedIcon />
      </button>

      <form>
        <div className='color_picker__wrapper'>
          {colorType === 'Hex' ? (
            <HexColorPicker color={`#${hexValue}`}
              onChange={hexValue => setHexValue(hexValue.replace('#', ''))}
            />
          ) : (
            <RgbColorPicker color={{ r: rgbValue[0], g: rgbValue[1], b: rgbValue[2] }}
              onChange={({ r, g, b }) => setRGBValue([r, g, b])}
            />
          )}
        </div>

        <div className={styles.form__row}>
          <div className={styles.form__col}>
            <Select
              label='&nbsp;'
              options={['Hex', 'RGB']}
              value={colorType}
              onChange={handleChangeColorType}
            />
          </div>

          <div className={styles.form__col}>
            <input hidden />
            <TextInput
              label='&nbsp;'
              value={colorType === 'Hex' ? `#${hexValue}` : `(${rgbValue.join(', ')})`}
              onChange={handleChangeColorValue}
            />
          </div>
        </div>

        <div className={styles.form__buttons}>
          <SecondaryButton
            className={styles.form__cancel}
            label='Cancel'
            onClick={handleClose}
          />

          <PrimaryButton
            className={styles.form__save}
            label='Save'
            onClick={handleUpdateColor}
          />
        </div>
      </form>
    </Modal >
  );
};

export default ColorPicker;