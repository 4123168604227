import axios from 'axios'
import { clearAuthToken, readAuthToken } from './storage'

const getAxiosConfig = () => {
  const authToken = readAuthToken()

  const { REACT_APP_API_HOST } = process.env

  if (authToken)
    return {
      headers: { Authorization: `Bearer ${authToken}` },
      baseURL: REACT_APP_API_HOST || 'http://localhost:3001',
    }

  return {
    baseURL: REACT_APP_API_HOST || 'http://localhost:3001',
  }
}
const getStripeAxiosConfig = () => {
  const { REACT_APP_STRIPE_API_URL } = process.env
  return {
    baseURL: REACT_APP_STRIPE_API_URL || 'http://localhost:3002',
  }
}

export const getAxiosInstance = (useStripe = false) => {
  const instance = axios.create(
    useStripe ? getStripeAxiosConfig() : getAxiosConfig()
  )

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (!error.status && error.message === 'Network Error') {
        return Promise.reject({ message: JSON.stringify(error) })
      }
      if (error.response.status === 401) clearAuthToken()
      return error.response
    }
  )

  return instance
}
