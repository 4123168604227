import React, { useState, useRef } from 'react'
import { useFormik } from 'formik'
import styles from './AmbLdg.module.scss'
import { getTextInitials } from 'utils'
import { Helmet } from "react-helmet";

import {
  PrimaryButton,
  TextInput,
  Checkbox,
  LogoIcon,
  CheckMarkCircleIcon,
  AmbassadorSetupTermsModal,
} from 'components'
import * as Yup from 'yup'
import clsx from 'clsx'

const AmbLdg = ({
  landingBgImgURL = null,
  landingButtonText = null,
  landingDesc = null,
  landingTitle = null,
  camp = {},
  campaignId = null,
  person: initPerson = {},
  isSubmitted = false,
  onSubmit = () => null,
}) => {
  const [showTermsModal, setShowTermsModal] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const termsButtonRef = useRef(null)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      acceptedTerms: false,
      ...initPerson,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First Name is required.'),
      lastName: Yup.string().required('Last Name is required.'),
      email: Yup.string()
        .required('Email is required.')
        .email('Please enter a valid email.'),
      acceptedTerms: Yup.boolean()
        .required('Required')
        .oneOf([true], 'You must read and accept the terms and conditions.'),
    }),
    onSubmit: (args) => {
      setLoading(true)
      onSubmit(args, () => setLoading(false))
    },
  })

  const handleClickAcceptTerms = (e) => {
    e.preventDefault()

    if (e.target !== termsButtonRef.current)
      formik.setFieldValue('acceptedTerms', !formik.values.acceptedTerms)
  }
  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Help {camp.name} grow</title>
          <meta name="description" content="Check out our new referral program!" />
      </Helmet>
      <div
        className={styles.page}
        style={{ backgroundImage: `url("${landingBgImgURL}")` }}
      >
        <div className={styles.header}>
          <div className={styles.header__brand}>
            <div className={styles.header__logo}>
              {camp.logo ? (
                <img src={camp.logo} alt={camp.name} />
              ) : (
                <span>{getTextInitials(camp.name)}</span>
              )}
            </div>
            <h6>{camp.name}</h6>
          </div>

        </div>

        <div className={styles.main__wrapper}>
          <div className={styles.main}>
            <div className={styles.info}>
              <div className={styles.info__logo}>
                {camp.logo ? (
                  <img src={camp.logo} alt={camp.name} />
                ) : (
                  <span>{getTextInitials(camp.name)}</span>
                )}
              </div>
              <h4 className={styles.info__title}>{landingTitle}</h4>
              <p className={styles.info__desc}>{landingDesc}</p>
            </div>

            {isSubmitted /* */ ? (
              <>
                <CheckMarkCircleIcon className={styles.check_mark} />
                <h3 className={styles.success}>Success!</h3>
                <p className={styles.success__instructions}>Please check your email for a confirmation message.</p>
              </>
            ) : (
              <form className={styles.form}>
                <div className={styles.split_line} />

                <div className={styles.form__legend}>
                  Claim your link. Share with friends.<br/>Earn rewards.
                </div>

                <div className={styles.form__row}>
                  <div className={styles.form__col}>
                    <TextInput
                      label="First Name"
                      placeholder="First Name"
                      value={formik.values.firstName}
                      onChange={(firstName) =>
                        formik.setFieldValue('firstName', firstName)
                      }
                      {...(formik.touched.firstName && formik.errors.firstName
                        ? {
                            isError: true,
                            captionText: formik.errors.firstName,
                          }
                        : {})}
                    />
                  </div>

                  <div className={styles.form__col}>
                    <TextInput
                      label="Last Name"
                      placeholder="Last Name"
                      value={formik.values.lastName}
                      onChange={(lastName) =>
                        formik.setFieldValue('lastName', lastName)
                      }
                      {...(formik.touched.lastName && formik.errors.lastName
                        ? { isError: true, captionText: formik.errors.lastName }
                        : {})}
                    />
                  </div>
                </div>

                <div className={styles.form__row}>
                  <div className={styles.form__col}>
                    <TextInput
                      label="Email"
                      placeholder="Email"
                      value={formik.values.email}
                      onChange={(email) => formik.setFieldValue('email', email)}
                      {...(formik.touched.email && formik.errors.email
                        ? { isError: true, captionText: formik.errors.email }
                        : {})}
                    />
                  </div>

                  <div className={styles.form__col}>
                    <label className={styles.form__submit__label}>
                      landingButtonText
                    </label>

                    <div className={styles.form__submit__wrapper}>
                      <PrimaryButton
                        className={styles.form__submit}
                        label={landingButtonText}
                        disabled={isLoading}
                        onClick={formik.handleSubmit}
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.form__row}>
                  <div className={styles.form__col}>
                    <div
                      className={styles.form__accept_terms}
                      onClick={handleClickAcceptTerms}
                    >
                      <Checkbox
                        className={clsx(
                          styles.form__accept_terms__checkbox,
                          formik.touched.acceptedTerms &&
                            formik.errors.acceptedTerms &&
                            styles['form__accept_terms__checkbox--error']
                        )}
                        isChecked={formik.values.acceptedTerms}
                      />
                      <span>
                        I have read, understand and accept the{' '}
                        <button
                          type="button"
                          ref={termsButtonRef}
                          onClick={() => setShowTermsModal(true)}
                        >
                          terms
                        </button>{' '}
                        and conditions
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            )}

            <div className={styles.powered_by}>
              <span>Powered by</span>
              <LogoIcon className={styles.powered_by__LogoIcon} />
            </div>
          </div>
        </div>
      </div>

      <AmbassadorSetupTermsModal
        campaignId={campaignId}
        show={showTermsModal}
        onClose={() => setShowTermsModal(false)}
      />
    </>
  )
}

export default AmbLdg
