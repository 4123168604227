import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useStateValue } from 'state'
import AmbLanding from '../Landing'
import AmbDashboard from '../Dashboard'
import moment from 'moment'
import {
  AMBASSADOR_STATUS,
  CAMPAIGN_STATUS,
  CAMP_STATUS,
} from 'utils/constants'
import { getAxiosInstance } from 'utils'
import { toast } from 'react-toastify'

// Landing page for debugging: http://localhost:3000/a/d/p.brannan.3.3
const AmbSetup = () => {

  const { type, uniqueLink } = useParams()
  const {
    action: { getAmbassadorSetupData, closeCampaign },
  } = useStateValue()
  const [isSubmitted, setSubmitted] = useState(false)
  const [ambassador, setAmbassador] = useState({ Campaign: {} })
  const [noAmbassadorError, setNoAmbassadorError] = useState(false)
  const [ambassadorFetched, setAmbassadorFetched] = useState(false)

  useEffect(
    () => {
      getAmbassadorSetupData({ type, uniqueLink }, (ambassador) => {
        if (ambassador) {
          handleAmbassador(ambassador)
        } else {
          // TODO: Why does handleAmbassador require undefined? Am I missing something?
          handleAmbassador(undefined)
        }
      })
    },
    // eslint-disable-next-line
    [uniqueLink]
  )

  const isClosed = () => {
    if (
      ambassador.Campaign?.status === CAMPAIGN_STATUS.CLOSED ||
      ambassador.Campaign?.Camp?.status !== CAMP_STATUS.ACTIVE
    ) {
      return true
    }

    const expired = moment().diff(ambassador.Campaign.endDate, 'days') > 0
    return expired
  }
  const campaign = ambassador?.Campaign || {}
  const ambassadorSetup = ambassador?.Campaign?.AmbassadorSetup || {}
  const camp = ambassador?.Campaign?.Camp || {}
  const person = ambassador?.Person || {}

  const handleAmbassador = (ambassador) => {
    setAmbassadorFetched(true)
    if (ambassador === undefined) {
      setNoAmbassadorError(true)
      return
    }
    if (moment().diff(ambassador.Campaign.endDate, 'days') > 0) {
      closeCampaign(ambassador.Campaign.id)
    }
    setNoAmbassadorError(false)
    setAmbassador(ambassador)
  }

  if (noAmbassadorError && ambassadorFetched) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: '100px',
        }}
      >
        <h6>The link '{uniqueLink}' was not found.<br /> Please check with the
          person who shared the link.</h6>
      </div>
    )
  }

  // TODO Move styles to css module
  if (ambassadorFetched) {
    if (isClosed())
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '100px',
          }}
        >
          <h6>We're sorry, this campaign is closed.</h6>
        </div>
      )
  }

  if (
    ambassador?.Campaign?.Camp &&
    ambassador?.Campaign?.AmbassadorSetup &&
    ambassadorFetched
  ) {
    if (!(ambassador && ambassador?.status === AMBASSADOR_STATUS.JOINED)) {
      return (
        <AmbLanding
          {...ambassador.Campaign.AmbassadorSetup}
          camp={ambassador.Campaign.Camp}
          campaignId={ambassador.Campaign.id}
          person={type === 'c' ? {} : ambassador.Person}
          isSubmitted={isSubmitted}
          onSubmit={(args, callback = () => null) =>
            (type === 'c'
              ? getAxiosInstance().post(
                `/api/ambassador/setup/${uniqueLink}`,
                args
              )
              : getAxiosInstance().put(`/api/ambassador/${uniqueLink}`, args)
            )
              .then(({ data: { success, message } }) => {
                if (success) {
                  getAmbassadorSetupData({ type, uniqueLink }, handleAmbassador)
                  setSubmitted(true)
                } else toast.error(message)
              })
              .catch(({ message }) =>
                toast.error(message || 'Please contact the support team.')
              )
              .finally(callback)
          }
        />
      )
    } else if (ambassadorFetched) {
      return (
        <AmbDashboard
          {...ambassadorSetup}
          camp={camp}
          campaignId={campaign.id}
          campaignName={campaign.name}
          ambassadorRewards={campaign.AmbassadorRewards}
          prospectRewards={campaign.ProspectRewards}
          person={person}
          ambassador={ambassador}
          isJoined={
            ambassador && ambassador?.status === AMBASSADOR_STATUS.JOINED
          }
          onSubmit={(args, callback = () => null) =>
            getAxiosInstance()
              .put(`/api/ambassador/${uniqueLink}`, args)
              .then(({ data: { success, message } }) => {
                if (success) {
                  getAmbassadorSetupData({ type, uniqueLink }, handleAmbassador)
                  setSubmitted(true)
                } else toast.error(message)
              })
              .catch(({ message }) =>
                toast.error(message || 'Please contact the support team.')
              )
              .finally(callback)
          }
        />
      )
    }
  }

  return <React.Fragment />
}

export default AmbSetup
