import React, { useState } from 'react';
import clsx from 'clsx';
import styles from './TertiaryButton.module.scss';

const TertiaryButton = ({
  className,
  label = null,
  children = null,
  disabled = false,
  onClick: handleClickButton = () => null
}) => {
  const [states, setStates] = useState([]);

  const handleClick = () => {
    if (disabled) return false;

    handleClickButton();
  };

  return (
    <div
      className={clsx(
        className,
        styles.primary_button,
        !disabled && states.includes('hovered') && styles.primary_button__hovered,
        !disabled && states.includes('pressed') && styles.primary_button__pressed,
        disabled && styles.primary_button__disabled
      )}
      onClick={handleClick}
      onMouseEnter={() => !disabled && setStates(states => [...states, 'hovered'])}
      onMouseLeave={() => !disabled && setStates([])}
      onMouseDown={() => !disabled && setStates(states => [...states, 'pressed'])}
      onMouseUp={() => !disabled && setStates(states => states.filter(state => state !== 'pressed'))}
    >
      {label || children || 'Tertiary Button'}
    </div>
  );
};

export default TertiaryButton;