import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import styles from './Breadcrumb.module.scss';
import { ChevronArrowIcon } from 'components';

const Breadcrumb = ({ className, items = [] }) => {
  return (
    <div className={clsx(className, styles.breadcrumb)}>
      {items.map(({ to, text }, bIndex) => (
        <React.Fragment key={bIndex}>
          {bIndex ? <ChevronArrowIcon className={styles.breadcrumb__arrow} /> : null}

          <Link
            className={styles.breadcrumb__item}
            to={to}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

export default Breadcrumb;