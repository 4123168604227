import React, { useEffect, useState } from 'react';
import styles from './TagInput.module.scss';
import { RemoveIcon } from 'components';
import _ from 'lodash';
import clsx from 'clsx';

const TagInput = ({
  classNames = { label: '' },
  tags: initTags = [],
  onChange = () => null,
  label = '', requiredShown = false, optionalShown = false,
  isError = false, captionText = '',
  placeholder = 'Add New Tag'
}) => {
  const [newTag, setNewTag] = useState('');
  const [tags, setTags] = useState(_.uniq(initTags));

  // eslint-disable-next-line
  useEffect(() => !_.isEqual(tags, initTags) ? setTags(initTags) : null, [initTags]);

  // eslint-disable-next-line
  useEffect(() => !_.isEqual(tags, initTags) ? onChange(tags) : null, [tags]);

  const handleAddNewTag = () => {
    if (newTag) setTags(tags => _.uniq([...tags, newTag]));

    setNewTag('')
  };

  const handleRemoveTag = tag => setTags(tags => _.difference(tags, [tag]));

  const handleKeyUp = e => (e.key === 'Enter' || e.keyCode === 13) ? handleAddNewTag(e) : null;

  const jsonTags = tags && (typeof tags === "string" || tags instanceof String) ? JSON.parse(tags) : tags;
  return (
    <div className={styles.container}>
      {label ? (
        <label className={clsx(classNames.label, styles.tag__input__label)}>
          {label === ' ' ? <>&nbsp;</> : label}
          {requiredShown ? <span className={styles.tag__input__required}>&nbsp;*</span> : null}
          {optionalShown ? <span className={styles.text_input__optional}>&nbsp;(optional)</span> : null}
        </label>
      ) : null}

      <div className={clsx(styles.tags__wrapper, isError && styles[`tags__wrapper--error`])}>
        {jsonTags.map((tag, tIndex) => (
          <div
            className={styles.tag}
            key={tIndex}
            onClick={() => handleRemoveTag(tag)}
          >
            {tag}<RemoveIcon className={styles.tag__remove} />
          </div>
        ))}
        <input
          className={styles.tag__input}
          placeholder={placeholder}
          onBlur={handleAddNewTag}
          onKeyUp={handleKeyUp}
          value={newTag}
          onChange={e => setNewTag(e.target.value)}
        />
      </div>

      <p className={clsx(styles.caption, isError && styles.caption__error)}>{captionText ? <span>{captionText}</span> : null}</p>
    </div>
  );
};

export default TagInput;