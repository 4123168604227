import React from 'react'
import styles from './PSPromoCode.module.scss'
import { getTextInitials } from 'utils'
import { Modal, ExcludedIcon, PrimaryButton, SecondaryButton } from 'components'

const PSPromoCode = ({
  linkToRegistrationPage,
  promocode,
  enrollInstructions,
  camp,
  show,
  enrollPopupHeading,
  enrollPopupBtnTxt,
  onClose = () => null,
}) => {
  const handleClose = (e) => {
    e.preventDefault()
    onClose()
  }

  return (
    <Modal
      classNames={{ modal: styles.modal, content: styles.modal__content }}
      show={show}
      onClose={onClose}
    >
      <button type="button" className={styles.close} onClick={handleClose}>
        <ExcludedIcon />
      </button>

      <div className={styles.camp__logo}>
        {camp ? (
          camp.logo ? (
            <img src={camp.logo} alt={camp?.name} />
          ) : (
            <span>{getTextInitials(camp.name)}</span>
          )
        ) : null}
      </div>

      <h5 className={styles.text__title}>{enrollPopupHeading}</h5>

      <p className={styles.text__message}>{enrollInstructions}</p>

      <SecondaryButton
        className={styles.button__promocode}
        noTextTransform={true}
        label={promocode}
        onClick={() => navigator.clipboard.writeText(promocode)}
      />

      <PrimaryButton
        label={enrollPopupBtnTxt}
        onClick={() => window.open(linkToRegistrationPage, '__blank').focus()}
      />
    </Modal>
  )
}

export default PSPromoCode
